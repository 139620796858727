import { Breadcrumb, Col, Divider, Row, Skeleton, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { FaBath } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { IoIosBed, IoMdCall } from "react-icons/io";
import { MdLocationOn, MdShare } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import {
  EditIcon,
  EditSvg,
  HomeSvg,
  ShareIcon,
  SqFtSvg,
} from "../../assets/svgs";
import AddApartmentModal from "../../components/addApartmentModal/AddApartmentModal";
import UpdateApartmentModal from "../../components/addApartmentModal/UpdateApartmentModal";
import UpdateUnitModal from "../../components/addApartmentModal/UpdateUnitModal";
import { ListWrap } from "../../components/apartment-listing/ListApartmentStyle";
import Actions, {
  EditIconWrap,
} from "../../components/apartments-view/Actions";
import {
  ApartmentTable,
  FloatButton,
} from "../../components/apartments-view/ApartmentsViewStyle";
import {
  AddUnitBtn,
  StyledButton,
} from "../../components/createPersonalProfile/RegistrationStyle";
import { BreadCrumbsWrapper } from "../../components/layout/TheLayout.styles";
import { AddNewApr } from "../../components/listings/Listingstyle";
import {
  getAllApartment,
  getAllUnits,
  getSingleApartment,
} from "../../store/reducers/user.reducer";
import { Heading } from "../../ui/Heading/Heading";
import { Container } from "../../ui/Layout/Container";
import { useWindowSize } from "../../utils/hooks/useWindowSize";

function SubUnit() {
  const location = useLocation();
  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const dispatch = useDispatch();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [modalStateUpdate, setModalStateUpdate] = useState(false);
  const [unitsNumberWidth, setUnitsNumberWidth] = useState("12%");
  const [yScroll, setyScroll] = useState(600);
  const [valueOfApartment, setValueOfApartment] = useState([]);
  const [orgName, setOrgName] = useState([]);
  const [propperty, setPropperty] = useState({});
  const [oneUnit, setOneUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [allUnitObj, setAllUnitObj] = useState({});
  const [allUnitArr, setAllUnitArr] = useState([]);
  const [unitListChanged, setUnitListChanged] = useState(false);
  const [getAllApartmentState, setGetAllApartment] = useState({});
  const [allApartmentForModal, setAllApartmentForModal] = useState([]);
  const [coverImgArr, setCoverImgArr] = useState([]);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);

  const { units } = useSelector((state) => state.user);
  const { allOrganization } = useSelector((state) => state.user);
  const { apartment } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  useEffect(() => {
    if (searchParams.get("unitId") && params.id) {
      var arrOfUnits = [];

      for (var item in units) {
        if (units.hasOwnProperty(item)) {
          arrOfUnits.push(units[item]);
        }
      }

      var orgValue = arrOfUnits.filter(function (item) {
        return item["id"] === searchParams.get("unitId");
      });
      setSelectedUnit(orgValue[0]);
    } else if (params.id) {
      var arr = [];

      for (var item in apartment) {
        if (apartment.hasOwnProperty(item)) {
          arr.push(apartment[item]);
        }
      }

      var orgValue = arr.filter(function (item) {
        return item["id"] === params.id;
      });
      setValueOfApartment(orgValue);
    } else {
    }
  }, [params.id, apartment]);

  useEffect(() => {
    if (oneUnit.length) {
      var arr = [];

      for (var item in allOrganization) {
        if (allOrganization.hasOwnProperty(item)) {
          arr.push(allOrganization[item]);
        }
      }

      var orgValue = arr.filter(function (item) {
        return item["id"] === oneUnit[0].org_id;
      });
      setOrgName(orgValue);
    }
  }, [params.id, allOrganization, user, oneUnit]);

  useEffect(() => {
    if (params.id) {
      localStorage.setItem("prop_id", params.id);
    }
  }, [params.id]);

  useEffect(() => {
    dispatch(getSingleApartment()).then(({ payload }) => {
      setPropperty(payload);
    });
  }, []);

  useEffect(() => {
    if (propperty && Object.keys(propperty).length) {
      const arr = Object.values(propperty);
      setOneUnit(arr);
      // setWaitForApart(false);
    } else {
      setOneUnit([]);
      // setWaitForApart(true);
    }
  }, [propperty]);

  useEffect(() => {
    if (allUnitObj && Object.keys(allUnitObj).length) {
      var arr = [];

      for (var item in allUnitObj) {
        if (allUnitObj.hasOwnProperty(item)) {
          arr.push(allUnitObj[item]);
        }
      }

      const arrOfSort = [];
      let sortForeach = arr.map((obj) => {
        return obj.unit_name;
      });
      sortForeach.sort();

      sortForeach?.map((val) => {
        arr.filter((obj) => {
          if (obj["unit_name"] === val) {
            arrOfSort.push(obj);
          }
        });
      });
      setAllUnitArr(arrOfSort);
    } else {
    }
  }, [allUnitObj]);

  useEffect(() => {
    if (allUnitArr.length) {
      var a = [];

      allUnitArr.forEach(function (obj) {
        a.push(
          obj?.price
            ? obj?.price === undefined
              ? null
              : Number(obj?.price)
            : []
        );
      });

      function removeNull(array) {
        return array.filter((x) => x !== null);
      }

      const max = Math.max(...removeNull(a));
      const min = Math.min(...removeNull(a));

      setMaxPrice(max);
      setMinPrice(min);
    }
  }, [allUnitArr]);

  const getUpdatedApartment = (value) => {
    if (value && Object.keys(value).length) {
      const arr = [value];
      setOneUnit(arr);
      if (allApartmentForModal.length) {
        const objIndex = allApartmentForModal.findIndex(
          (obj) => obj.id === value.id
        );
        allApartmentForModal[objIndex] = value;
      }
    }
  };
  // This is fun
  const getUpdatedUnit = (value) => {
    if (value && Object.keys(value).length) {
      setSelectedUnit(value);
      if (allUnitArr.length) {
        const objIndex = allUnitArr.findIndex((obj) => obj.id === value.id);
        allUnitArr[objIndex] = value;
      }
    }
  };

  useEffect(() => {
    if (width < 1550 && width > 991) {
      setyScroll(600);
    } else {
      setyScroll(600);
    }
  }, [width, location.pathname]);

  useEffect(() => {
    if (width > 1500) {
      setUnitsNumberWidth("22%");
    } else if (width < 1500) {
      setUnitsNumberWidth("16%");
    } else if (width < 1441) {
    }
  }, [width, location.pathname]);

  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/subunit",
      label: `${upperCase(oneUnit[0]?.unit_name)}`,
      isLink: false,
      onClick: true,
    },

    {
      path: "/account",
      label: "My Account",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    ?.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap?.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb?.path}_${index}`}>
          {breadcrumb?.isLink ? (
            <Link to={breadcrumb?.isLink && breadcrumb?.path}>
              {breadcrumb?.label}
            </Link>
          ) : breadcrumb?.onClick ? (
            <span
              style={{ cursor: "pointer" }}
              className="breadcrumbs_label"
              onClick={() => setSelectedUnit([])}
            >
              {breadcrumb?.label}{" "}
              {selectedUnit && Object.keys(selectedUnit)?.length ? (
                <span style={{ cursor: "auto" }}>
                  {" "}
                  &gt; {selectedUnit.unit_name}
                </span>
              ) : (
                ""
              )}
            </span>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      {/* <Link to={`/property/${orgName[0]}`}>{orgName[0].org_name}</Link> */}
      <Link to={`/property/${orgName[0]?.id}`}>
        {upperCase(orgName[0]?.org_name)}
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  useEffect(() => {
    dispatch(getAllUnits()).then(({ payload }) => {
      setAllUnitObj(payload);
    });
  }, [params.id, unitListChanged]);

  const editValue = (value) => {
    setSelectedUnit(value);
    // setAllApartmentForModal([value]);
    setModalStateUpdate(true);
  };

  const columns = [
    {
      title: "Units Type",
      dataIndex: "unitType",
      // sorter: true,
      // render: (name) => `${name.first} ${name.last}`,
      width: "15%",
    },
    {
      title: "Units Name",
      dataIndex: "unit_name",
      width: unitsNumberWidth,
    },

    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   width: "25%",
    // },
    {
      title: "Sq.ft",
      dataIndex: "sqft",
      width: "8%",
    },
    {
      title: "Bed",
      dataIndex: "bed",
      width: "8%",
    },
    {
      title: "Bath",
      dataIndex: "bath",
      width: "8%",
    },
    {
      title: "Amount",
      dataIndex: "price",
      width: "12%",
    },
    {
      title: "Status",
      dataIndex: "unitStatus",
      key: "unitStatus",
      width: "15%",

      render: (_, record) => {
        let color = "red";
        if (record.unitStatus === "Sold") {
          color = "#E04141";
        } else if (record.unitStatus === "Contract Sent") {
          color = "#4160AC";
        } else if (record.unitStatus === "Available") {
          color = "#58D255";
        } else if (record.unitStatus === "Pending") {
          color = "#F67C36";
        }
        return (
          <span
            onClick={(event) => {
              event.stopPropagation();
              editValue(record);
            }}
            style={{ color: color }}
            key={record?.unitStatus}
          >
            {record?.unitStatus}
          </span>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      align: "right",
      width: "8%",
      render: (_, record) => {
        return (
          <EditIconWrap
            onClick={(event) => {
              event.stopPropagation();
              editValue(record);
            }}
          >
            <EditSvg />
          </EditIconWrap>
        );
      },
    },
  ];

  const [tableLoading, setTableLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    size: "small",
  });
  const fetchData = (params = {}) => {
    setTableLoading(true);
    setPagination({
      ...params.pagination,
      total: allUnitArr.length, // 200 is mock data, you should read it from server
      // total: data.totalCount,
    });
    setTimeout(() => {
      setTableLoading(false);
    }, 500);

    //   });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  const newListedUnit = (value) => {
    setUnitListChanged(true);
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getAllApartment()).then(({ payload }) => {
        setGetAllApartment(payload);
      });
    }
  }, []);

  useEffect(() => {
    if (getAllApartmentState && Object.keys(getAllApartmentState).length) {
      const arr = Object.values(getAllApartmentState);
      setAllApartmentForModal(arr);
      // setWaitForApart(false);
    } else {
      setAllApartmentForModal([]);
      // setWaitForApart(true);
    }
  }, [getAllApartmentState]);

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  useEffect(() => {
    if (oneUnit && !Object.keys(selectedUnit ? selectedUnit : {})?.length) {
      let coverImg = oneUnit[0]?.images?.filter(
        (item, key) => item.isCover == true
      );
      setCoverImgArr(coverImg);
    } else {
      if (selectedUnit && Object.keys(selectedUnit)?.length) {
        let coverImg = selectedUnit?.images?.filter(
          (item, key) => item.isCover == true
        );
        setCoverImgArr(coverImg);
      }
    }
  }, [oneUnit, selectedUnit]);

  return (
    <>
      {loading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <Container padding="50px 50px 0 330px" paddingSm="40px 40px 0 265px">
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
              <Row justify="space-between">
                <Col xs={24} sm={24} md={24} lg={16} xl={20} xxl={20}>
                  <Row style={{ alignItems: "center" }}>
                    <Col>
                      <Heading marginBottomLep="10px">Property Details</Heading>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16}>
                      <BreadCrumbsWrapper>
                        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
                      </BreadCrumbsWrapper>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  style={{ textAlign: "end" }}
                >
                  <AddNewApr
                    bgColor={"#777777"}
                    fw={"400"}
                    hg={"40px"}
                    displayView="none"
                    onClick={() => setModalState(true)}
                  >
                    Add New Unit
                  </AddNewApr>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <ApartmentTable>
                    <Table
                      columns={columns}
                      onRow={(r) => ({
                        onClick: () => setSelectedUnit(r),
                      })}
                      dataSource={[...allUnitArr]}
                      onChange={handleTableChange}
                      pagination={pagination}
                      loading={tableLoading}
                      scroll={{ x: 800, y: yScroll }}
                      // scroll={{ x: 1000 }}
                      // scroll={{ y: 350 }}
                    />
                  </ApartmentTable>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <RightSide>
                <RightSideImgWrap>
                  <ImgWrap>
                    {coverImgArr?.length ? (
                      <img alt="example" src={coverImgArr[0]?.path} />
                    ) : (
                      <Skeleton.Image
                        active={false}
                        style={{ width: "100%" }}
                      />
                    )}
                    {/* <img alt="example" src="/images/apartment1.png" /> */}
                  </ImgWrap>
                  <Photos>
                    <p>
                      {selectedUnit && Object.keys(selectedUnit).length
                        ? selectedUnit?.images
                          ? selectedUnit?.images?.length
                          : "0"
                        : oneUnit[0]?.images?.length}{" "}
                      Photos
                    </p>
                  </Photos>
                  <Icons>
                    <div
                      onClick={() => {
                        setModalStateUpdate(true);
                      }}
                    >
                      <EditIcon />
                    </div>
                    <div>
                      <ShareIcon
                        className="share__icon"
                        style={{ marginLeft: "10px" }}
                      />
                    </div>
                  </Icons>
                </RightSideImgWrap>
                <RightSideDetailsWrap>
                  <h2 className="apartment__name">
                    {upperCase(
                      selectedUnit && Object.keys(selectedUnit).length
                        ? selectedUnit.unit_name
                        : oneUnit[0]?.unit_name
                    )}
                  </h2>
                  <StarRatings
                    rating={
                      selectedUnit && Object.keys(selectedUnit).length
                        ? selectedUnit.starRating
                        : 3.32
                    }
                    // starDimension="20px"
                    // starSpacing="5px"
                    starRatedColor="#F67C36"
                  />
                  <h3 className="apartment__price">
                    {selectedUnit && Object.keys(selectedUnit).length
                      ? `$${selectedUnit.price ? selectedUnit.price : 0}`
                      : oneUnit[0]?.price
                      ? `$${oneUnit[0].price}`
                      : `${toUSACurrency(minPrice)} - ${toUSACurrency(
                          maxPrice
                        )}`}
                  </h3>
                  <div className="apartment__location">
                    <MdLocationOn />
                    <p className="apartment__address">{oneUnit[0]?.address}</p>
                  </div>
                  <div className="apartment__location property__phone ">
                    <IoMdCall />
                    <p className="apartment__phone">
                      +{oneUnit[0]?.phonenumber}
                    </p>
                  </div>
                  <Divider />
                  {selectedUnit && Object.keys(selectedUnit).length ? (
                    <Row>
                      <Col span={8} className="apartment__sqft">
                        <IoIosBed />
                        <p className="apartment__bed">
                          Bed : {selectedUnit.bed}
                        </p>
                      </Col>
                      <Col span={8} className="apartment__sqft">
                        <FaBath />
                        <p className="apartment__bed">
                          Bath : {selectedUnit.bath}
                        </p>
                      </Col>
                      <Col span={8} className="apartment__sqft">
                        <SqFtSvg />
                        <p>Sq ft : {selectedUnit.sqft}</p>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={12} className="apartment__sqft">
                        <SqFtSvg />
                        <p>Sq ft : {oneUnit[0]?.sqft}</p>
                      </Col>
                      <Col span={12} className="apartment__sqft">
                        <HomeSvg />
                        <p>{oneUnit[0]?.unitType}</p>
                      </Col>
                    </Row>
                  )}
                  <h4>Description</h4>
                  <h5>
                    {selectedUnit && Object.keys(selectedUnit).length
                      ? selectedUnit.description
                      : oneUnit[0]?.description}
                  </h5>
                  <h4>Amenities</h4>
                  <Row gutter={[10, 10]}>
                    {selectedUnit && Object.keys(selectedUnit).length
                      ? selectedUnit?.ammenities?.map((val) => (
                          <Col>
                            <ListWrap>
                              <p>{val}</p>
                            </ListWrap>
                          </Col>
                        ))
                      : oneUnit[0]?.ammenities?.map((val) => (
                          <Col>
                            <ListWrap>
                              <p>{val}</p>
                            </ListWrap>
                          </Col>
                        ))}
                  </Row>
                </RightSideDetailsWrap>
              </RightSide>
            </Col>
          </Row>
          <FloatButton>
            <AddUnitBtn
              shape="circle"
              icon={<HiPlus className="plus_icon" />}
              onClick={() => setModalState(true)}
            />
          </FloatButton>
        </Container>
      )}
      {selectedUnit && selectedUnit.id ? (
        <UpdateUnitModal
          onCancel={(value) => setModalStateUpdate(value)}
          modalState={modalStateUpdate}
          isEdit={true}
          updateUnit={selectedUnit}
          getUpdatedUnit={getUpdatedUnit}
          getAllApartmentState={allApartmentForModal}
        />
      ) : (
        <UpdateApartmentModal
          onCancel={(value) => setModalStateUpdate(value)}
          modalState={modalStateUpdate}
          isEdit={true}
          updateUnit={oneUnit}
          getUpdatedApartment={getUpdatedApartment}
        />
      )}
      <AddApartmentModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        isApart="true"
        newListedUnit={newListedUnit}
        getAllApartmentState={allApartmentForModal}
      />
    </>
  );
}

export default SubUnit;

export const RightSideDetailsWrap = styled.div`
  padding: 20px;

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    margin-top: 35px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.forth};
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #777777;
  }
  .ant-divider-horizontal {
    background: #dddddd;
  }

  .star-container {
    padding: 5px !important;
  }

  .widget-svg {
    width: 20px !important;
    height: 20px !important;
  }

  .top__id {
    display: none;
  }

  .apartment__name {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 0px;
  }
  .apartment__location {
    margin-top: 20px;
  }
  .property__phone {
    margin-bottom: 20px;
  }

  .apartment__phone,
  .apartment__bed,
  .apartment__address {
    margin: 0;
    font-size: 18px;
    margin-right: 20px;
  }

  .location__row {
    margin-top: 15px;
  }

  .apartment__location {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }
  .apartment__sqft {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }

  .ant-row {
    align-items: center;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .card__BHK {
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .ant-divider-vertical {
    border-left: 1px solid #777777;
    height: 1rem;
  }

  .card__bedroom {
    margin-right: 36px;
  }

  .apartment__type {
    padding-left: 20px;
  }

  .apartment__price {
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #323548;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 25px;
  }

  span {
    color: #777777;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
  }

  @media (max-width: 1550px) {
    padding: 15px;

    .star-container {
      padding: 2px !important;
    }

    .widget-svg {
      width: 16px !important;
      height: 16px !important;
    }
    .apartment__name {
      font-size: 20px;
      line-height: 36px;
    }
    .apartment__phone,
    .apartment__bed,
    .apartment__address {
      margin: 0;
      font-size: 14px;
      margin-right: 16px;
    }
    .location__row {
      margin-top: 12px;
    }
    .apartment__location {
      svg {
        ${
          "" /* font-size: 14px;
        margin-right: 8px; */
        }
      }
    }
    p {
      font-size: 14px;
      line-height: 19px;
    }
    .card__BHK {
      font-size: 14px;
    }
    .card__bedroom {
      margin-right: 28px;
    }
    .apartment__type {
      padding-left: 16px;
    }
    .apartment__price {
      font-size: 14px;
      line-height: 23px;
    }
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const Icons = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 90px;
  display: flex;
  svg {
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const Photos = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: #00000090;
  border-radius: 3px;
  padding: 8px 20px;
  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;

    /* identical to box height */
    color: #ffffff;
    margin-bottom: 0;
  }
`;

export const ImgWrap = styled.div`
  img {
    width: -webkit-fill-available;
    max-height: 255px;
  }

  .ant-skeleton-element .ant-skeleton-image {
    height: 255px;
  }
  .ant-skeleton-element {
    width: 100%;
  }
`;

export const RightSideImgWrap = styled.div`
  position: relative;
`;

export const RightSide = styled.div`
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-height: calc(100vh - 140px);
`;
