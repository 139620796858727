import styled from "styled-components";

export const TenantsCardWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px 20px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    margin-bottom: 0;
    margin-top: 10px;
  }

  h5 {
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
    margin-bottom: 0;

    .tifilter__icon {
      font-size: 21px;
      margin-bottom: -6px;
      cursor: pointer;
    }
  }
  @media (max-width: 1550px) {
    padding: 10px 15px;

    p {
      font-size: 12px;
      line-height: 15px;
    }

    h2 {
      font-size: 24px;
      line-height: 29px;
      margin-top: 8px;
    }

    h5 {
      .tifilter__icon {
        font-size: 18px;
        margin-bottom: -5px;
      }
    }
  }
`;

export const TenantsCard = styled.div``;

export const StyledTableSearch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 433px;
  margin-top: 23px;
  margin-left: 200px;
  background: #eeeeee;
  border-radius: 5px;
  height: 45px;
  padding-left: 18px;
  .ant-select-selector {
    height: auto;
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
  }
  .ant-select {
    width: 80% !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  .ant-select-clear {
    opacity: 1;
    background: none;
    font-size: 16px;
    color: white;
  }

  .tenant_search_icon {
    font-size: 20px;
    cursor: pointer;
    color: #777777;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
  }

  input::placeholder {
    color: white;
    font-size: 14px;
    line-height: 14px;
  }
  @media (max-width: 991.98px) {
    margin-left: 10px;
  }
`;
