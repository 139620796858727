import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  Checkbox,
  List,
  Button,
} from "antd";
import { AiOutlineUpload } from "react-icons/ai";
import { CoverImage, ImagePreview } from "../ImagePreview/index";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { TiDeleteOutline } from "react-icons/ti";
import { MdClear } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledFormSelect,
  StyledForms,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import {
  DropDownView,
  ListWrap,
  PropertyBtn,
  StyledCover,
  StyledUploadPreviewWrapper,
  StyledUploadPreviewWrapperCover,
  UploadBox,
  UploadInput,
} from "../apartment-listing/ListApartmentStyle";
import { ManagePropertyBtn } from "../login/LoginStyle";
import { createUnit, updateUnitApi } from "../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { BsCurrencyDollar } from "react-icons/bs";

function ListUnit({
  pageTitle,
  bgImage,
  pageMargin,
  isModal,
  onCancel,
  isEdit = false,
  updateUnit,
  getUpdatedUnit = console.log,
  onFormChange = console.log,
  newListedUnit = console.log,
  getAllApartmentState,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const params = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const currentDateTime = new Date();
  const navigate = useNavigate();
  const upload = React.useRef(null);
  const [state, setState] = useState([]);
  const [selectAmmenities, setSelectAmmenities] = useState([]);
  const [allApartmentOrg, setAllApartmentOrg] = useState([]);
  const [viewAmmenities, setViewAmmenities] = useState(false);
  const [selectedApartment, setselectedApartment] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [selectImgIdx, setSelectImgIdx] = useState(0);
  const [amenitiesLength, setAmenitiesLength] = useState(12);

  useEffect(() => {
    if (isEdit && Object.keys(updateUnit).length) {
      setSelectAmmenities(updateUnit?.ammenities);

      form.setFieldsValue({
        ammenities: updateUnit?.ammenities,
        bed: updateUnit?.bed,
        diposit: updateUnit?.diposit,
        unitNumber: updateUnit?.unitNumber,
        unitStatus: updateUnit?.unitStatus,
        bath: updateUnit?.bath,
        sqft: updateUnit?.sqft,
        buldingNumber: updateUnit?.buldingNumber,
        unitType: updateUnit?.unitType,
        apartment_id: updateUnit?.apartment_id,
        user_id: updateUnit?.user_id,
        images: updateUnit?.images,
        description: updateUnit?.description,
        price: updateUnit?.price,
        id: updateUnit?.id,
        unit_name: updateUnit?.unit_name,
        imageUrls: updateUnit?.imageUrls,
      });
    }
  }, []);

  useEffect(() => {
    if (updateUnit && updateUnit?.images && isEdit) {
      updateUnit?.images.map((img) => {
        setState((s) => [
          ...s,
          {
            ...(img.isCover ? { isCover: img.isCover } : ""),
            key: img.key,
            // url: img.url,
            path: img.path,
          },
        ]);
      });
    }
  }, [updateUnit, isEdit]);

  const removeAmmenities = (value) => {
    setSelectAmmenities(selectAmmenities.filter((item) => item !== value));
  };

  useEffect(() => {
    if (pageTitle) {
      pageTitle("List Your Unit");
      pageMargin("50px auto");
      bgImage("/images/listApartment.png");
    }
  }, []);

  const imagePath = (path, isStatic = false) => {
    if (isStatic) return path;
    return process.env.REACT_APP_API_URL
      ? `${process.env.REACT_APP_API_URL}${path}`
      : path;
  };

  // const fileChangeHandler = (event) => {
  //   console.log(event.target.files);
  //   setState((s) => [
  //     ...s,
  //     ...Array.from(event.target.files).map((file) => ({
  //       image: file,
  //       path: URL.createObjectURL(file),
  //     })),
  //   ]);
  // };

  // const fileChangeHandlerDrag = (files) => {
  //   console.log(files, "files");
  //   setState((s) => [
  //     ...s,
  //     ...Array.from(files).map((file) => ({
  //       image: file,
  //       path: URL.createObjectURL(file),
  //     })),
  //   ]);
  // };

  const fileChangeHandler = async (event) => {
    console.log(event.target.files);
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    // Array(...event.target.files).map(async (img) => {
    //   const convertImg = await convertToBase64(img);
    //   setState((s) => [
    //     ...s,
    //     {
    //       image: convertImg,
    //       path: URL.createObjectURL(img),
    //       name: img.name,
    //     },
    //   ]);
    // });

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        ...s,
        {
          image: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
        },
      ]);
    };

    Array(...event.target.files) &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  const fileChangeHandlerDrag = async (files) => {
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    await Promise.all(
      Array(...files).map(async (img) => {
        const convertImg = await convertToBase64(img);
        setState((s) => [
          ...s,
          {
            image: convertImg,
            path: URL.createObjectURL(img),
            name: img.name,
          },
        ]);
      })
    );
    // setState((s) => [
    //   ...s,
    //   ...Array.from(files).map((file) => ({
    //     image: file,
    //     path: URL.createObjectURL(file),
    //   })),
    // ]);
  };

  useEffect(() => {
    form.setFieldsValue({
      images: state,
      ammenities: selectAmmenities,
      // apartment: localStorage.getItem("apartment_name"),
    });
  }, [form, state, selectAmmenities]);
  useEffect(() => {
    if (localStorage.getItem("apartment_name")) {
      setselectedApartment(true);
    }
  }, []);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    if (params.id) {
      // setselectedApartment(true);
      form.setFieldsValue({
        apartment_id: isEdit ? updateUnit?.apartment_id : params.id,
      });
    }
  }, [params.id]);

  const onCoverChange = (idx) => {
    const st = [...state];
    st.map((item, key) => {
      if (key != idx) {
        delete item.isCover;
      } else {
        st[idx].isCover = true;
      }
    });
    setState(st);
  };

  const onFinish = (value) => {
    if (params.id) {
      if (isEdit && Object.keys(updateUnit).length) {
        setloadings(true);
        value.user_id = user.id;
        value.id = updateUnit.id;
        value.updatedAt = currentDateTime;
        value.createdAt = updateUnit.createdAt
          ? updateUnit.createdAt
          : currentDateTime;
        // value.unitType = updateUnit?.unitType;
        value.unitStatus = updateUnit?.unitStatus;
        value.tenant = updateUnit?.tenant;
        value.fees = updateUnit?.fees;

        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }
        if (
          value.unit_name &&
          value.unitType &&
          value.sqft &&
          value.bed &&
          value.bath &&
          value.price
        ) {
          value.unitStatus = "Available";
        }

        dispatch(updateUnitApi(value)).then(({ payload }) => {
          getUpdatedUnit(payload);

          setloadings(false);
          onCancel();
        });
      } else {
        setloadings(true);
        // value.unitStatus = "Available";
        value.user_id = user.id;
        if (state?.length && value.images) {
          value.images[selectImgIdx].isCover = true;
        }
        value.tenant = {};
        if (
          value.unit_name &&
          value.unitType &&
          value.sqft &&
          value.bed &&
          value.bath &&
          value.price &&
          value.diposit
        ) {
          value.unitStatus = "Available";
        }
        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }
        value.updatedAt = currentDateTime;
        value.createdAt = currentDateTime;
        dispatch(createUnit(value)).then(({ payload }) => {
          newListedUnit(payload);
          setloadings(false);
          onCancel(false);

          // onCancel();
        });
      }
    } else {
      if (isEdit && Object.keys(updateUnit).length) {
        setloadings(true);
        value.user_id = user.id;
        value.id = updateUnit.id;
        value.updatedAt = currentDateTime;
        value.createdAt = updateUnit.createdAt
          ? updateUnit.createdAt
          : currentDateTime;
        value.unitStatus = updateUnit?.unitStatus;
        value.tenant = updateUnit?.tenant;
        value.fees = updateUnit?.fees;

        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }

        if (
          value.unit_name &&
          value.unitType &&
          value.sqft &&
          value.bed &&
          value.bath &&
          value.price &&
          value.diposit
        ) {
          value.unitStatus = "Available";
        }

        dispatch(updateUnitApi(value)).then(({ payload }) => {
          getUpdatedUnit(payload);

          setloadings(false);
          onCancel();
        });
      }
    }
    onFormChange(false);
  };

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setSelectAmmenities(checkedValues);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const drop = React.useRef(null);
  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);

    // return () => {
    //   drop.current.removeEventListener('dragover', handleDragOver);
    //   drop.current.removeEventListener('drop', handleDrop);
    // };
  }, []);

  const amenities = [
    "Bedroom",
    "Energy",
    "Ac",
    "Dishwasher",
    "Washroom",
    "Gym",
    "Kitchen",
    "Roof",
    "Fridge",
    "Yard",
    "Washer and Dryer",
    "Outdoor areas",
    "Cable ready",
    "Swimming pool",
    "Dining Area",
    "Gated Access",
    "Master Suite",
    "24/7 Water",
    "Pets allowed",
    "Internet access",
  ];

  return (
    <StyledForm wh={isModal ? "100" : "100"} smwh="100" xswh="100" xxswh="100">
      <StyledForms>
        {isModal && (
          <StyledTitle>
            {isEdit ? "Update Unit" : "New Unit Listing"}
          </StyledTitle>
        )}
        <Form
          layout="vertical"
          // requiredMark={"optional"}
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <StyledFormSelect wh="100">
            <Form.Item
              name="apartment_id"
              label="Select Property"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select
                placeholder="Select Property"
                // allowClear
                suffixIcon={<IoIosArrowDown />}
                disabled={isEdit}
                defaultValue={updateUnit?.apartment_id}
              >
                {getAllApartmentState?.map((item, idx) => (
                  <Option key={idx} value={item.id}>
                    {item.unit_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </StyledFormSelect>

          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="unit_name"
              label="Unit Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Unit Name" />
            </Form.Item>
          </StyledFormItem>

          <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
            <Form.Item
              name="unitType"
              label="Unit Type"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select
                placeholder="Unit Type"
                suffixIcon={<IoIosArrowDown />}
                // onChange={}
              >
                <Option value="Single Family Home">Single-Family Home</Option>
                <Option value="Condo">Condo</Option>
                <Option value="Townhouse">Townhouse</Option>
                <Option value="Apartment">Apartment</Option>
                <Option value="Farm/Ranch">Farm/Ranch</Option>
                <Option value="Multi-Family">Multi-Family</Option>
                <Option value="Commercial">Commercial</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="buldingNumber"
              label="Building Number"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="Building Number" />
            </Form.Item>
            <Form.Item
              name="unitNumber"
              label="Unit Number"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="Unit Number" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
            <Form.Item
              name="sqft"
              label="Sq ft"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input type="number" placeholder="Sq ft" min="0" />
            </Form.Item>
            <Form.Item
              name="bed"
              label="Bed"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                // onKeyDown={(event) => {
                //   if (event.key === ".") {
                //     event.preventDefault();
                //   }
                // }}
                // onInput={(event) => {
                //   event.target.value = event.target.value.replace(
                //     /[^0-9]*/g,
                //     ""
                //   );
                // }}
                // pattern="[0-9]"
                type="number"
                step="any"
                placeholder="Bed"
                min="0"
              />
            </Form.Item>
            <Form.Item
              name="bath"
              label="Bath"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                // onKeyDown={(event) => {
                //   if (event.key === ".") {
                //     event.preventDefault();
                //   }
                // }}
                // onInput={(event) => {
                //   event.target.value = event.target.value.replace(
                //     /[^0-9]*/g,
                //     ""
                //   );
                // }}
                // pattern="[0-9]"
                type="number"
                step="any"
                placeholder="Bath"
                min="0"
              />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem fd={"row"}>
            <Form.Item
              name="price"
              label="Price"
              className="input__affix"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                prefix={<BsCurrencyDollar className="site-form-item-icon" />}
                type="number"
                placeholder="Price"
                min="0"
                step="any"
              />
            </Form.Item>
            <Form.Item
              name="diposit"
              label="Deposit Amount"
              className="input__affix"

              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                prefix={<BsCurrencyDollar className="site-form-item-icon" />}
                type="number"
                step="any"
                placeholder="Deposit Amount"
                min="0"
              />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="images"
              label="Property Images"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadBox ref={drop} onClick={(e) => upload.current.click()}>
                <UploadInput>
                  <p className="ant-upload-text" style={{ fontSize: "16px" }}>
                    Upload Image of Property
                  </p>
                  <p className="ant-upload-drag-icon">
                    <AiOutlineUpload className="upload__icon" />
                  </p>
                  <p className="upload__icon">Upload</p>
                </UploadInput>
              </UploadBox>
              <input
                accept="image/*"
                id="myInput"
                multiple={"multiple"}
                type="file"
                ref={upload}
                style={{ display: "none" }}
                onChange={fileChangeHandler}
              />
              <div style={{ display: "flex" }}>
                {state.map((img, idx) =>
                  img.isCover ? (
                    <StyledUploadPreviewWrapperCover isPhoto={state.length}>
                      <CoverImage
                        src={img.path}
                        height={95}
                        width={95}
                        onDelete={() => {
                          const st = [...state];
                          // st.splice(0, 1);
                          st.splice(idx, 1);
                          setState(st);
                          // upload.current.value = null;
                        }}
                      />
                      <StyledCover>
                        <p>Cover</p>
                      </StyledCover>
                    </StyledUploadPreviewWrapperCover>
                  ) : (
                    <StyledUploadPreviewWrapper isPhoto={state.length}>
                      <ImagePreview
                        key={idx}
                        src={img.path ? img.path : imagePath(img.path)}
                        height={95}
                        width={95}
                        onDelete={() => {
                          const st = [...state];
                          st.splice(idx, 1);
                          setState(st);

                          // upload.current.value = null;
                        }}
                        onCover={() => {
                          const st = [...state];
                          setState(st);
                          setSelectImgIdx(idx);
                          onCoverChange(idx);
                        }}
                      />
                    </StyledUploadPreviewWrapper>
                  )
                )}
                {/* <StyledUploadPreviewWrapperCover isPhoto={state.length}>
                  <CoverImage
                    src={
                      state[selectImgIdx]?.path
                        ? state[selectImgIdx]?.path
                        : imagePath(state[selectImgIdx]?.path)
                    }
                    height={95}
                    width={95}
                    onDelete={() => {
                      const st = [...state];
                      st.splice(0, 1);
                      setState(st);
                      // upload.current.value = null;
                    }}
                  />
                  <StyledCover>
                    <p>Cover</p>
                  </StyledCover>
                </StyledUploadPreviewWrapperCover>
                <StyledUploadPreviewWrapper isPhoto={state.length}>
                  {state.slice(1).map((img, idx) => (
                    <>
                      {selectImgIdx === idx ? (
                        " "
                      ) : (
                        <ImagePreview
                          key={idx}
                          src={img.path ? img.path : imagePath(img.path)}
                          height={95}
                          width={95}
                          onDelete={() => {
                            const st = [...state];
                            st.splice(idx, 1);
                            setState(st);

                            // upload.current.value = null;
                          }}
                          onCover={() => {
                            const st = [...state];
                            setState(st);
                            setSelectImgIdx(idx);
                          }}
                        />
                      )}
                    </>
                  ))}
                </StyledUploadPreviewWrapper> */}
              </div>
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="ammenities"
              label="Amenities"
              // rules={[{ required: true }]}
            >
              <PropertyBtn
                br={viewAmmenities ? "5px 5px 0 0" : ""}
                onClick={() => setViewAmmenities(!viewAmmenities)}
              >
                <p>Property Amenities</p>
                {!viewAmmenities ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </PropertyBtn>
              {viewAmmenities && (
                <DropDownView>
                  <Checkbox.Group
                    defaultValue={selectAmmenities}
                    style={{ width: "100%" }}
                    onChange={onChange}
                  >
                    <Row justify="space-between">
                      {amenities?.slice(0, amenitiesLength).map((value, id) => (
                        <Col key={id} xs={10} sm={6}>
                          <Checkbox value={value}>{value}</Checkbox>
                        </Col>
                      ))}
                      {/* <Col xs={10} sm={6}>
                        <Checkbox value="Bedroom">Bedroom</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Energy">Energy</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Ac">Ac</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Dishwasher">Dishwasher</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Washroom">Washroom</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Outdoor areas">Outdoor areas</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Gym">Gym</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Cable ready">Cable ready</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Kitchen">Kitchen</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Roof">Roof</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Fridge">Fridge</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Swimming pool">Swimming pool</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Dining Area">Dining Area</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Yard">Yard</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Washer and Dryer">
                          Washer and Dryer
                        </Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Gated Access">Gated Access</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Master Suite">Master Suite</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="24/7 Water">24/7 Water</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Pets allowed">Pets allowed</Checkbox>
                      </Col>
                      <Col xs={10} sm={6}>
                        <Checkbox value="Internet access">
                          Internet access
                        </Checkbox>
                      </Col> */}
                    </Row>
                  </Checkbox.Group>
                  {/* <Row>
                    <Col
                      xl={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 20 }}
                      xs={{ span: 22 }}
                    >
                      <Checkbox.Group options={options} />
                    </Col>
                  </Row> */}
                  {amenitiesLength === amenities.length ? (
                    ""
                  ) : (
                    <Row>
                      <Col style={{ paddingTop: "15px", cursor: "pointer" }}>
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            setAmenitiesLength(amenities.length);
                          }}
                        >
                          VIEW MORE
                        </span>
                      </Col>
                    </Row>
                  )}
                </DropDownView>
              )}
              {!viewAmmenities && (
                <>
                  <Row gutter={[10, 10]}>
                    {selectAmmenities?.map((val, idx) => (
                      <Col key={idx}>
                        <ListWrap>
                          <p>{val}</p>
                          <MdClear onClick={() => removeAmmenities(val)} />
                        </ListWrap>
                      </Col>
                    ))}
                  </Row>
                  {/* {selectAmmenities.length ? (
                    <ListWrap>
                      <List
                        size="large"
                        header={<div>Header</div>}
                        footer={<div>Footer</div>}
                        bordered
                        dataSource={selectAmmenities}
                        renderItem={(item) => (
                          <List.Item
                            extra={
                              <TiDeleteOutline
                                onClick={() => removeAmmenities(item)}
                              />
                            }
                          >
                            {item}
                          </List.Item>
                        )}
                      />
                    </ListWrap>
                  ) : (
                    ""
                  )} */}
                </>
              )}
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="description"
              label="Description"
              // rules={[{ required: true }]}
            >
              <Input.TextArea
                className="description__box"
                placeholder="Description"
              />
            </Form.Item>
          </StyledFormItem>

          <Form.Item>
            <StyledButton htmlType="submit" loading={loadings}>
              {isEdit ? "Update   " : "List A New"} Unit
            </StyledButton>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
}

export default ListUnit;
