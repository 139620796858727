import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import { NoListingsSvg } from "../../assets/svgs";
import { Heading } from "../../ui/Heading/Heading";
import { Container } from "../../ui/Layout/Container";
import { AddNewApr } from "../listings/Listingstyle";
import AddApartmentModal from "../addApartmentModal/AddApartmentModal";
import { useLocation, useParams } from "react-router";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useSelector } from "react-redux";

function NoListing({ newListedApartment, newListedSubUnit }) {
  const [pageTitle, setPageTitle] = useState("");
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [svgWidth, setSvgWidth] = useState("388");
  const [svgHeight, setSvgHeight] = useState("265");
  const [valueOfOrg, setValueOfOrg] = useState([]);
  const [heading, setHeading] = useState("");

  const { allOrganization } = useSelector((state) => state.user);

  useEffect(() => {
    setPageTitle(localStorage.getItem("orgName"));
  }, [localStorage]);

  const { width } = useWindowSize();
  const location = useLocation();

  const params = useParams();

  useEffect(() => {
    if (width < 426) {
      setSvgWidth("300");
      setSvgHeight("210");
    } else {
      setSvgWidth("388");
      setSvgHeight("265");
    }
  }, [width, location.pathname]);

  useEffect(() => {
    if (params.id) {
      var arr = [];

      for (var item in allOrganization) {
        if (allOrganization.hasOwnProperty(item)) {
          arr.push(allOrganization[item]);
        }
      }

      var orgValue = arr.filter(function (item) {
        return item["id"] == localStorage.getItem("toSave");
      });
      setValueOfOrg(orgValue);
    } else {
      if (localStorage.getItem("org_id")) {
        var arr = [];

        for (var item in allOrganization) {
          if (allOrganization.hasOwnProperty(item)) {
            arr.push(allOrganization[item]);
          }
        }

        var orgValue = arr.filter(function (item) {
          return item["id"] == localStorage.getItem("org_id");
        });
        setValueOfOrg(orgValue);
      }
    }
  }, [params.id, heading, allOrganization]);

  useEffect(() => {
    if (valueOfOrg && valueOfOrg.length) {
      setHeading(valueOfOrg[0].org_name);
    }
  }, [valueOfOrg]);

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  return (
    <>
      <Container
        minH="calc(100vh - 80px)"
        padding="50px 50px 25px 330px"
        // paddingSm="50px 50px 25px 330px "
      >
        <CreateApartWrap>
          <Row gutter={[15, 15]} justify="space-between">
            <Col
              xl={{ span: 12 }}
              lg={{ span: 14 }}
              md={{ span: 14 }}
              sm={{ span: 14 }}
              xs={{ span: 24 }}
            >
              {/* <h2>{heading?.charAt(0).toUpperCase() + heading?.slice(1)}</h2> */}
              <h2>{upperCase(heading)}</h2>
            </Col>
            <Col
              xxl={{ span: 5 }}
              xl={{ span: 6 }}
              lg={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              xs={{ span: 24 }}
              style={{ textAlign: "end" }}
            >
              <AddNewApr
                onClick={() => setModalState(true)}
                displayView="unset"
              >
                Create a New Property
              </AddNewApr>
            </Col>
          </Row>
        </CreateApartWrap>
        <AddApartment>
          <Row>
            <Col>
              <NoListingsSvg width={svgWidth} height={svgHeight} />
            </Col>
          </Row>
          <Row className="new__organization">
            <Col>
              <p>
                Congratulations! You have successfully created an organization.
                <br /> Now you can easily create properties under it.
              </p>
            </Col>
          </Row>
          <Row className="add__apartment">
            <Col
              xl={{ span: 4 }}
              lg={{ span: 8 }}
              sm={{ span: 8 }}
              xs={{ span: 24 }}
            >
              <AddNewApr onClick={() => setModalState(true)}>
                Create a Property
              </AddNewApr>
            </Col>
          </Row>
        </AddApartment>
      </Container>
      <AddApartmentModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        newListedApartment={newListedApartment}
        newListedSubUnit={newListedSubUnit}
      />
    </>
  );
}

export default NoListing;

export const CreateApartWrap = styled.div`
  ${"" /* height: 100px; */}
  background: #ffffff;
  border-radius: 5px;
  padding: 25px 30px;
  align-items: center;
  .ant-row {
    align-items: center;
  }
  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.forth};
  }
  @media (max-width: 1540px) {
    padding: 15px 20px;
  }
`;

export const AddApartment = styled.div`
  padding-top: 125px;
  .ant-row {
    justify-content: center;
  }
  .new__organization {
    padding-top: 40px;
  }
  p {
    ${"" /* font-weight: 700; */}
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.forth};
    text-align: center;
  }
  svg {
    zoom: 90%;
  }
  @media (max-width: 1540px) {
    padding-top: 25px;

    .new__organization {
      padding-top: 20px;
    }
  }
  @media (max-width: 991.98px) {
    .new__organization {
      padding-top: 30px;
    }
  }
`;
