import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Space,
  Dropdown,
  Menu,
  Popover,
  Input,
} from "antd";
import { Container } from "../../ui/Layout/Container";
import TenantSearch from "./TenantSearch";
import { TiFilter } from "react-icons/ti";
import { FcInfo } from "react-icons/fc";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import styled from "styled-components";
import { Heading } from "../../ui/Heading/Heading";
import { Link, useLocation } from "react-router-dom";
import { FilterWrap } from "../dashboard/DashboardStyle";
import HeaderSearch from "../layout/header/HeaderSearch";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { ContainerWrap } from "../../ui/Layout/ContainerWrap";
import CheckboxMenu from "./CheckboxMenu";
import { MessagesSvg } from "../../assets/svgs";
import { BiSearch } from "react-icons/bi";
import { getAllTenants } from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";

function TableOne({ newValue }) {
  const [dayLeft, setDayLeft] = useState(0);
  const dispatch = useDispatch();

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day}-${month}-${year}`;
  }

  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);
    var newDate = new Date(date.setMonth(date.getMonth() + month));
    var newDay = new Date(newDate.setDate(newDate.getDate() - 1));

    return formatDate(newDay);
  };

  const dayLeftCounter = (dateValue, month) => {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    var date = new Date(dateValue);
    var newDate = new Date(date.setMonth(date.getMonth() + month));
    var newDay = new Date(newDate.setDate(newDate.getDate()));

    let date_ini = new Date();
    let date_end = new Date(formatDate(newDay));
    let diff = date_end.getTime() - date_ini.getTime();
    const val = Math.floor(diff / day);

    if (Math.sign(val) === -1) {
      return "Lease Expired";
    } else {
      return `${val} Left`;
    }
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = React.useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // icon={<BiSearch />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <BiSearch
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <BiSearch
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const balanceColor = (value) => {
    if (Number(value.rate) - Number(value.balance) < 0) {
      return (
        <td style={{ color: "green" }}>
          {toUSACurrency(Math.abs(Number(value.rate) - Number(value.balance)))}
        </td>
      );
    } else if (Number(value.rate) - Number(value.balance) === 0) {
      return (
        <td style={{ color: "rgb(33, 150, 243)" }}>
          {toUSACurrency(Number(value.rate) - Number(value.balance))}
        </td>
      );
    } else {
      return (
        <td style={{ color: "#e73030" }}>
          {toUSACurrency(Number(value.rate) - Number(value.balance))}
        </td>
      );
    }
  };

  const dueDateColor = (value) => {
    if (Number(value.rate) - Number(value.balance) < 0) {
      return (
        <p style={{ color: "green" }}>
          Balance :{" "}
          {toUSACurrency(Math.abs(Number(value.rate) - Number(value.balance)))}
        </p>
      );
    } else if (Number(value.rate) - Number(value.balance) === 0) {
      return (
        <p style={{ color: "rgb(33, 150, 243)" }}>
          Balance : {toUSACurrency(Number(value.rate) - Number(value.balance))}
        </p>
      );
    } else {
      return (
        <p style={{ color: "#F67C36 " }}>
          Balance : {toUSACurrency(Number(value.rate) - Number(value.balance))}
        </p>
      );
    }
  };

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  const columns = [
    {
      title: "TENANT NAME",
      dataIndex: "tenantName",
      width: 170,
      ...getColumnSearchProps("tenantName"),
      render: (_, record) => (
        <div>
          <td>
            {upperCase(record.firstName)} {upperCase(record.lastName)}
          </td>
        </div>
      ),
    },
    {
      title: "UNIT",
      dataIndex: "unit",
      width: 120,
    },
    {
      title: "UNIT TYPE",
      dataIndex: "unitType",
      width: 120,
    },
    {
      title: "LEASE TERM",
      dataIndex: "leaseTerm",
    },
    {
      title: "LEASE START",
      dataIndex: "leaseStart",
    },
    {
      title: "DUE DATE",
      dataIndex: "dueDate",
      render: (_, record) => (
        <div>
          <Space size="middle">
            <td>
              {dateConverter(
                record.leaseStart,
                Number(record.leaseTerm.substring(0, 2))
              )}
            </td>
            <Popover
              content={
                <div>
                  <p style={{ color: "#f65936 " }}>
                    Due Day :{" "}
                    {dayLeftCounter(
                      record.leaseStart,
                      Number(record.leaseTerm.substring(0, 2))
                    )}
                  </p>
                  {dueDateColor(record)}
                </div>
              }
              title="DUE DAY"
            >
              <FcInfo />
            </Popover>
          </Space>
        </div>
      ),
    },
    {
      title: "RATE",
      dataIndex: "rate",
      sorter: (a, b) => Number(a.rate) - Number(b.rate),
      render: (_, record) => <td>{toUSACurrency(Number(record.rate))}</td>,
    },
    {
      title: "BALANCE",
      dataIndex: "balance",
      render: (_, record) => (
        <Space size="middle">
          {balanceColor(record)}
          <Popover
            content={
              <div>
                <p>Rate : {toUSACurrency(Number(record.rate))}</p>
                <p style={{ color: "#F67C36 " }}>
                  Balance : {toUSACurrency(Number(record.balance))}
                </p>
              </div>
            }
            title="BALANCE"
          >
            <FcInfo />
          </Popover>
        </Space>
      ),
    },
    {
      title: "MESSAGE",
      dataIndex: "sendmessage",
      width: 100,

      render: (_, record) => (
        <Space size="middle">
          <Link to="/tenants">
            <MessagesSvg />
          </Link>
          {/* <Link to="/tenants">Send Message</Link> */}
          {/* <Link to="/tenants">
            <MdOutlineArrowForwardIos />
          </Link> */}
        </Space>
      ),
    },
  ];

  const [columans, setColumans] = useState(columns);
  const [allTenantsObj, setAllTenantsObj] = useState({});
  const [allTenantsArr, setAllTenantsArr] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getAllTenants()).then(({ payload }) => {
      setAllTenantsObj(payload);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (allTenantsObj && Object.keys(allTenantsObj).length) {
      const arr = Object.values(allTenantsObj);
      setAllTenantsArr(arr);
    }
  }, [allTenantsObj]);

  const data = [
    {
      key: "1",
      tenantName: "Abram Esparza",
      unit: 125,
      unitType: "Studio",
      leaseTerm: "12 Month",
      leaseup: "11-09-2021",
      rate: "1569",
      marketrate: "$4589",
      leaseStart: "22-Sep-2021",
      balance: "1700",
    },
    {
      key: "2",
      tenantName: "Lila Klein",
      unit: 856,
      unitType: "2 Bed",
      leaseTerm: "11 Month",
      leaseup: "12-07-2021",
      rate: "5895",
      marketrate: "$5,895",
      leaseStart: "20-Aug-2022",
      balance: "1500",
    },
    {
      key: "3",
      tenantName: "Kymani Parks",
      unit: 745,
      unitType: "3 Bed",
      leaseTerm: "06 Month",
      leaseup: "06-01-2021",
      rate: "1569",
      marketrate: "$8,569",
      sendmessage: "Send Message   >",
      leaseStart: "12-Oct-2022",
      balance: "1500",
    },
    {
      key: "4",
      tenantName: "Matias Chung",
      unit: 241,
      unitType: "Studio",
      leaseTerm: "14 Month",
      leaseup: "11-06-2021",
      rate: "7698",
      marketrate: "$1,569",
      sendmessage: "Send Message   >",
      leaseStart: "10-Feb-2019",
      balance: "1500",
    },
    {
      key: "5",
      tenantName: "Jaqueline Mcdaniel",
      unit: 452,
      unitType: "Studio",
      leaseTerm: "20 Month",
      leaseup: "12-07-2021",
      rate: "1569",
      marketrate: "$4,589",
      sendmessage: "Send Message   >",
      leaseStart: "14-Dec-2020",
      balance: "1500",
    },
    {
      key: "6",
      tenantName: "Teagan Reynolds",
      unit: 745,
      unitType: "5 Bed",
      leaseTerm: "18 Month",
      leaseup: "06-01-2021",
      rate: "8569",
      marketrate: "$8,569",
      sendmessage: "Send Message   >",
      leaseStart: "22-Sep-2022",
      balance: "1500",
    },
    {
      key: "7",
      tenantName: "Kaylie Dixon",
      unit: 212,
      unitType: "6 Bed",
      leaseTerm: "07 Month",
      leaseup: "11-06-2021",
      rate: "1569",
      marketrate: "$5,895",
      sendmessage: "Send Message   >",
      leaseStart: "20-May-2021",
      balance: "1569",
    },
    {
      key: "8",
      tenantName: "Winston Martinez",
      unit: 210,
      unitType: "1 Bed",
      leaseTerm: "11 Month",
      leaseup: "12-07-2021",
      rate: "5895",
      marketrate: "$4,589",
      leaseStart: "18-Apr-2022",
      balance: "1500",
    },
    {
      key: "9",
      tenantName: "Mattie Mccoy",
      unit: "011",
      unitType: "Studio",
      leaseTerm: "10 Month",
      leaseup: "06-01-2021",
      rate: "8569",
      marketrate: "$8,569",
      leaseStart: "17-Jun-2022",
      balance: "1500",
    },
  ];

  const { width } = useWindowSize();
  const location = useLocation();
  const [yScroll, setyScroll] = useState(250);

  useEffect(() => {
    if (width < 1550) {
      setyScroll(250);
    } else {
      setyScroll(350);
    }
  }, [width, location.pathname]);

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  function getMenuLink(key) {
    switch (key) {
      case "tenantName":
        return {
          title: "TENANT NAME",
          dataIndex: "tenantName",
          width: 170,
          ...getColumnSearchProps("tenantName"),
        };
      case "unit":
        return {
          title: "UNIT",
          dataIndex: "unit",
          width: 120,
        };
      case "unitType":
        return {
          title: "UNIT TYPE",
          dataIndex: "unitType",
          width: 120,
        };
      case "leaseTerm":
        return {
          title: "LEASE TERM",
          dataIndex: "leaseTerm",
        };
      case "leaseStart":
        return {
          title: "LEASE START",
          dataIndex: "leaseStart",
        };
      case "dueDate":
        return {
          title: "DUE DATE",
          dataIndex: "dueDate",
          render: (_, record) => (
            <div>
              <Space size="middle">
                <td>
                  {dateConverter(
                    record.leaseStart,
                    Number(record.leaseTerm.substring(0, 2))
                  )}
                </td>
                <Popover
                  content={
                    <div>
                      <p style={{ color: "#f65936 " }}>
                        Due Day :{" "}
                        {dayLeftCounter(
                          record.leaseStart,
                          Number(record.leaseTerm.substring(0, 2))
                        )}
                      </p>
                      {dueDateColor(record)}
                    </div>
                  }
                  title="DUE DAY"
                >
                  <FcInfo />
                </Popover>
              </Space>
            </div>
          ),
        };
      case "rate":
        return {
          title: "RATE",
          dataIndex: "rate",
          sorter: (a, b) => Number(a.rate) - Number(b.rate),
          render: (_, record) => <td>{toUSACurrency(Number(record.rate))}</td>,
        };
      case "dueDate":
        return {
          title: "DUE DATE",
          dataIndex: "dueDate",
        };
      case "balance":
        return {
          title: "BALANCE",
          dataIndex: "balance",
          render: (_, record) => (
            <Space size="middle">
              {balanceColor(record)}
              <Popover
                content={
                  <div>
                    <p>Rate : {toUSACurrency(Number(record.rate))}</p>
                    <p style={{ color: "#F67C36 " }}>
                      Balance : {toUSACurrency(Number(record.balance))}
                    </p>
                  </div>
                }
                title="BALANCE"
              >
                <FcInfo />
              </Popover>
            </Space>
          ),
        };
      case "leaseup":
        return {
          title: "LEASE UP",
          dataIndex: "leaseup",
        };
      case "sendmessage":
        return {
          title: "MESSAGE",
          dataIndex: "sendmessage",
          width: 100,

          render: (_, record) => (
            <Space size="middle">
              <Link to="/tenants">
                <MessagesSvg />
              </Link>
              {/* <Link to="/tenants">Send Message</Link>
              <Link to="/tenants">
                <MdOutlineArrowForwardIos />
              </Link> */}
            </Space>
          ),
        };
      default:
        return null;
    }
  }
  function getMenu(key) {
    switch (key) {
      case "TENANT NAME":
        return "tenantName";
      case "UNIT":
        return "unit";
      case "UNIT TYPE":
        return "unitType";
      case "LEASE TERM":
        return "leaseTerm";
      case "LEASE START":
        return "leaseStart";
      case "RATE":
        return "rate";
      case "DUE DATE":
        return "dueDate";
      case "BALANCE":
        return "balance";
      case "SEND MESSAGE":
        return "sendmessage";
    }
  }

  const onCheckboxChange = (selection) => {
    var arr = [];
    if (selection.length) {
      selection.map((value) => {
        // arr.push(getMenuLink(value));
        arr.push(getMenu(value));
      });
    }
    if (arr?.length) {
      var arr2 = [];
      const abc = columns?.map((val) =>
        arr.filter(function (item) {
          return item === val["dataIndex"];
        })
      );
      arr2.push(...abc);
      if (arr2?.length) {
        var arr3 = [];
        arr2.map((val) => {
          arr3.push(getMenuLink(...val));
        });
        var arr4 = [];
        if (arr3?.length) {
          arr3.map((value) => {
            if (value === null) {
            } else {
              arr4.push(value);
            }
          });
        }
        setColumans(arr4);
      }
    }
  };

  return (
    <TableOneWrap>
      <ContainerWrap
        paddings="10px"
        minH="auto"
        brRadius="5px"
        bgColor="#ffffff"
      >
        <Row justify="space-between">
          <Col
            xl={{ span: 9 }}
            md={{ span: 8 }}
            sm={{ span: 10 }}
            xs={{ span: 24 }}
          >
            <Heading fontSize="20px">
              <Link to="/tenants">Current Tenants</Link>
            </Heading>
          </Col>
          <Col
            xl={{ span: 8 }}
            md={{ span: 12 }}
            sm={{ span: 10 }}
            xs={{ span: 24 }}
            style={{ display: "flex" }}
            className="search__filter"
          >
            <HeaderSearch height="40px" bgcolor="#eeeeee" />
            <CheckboxMenu
              options={[
                "TENANT NAME",
                "UNIT",
                "UNIT TYPE",
                "LEASE TERM",
                "RATE",
                "DUE DATE",
                "BALANCE",
                "SEND MESSAGE",
                "LEASE START",
              ]}
              value={[
                "TENANT NAME",
                "UNIT",
                "UNIT TYPE",
                "LEASE TERM",
                "RATE",
                "DUE DATE",
                "BALANCE",
                "SEND MESSAGE",
                "LEASE START",
              ]}
              onChange={onCheckboxChange}
            />

            {/* <FilterButton icon={<TiFilter className="" />}>Filter</FilterButton> */}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Table
              columns={columans}
              dataSource={allTenantsArr}
              // dataSource={data}
              size="middle"
              pagination={false}
              scroll={{ x: 1100, y: yScroll }}
              loading={loading}
              // bordered
            />
          </Col>
        </Row>
        <Row justify="end" className="pagination__wrap">
          <Col>
            <Pagination size="small" defaultPageSize={1} total={9} />
          </Col>
        </Row>
      </ContainerWrap>
    </TableOneWrap>
  );
}

export default TableOne;

export const TableOneWrap = styled.div`
  ${"" /* margin-top: 22px; */}
  border-radius: 5px;

  .ant-space {
    :hover,
    :focus {
      a {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
  .ant-table-thead > tr > th {
    background-color: #dee8ff;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.forth};
  }
  .ant-table.ant-table-middle {
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    cursor: pointer;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 8px 8px;
  }

  .ant-table {
    ${
      "" /* svg {
      margin-top: 10px;
    } */
    }
  }
  /* FOR PAGINATION */
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .pagination__wrap {
    padding-top: 30px;
  }
  .ant-pagination-prev .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-left: 5px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.secondary};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
  a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-table-body {
    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    ${
      "" /* .ant-table-row{
      line-height: 0.5715;
    } */
    }
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-size: 14px;
      line-height: 12px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-pagination.mini .ant-pagination-options {
      display: none;
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 6px 4px;
    }
  }
  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 5px;
    }
  }

  @media (max-width: 991.98px) {
    .search__filter {
      margin-bottom: 10px;
    }
  }
`;

export const FilterButton = styled(Button)`
  ${"" /* width: 100%; */}
  margin-left:15px;
  height: 40px !important;
  padding: 0 15px;
  border: 1px solid ${({ theme }) => theme.colors.third} !important;
  color: #777777;
  font-size: 18px;
  background: ${({ theme }) => theme.colors.third};
  border-radius: 5px;
  .anticon {
    transform: rotate(180deg) !important;
  }
  :hover,
  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.third} !important;
    background: #f9a97c;
    color: #ffffff;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: #ffffff;
  }
  svg {
    margin-bottom: -6px;
    font-size: 26px;
    color: #ffffff;
  }

  @media (max-width: 1550px) {
    height: 32px !important;
    font-size: 14px;
    span {
      font-size: 14px;
      line-height: 16px;
    }
    svg {
      margin-bottom: -4px;
      font-size: 20px;
    }
  }
`;
