import styled from "styled-components";

export const AtchBox = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 32px;
    color: ${({ fontColor }) => fontColor || "#4160ac"};
  }
  h5 {
    cursor: pointer;
    margin-left: 15px;
    color: ${({ fontColor }) => fontColor || "#4160ac"};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
  }
  @media (max-width: 1550px) {
    h5 {
      font-size: 16px;
      line-height: 20px;
      margin-left: 5px;
    }
  }
`;

export const MessagerList = styled.div`
  margin-top: 30px;
  cursor: pointer;
  transition: transform 0.6s;
  :hover {
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05);
  }
`;

export const MessagerBox = styled.div`
  align-items: center;
  display: flex;
  align-items: start;
  justify-content: space-between;
  ${
    "" /* .ant-row {
    display: contents;
    flex-flow: row wrap;
  } */
  }
  p {
    margin: 0;
    margin-left: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.forth};
  }
  .message__time {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const MessagesWrap = styled.div`
  .last__message {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
  }
  .message__left {
    min-height: calc(100vh - 145px);
    max-height: calc(100vh - 145px);
    overflow-y: scroll;

    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }
    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: rgb(97, 96, 96);
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  @media (max-width: 1550px) {
    .message__left {
      min-height: calc(100vh - 110px);
      max-height: calc(100vh - 110px);
    }
  }
  @media (max-width: 768px) {
    .message__left {
      min-height: 0;
      max-height: 0;
    }
  }
`;

export const TopMessageBar = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(131, 131, 131, 0.1);
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  padding: 20px;
  display: flex;

  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #2b353f;
    margin-bottom: 0;
  }

  .user__icon {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.forth};
    background: #ededed;
    width: 40px;
    height: 40px;
  }

  @media (max-width: 1550px) {
    padding: 14px;

    h1 {
      font-size: 20px;
      line-height: 22px;
    }
  }
`;

export const ChatBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0 4px 4px 0;
  padding: 20px;

  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  overflow-y: scroll;

  display: flex;
  flex-direction: column-reverse;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #aaaaaa;
    text-align: center;
  }
  span {
    display: flex;
    justify-content: center;
  }

  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    min-height: calc(100vh - 260px);
    max-height: calc(100vh - 260px);
    padding: 15px;
  }
  @media (max-width: 768px) {
    padding: 15px;
    min-height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
  }
`;

export const TextBox = styled.div`
  ${"" /* background: #ffffff; */}
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 25px;
  display: flex;
  ${"" /* padding: 10px; */}
`;

export const SendEmail = styled.div`
  border-left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .ant-btn:active {
    border-color: #fff;
  }

  .ant-input-prefix {
    margin-right: 20px;
  }

  .ant-btn {
    height: 50px;
    border-left: 0;
    color: #f67c36;
    background: #fff;
    border: none;
    svg {
      margin-top: 5px;
      font-size: 24px;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #f67c36;
    border-color: #d9d9d9;
    background: #fff;
  }

  .ant-input:hover {
    border-right: none;
    border-color: #d9d9d9;
  }

  .ant-input {
    background: #fff;
    border-right: none;
  }
  .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #fff;
    border: none;
  }
  .ant-input-group.ant-input-group-compact > *:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #eeeeee;
    box-shadow: 0 0 0 2px #eeeeee;
    border-right-width: 0;
    outline: 0;
  }

  .ant-input-affix-wrapper:focus {
    border-color: #fff;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fff;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: #fff;
  }

  .ant-input-group.ant-input-group-compact {
    display: flex;
  }

  @media (max-width: 1550px) {
    width: 100%;
  }
`;

export const LeftMessage = styled.div`
  background: ${({ background }) => background || "#4160ac"};
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  margin-top: 20px;
  width: ${({ width }) => width || "fit-content"};
  align-items: end;
  display: flex;

  .typing {
    position: relative;
    padding: 5px;
  }

  .typing__dot {
    float: left;
    width: 10px;
    height: 10px;
    margin: 0 4px;
    background: #4160ac;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }

  .typing__dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }

  h2 {
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    margin-left: 20px;
  }

  @media (max-width: 1550px) {
    margin-top: 15px;
    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const RightMessage = styled.div`
  background: #eeeeee;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  width: ${({ width }) => width || "fit-content"};
  margin-left: auto;
  margin-top: 20px;

  justify-content: end;
  align-items: end;
  text-align: end;
  display: flex;
  right: 20px;
  h2 {
    color: #2b353f;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    margin-left: 20px;
  }

  @media (max-width: 1550px) {
    margin-top: 15px;

    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const AttachMent = styled.div`
  min-width: 50px;
  min-height: 50px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 22px;
  }
`;
