import React from "react";
import { Avatar } from "antd";
import { getInitials } from "../../utils/fn";
import styled from "styled-components";

const UserAvatar = ({ src, fullName, ...rest }) => {
  const avatarProps = {
    ...(src ? { src } : { children: getInitials(fullName) }),
  };
  return <StyledAvatar {...rest} {...avatarProps} />;
};

const StyledAvatar = styled(Avatar)`
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  /* iOS fix */
  /* background-color: ${(props) => props.theme.colors.primary}; */
  .ant-avatar-string {
    z-index: 1;
    color: ${(props) => props.theme.colors.primary};
    font-size: calc(${({ size }) => size || 32}px / 2.3);
  }

  &:after {
    position: absolute;
    content: "";
    top: ${({ border }) => border || 1}px;
    left: ${({ border }) => border || 1}px;
    width: calc(100% - ${({ border }) => border * 2 || 2}px);
    height: calc(100% - ${({ border }) => border * 2 || 2}px);
    background-color: #ccc;
    border-radius: 50%;
    z-index: 0;
  }
  /* // iOS fix */
  > img {
    border-radius: 50%;
    padding: ${({ border }) => border || 1}px; // iOS Fix
    z-index: 1; // iOS Fix
  }
`;

export default UserAvatar;
