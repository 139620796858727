import React from "react";
import styled from "styled-components";
import { MdModeEdit } from "react-icons/md";
import { EditSvg, RightSideArrow } from "../../assets/svgs";

function Actions({ onClick }) {
  return (
    <ActionsWrap>
      <EditIconWrap onClick={() => onClick}>
        <EditSvg />
      </EditIconWrap>
      <ViewIconWrap>
        <RightSideArrow />
      </ViewIconWrap>
    </ActionsWrap>
  );
}

export default Actions;

export const ActionsWrap = styled.div`
  display: flex;
  justify-content: center;
`;
export const EditIconWrap = styled.div`
  cursor: pointer;
  color: #777777;
  font-size: 16px;
  padding-right: 35px;
`;
export const ViewIconWrap = styled.div`
  cursor: pointer;
  color: #777777;
  font-size: 30px;
`;
