import React from "react";

const supportSvg = ({
  size = "20",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.7 0C4.34028 0 0 4.34028 0 9.7C0 15.0597 4.35573 19.4 9.7 19.4C15.0443 19.4 19.4 15.0443 19.4 9.7C19.4 4.35573 15.0597 0 9.7 0ZM9.7 1.17388C11.8006 1.17388 13.7159 1.93073 15.1987 3.19729L12.851 5.52962C11.9705 4.86544 10.8739 4.46385 9.7 4.46385C8.52611 4.46385 7.41401 4.86544 6.54904 5.52962L4.20127 3.19729C5.68407 1.94618 7.59936 1.17388 9.7 1.17388ZM13.7468 9.7C13.7468 11.9242 11.9396 13.7468 9.7 13.7468C7.46035 13.7468 5.65318 11.9242 5.65318 9.7C5.65318 7.47579 7.47579 5.65318 9.7 5.65318C11.9242 5.65318 13.7468 7.47579 13.7468 9.7ZM1.17388 9.7C1.17388 7.52213 1.99251 5.52962 3.35175 4.03137L5.68407 6.36369C4.92723 7.275 4.4793 8.43344 4.4793 9.7C4.4793 10.8893 4.88089 11.986 5.54506 12.851L3.19729 15.1987C1.94618 13.7159 1.17388 11.8006 1.17388 9.7ZM9.7 18.2261C7.52213 18.2261 5.52962 17.4075 4.03137 16.0482L6.36369 13.7159C7.275 14.4728 8.43344 14.9207 9.7 14.9207C10.9666 14.9207 12.1404 14.4728 13.0363 13.7159L15.3686 16.0482C13.8704 17.392 11.8779 18.2261 9.7 18.2261ZM16.2027 15.1987L13.8704 12.851C14.5345 11.9705 14.9361 10.8739 14.9361 9.7C14.9361 8.43344 14.4882 7.25955 13.7314 6.36369L16.0637 4.03137C17.4075 5.54506 18.2416 7.52213 18.2416 9.7C18.2261 11.8006 17.4538 13.7159 16.2027 15.1987Z"
        fill="#777777"
      />
    </svg>
  );
};

export default supportSvg;
