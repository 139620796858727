import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "antd";
import { HiPlus } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { Heading } from "../../ui/Heading/Heading";
import { Container } from "../../ui/Layout/Container";
import AddApartmentModal from "../addApartmentModal/AddApartmentModal";
import {
  FloatButton,
  SecondaryButton,
} from "../apartments-view/ApartmentsViewStyle";
import { AddNewApr } from "../listings/Listingstyle";
import styled from "styled-components";
import AddDiscountModal from "./AddDiscountModal";
import Actions from "./Actions";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import FetureSoon from "../feture-soon/FetureSoon";

function DiscountCode() {
  const { width } = useWindowSize();
  const location = useLocation();
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);

  const [yScroll, setyScroll] = useState(450);

  useEffect(() => {
    if (width < 1550) {
      setyScroll(450);
    } else {
      setyScroll(600);
    }
  }, [width, location.pathname]);
  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "35%",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      width: "10%",
    },

    {
      title: "End Date",
      dataIndex: "endDate",
      width: "10%",
    },
    {
      title: "Discount",
      dataIndex: "discount",
    },
    {
      title: "Code",
      dataIndex: "code",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      //   width: "13%",
      render: (_, { status }) => {
        let color = "red";
        if (status === "Unactive") {
          color = "#E04141";
        } else if (status === "Active") {
          color = "#58D255";
        } else if (status === "Available") {
          color = "#58D255";
        } else if (status === "Pending") {
          color = "#F67C36";
        }
        return (
          <span style={{ color: color }} key={status}>
            {status}
          </span>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      align: "right",
      // width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            // item={record._id}
            // onChange={actionHandler}
          />
        );
      },
    },
  ];

  const dataSource = [
    {
      key: "1",
      name: "New Launch",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Unactive",
    },
    {
      key: "2",
      name: "Summer offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Active",
    },
    {
      key: "3",
      name: "Vacation offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Unactive",
    },
    {
      key: "4",
      name: "Tour offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Active",
    },
    {
      key: "5",
      name: "New Launch",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Unactive",
    },
    {
      key: "6",
      name: "New Launch",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Active",
    },
    {
      key: "7",
      name: "Summer offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Unactive",
    },
    {
      key: "8",
      name: "Vacation offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Active",
    },
    {
      key: "9",
      name: "Tour offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Unactive",
    },
    {
      key: "10",
      name: "New Launch",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Active",
    },
    {
      key: "11",
      name: "New Launch",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Unactive",
    },
    {
      key: "12",
      name: "Summer offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Active",
    },
    {
      key: "13",
      name: "Vacation offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Unactive",
    },
    {
      key: "14",
      name: "Tour offer",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Active",
    },
    {
      key: "15",
      name: "New Launch",
      description:
        "simply dummy text of the printing and typesetting industry...",
      startDate: "01 Sep, 2022",
      endDate: "06 Sep, 2022",
      discount: "50%",
      code: "ABC50D",
      status: "Unactive",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    size: "small",
  });
  const fetchData = (params = {}) => {
    setLoading(true);
    setPagination({
      ...params.pagination,
      total: 20, // 200 is mock data, you should read it from server
      // total: data.totalCount,
    });
    setTimeout(() => {
      setLoading(false);
    }, 500);

    //   });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  return (
    <Container paddingSm="40px 40px 0 265px">
          <FetureSoon />

      <Row justify="space-between">
        <Col>
          <Heading>
            <Link to="/discount-code">Discount Code</Link>
          </Heading>
        </Col>
        <Col>
          <AddNewApr
            bgColor={"#777777"}
            fw={"400"}
            displayView="none"
            onClick={() => setModalState(true)}
          >
            Create a new code
          </AddNewApr>
        </Col>
      </Row>
      <DiscountTable>
        <Table
          columns={columns}
          // rowKey={(record) => record.login.uuid}
          dataSource={dataSource}
          loading={loading}
          onChange={handleTableChange}
          //   pagination={{
          //     current: 1,
          //     pageSize: 10,
          //     size: "small",
          //     // total: 90,
          //   }}
          pagination={pagination}
          // onChange={this.handleTableChange}
          scroll={{ x: 1200, y: yScroll }}
          // scroll={{ x: 1000 }}
          // scroll={{ y: 350 }}
        />
      </DiscountTable>
      <FloatButton>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
          onClick={() => setModalState(true)}
        />
      </FloatButton>
      <AddDiscountModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </Container>
  );
}

export default DiscountCode;

export const DiscountTable = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

  .ant-table-body {
    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .ant-table-container,
  .ant-table-content,
  table {
    border-radius: 5px 5px 0 0 !important;
  }

  .ant-table-container .ant-table-content table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  .ant-table-container,
  .ant-table-header,
  table {
    border-radius: 5px 5px 0 0 !important;
  }
  .ant-table-container .ant-table-body table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.forth};
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background-color: ${({ theme }) => theme.colors.forth} !important;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
  }

  /* FOR PAGINATION */
  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.forth};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }

  @media (max-width: 1550px) {
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      padding: 12px 12px;
    }

    .ant-table-tbody > tr > td {
      font-size: 14px;
      line-height: 16px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-pagination.mini .ant-pagination-options {
      display: none;
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 5px 4px;
    }
  }

  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
  }
`;
