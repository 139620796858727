import React from "react";
import styled from "styled-components";
import { MdModeEdit } from "react-icons/md";
import { CrossSvg, DownloadSvg, EditSvg } from "../../assets/svgs";

function Actions() {
  return (
    <ActionsWrap>
      {/* <EditIconWrap>
        <EditSvg />
      </EditIconWrap>
      <ViewIconWrap>
        <CrossSvg />
      </ViewIconWrap> */}
      <DownloadWrap>
        <DownloadSvg />
      </DownloadWrap>
    </ActionsWrap>
  );
}

export default Actions;

export const ActionsWrap = styled.div`
  display: flex;
  justify-content: center;
`;
export const EditIconWrap = styled.div`
  cursor: pointer;
  color: #777777;
  font-size: 16px;
  padding-right: 35px;
`;
export const ViewIconWrap = styled.div`
  cursor: pointer;
  color: #777777;
  font-size: 14px;
  padding-right: 35px;
`;
export const DownloadWrap = styled.div`
  cursor: pointer;
  color: #777777;
  font-size: 14px;
`;
