import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation } from "react-router";
import { Button, DatePicker, Form, Input, Modal, Radio } from "antd";
import moment from "moment";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import { useBeforeunload } from "react-beforeunload";

function AddDiscountModal({ modalState, onCancel }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const { width } = useWindowSize();
  const [isFormChange, setIsFormChange] = useState(false);
  const [size, setSize] = useState("$");
  const [modalWidth, setModalWidth] = useState(800);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    if (width < 1550) {
      setModalWidth(640);
    } else {
      setModalWidth(800);
    }
  }, [width, location.pathname]);

  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const onFinish = (value) => {
    console.log(value, "value");
  };
  const onChange = (date, dateString) => {
    setSelectedDate(date);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };
  const disabledDateAfter = (current) => {
    // Can not select days before today and today
    return current && current < selectedDate;
  };

  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          if (!isFormChange) {
            onCancel(false);
          } else {
            openPrompt(
              "You will lose all changes if you navigate away from this page without saving"
            );
          }
        }}
        width={modalWidth}
        footer={null}
        style={{ borderRadius: "5px", top: "50px" }}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <StyledTitle>Add Discount Code</StyledTitle>

            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="offer_name"
                  label="Enter Offer Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter Offer Name" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem smwh="100">
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    format="DD MMM, YYYY"
                    disabledDate={disabledDate}
                    onChange={onChange}
                    placeholder="Start Date"
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item
                  name="endDate"
                  label="End Date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    format="DD MMM, YYYY"
                    disabledDate={disabledDateAfter}
                    onChange={onChange}
                    placeholder="End Date"
                    allowClear={false}
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem radioHeight="30px" radioWidth="30px" smwh="100">
                <Form.Item
                  name="discount"
                  label="Discount"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Discount"
                step="any"

                    type="number"
                    addonAfter={
                      <>
                        <Radio.Group
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                        >
                          <Radio.Button value="$">$</Radio.Button>
                          <Radio.Button value="%">%</Radio.Button>
                        </Radio.Group>
                      </>
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="discountCode"
                  label="Enter Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Enter Code" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="description"
                  label="description"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    className="description__box"
                    placeholder="Description"
                  />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <StyledButton
                  htmlType="submit"
                  style={{ letterSpacing: "1px" }}
                >
                  Add Code
                </StyledButton>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </Modal>
    </>
  );
}

export default AddDiscountModal;
