import React, { useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { IoIosClose } from "react-icons/io";
import { Modal } from "antd";
import ListUnit from "../unit-listing/ListUnit";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation } from "react-router";

function UpdateUnitModal({
  updateUnit = [],
  modalState,
  onCancel,
  isApart = false,
  isEdit,
  getUpdatedUnit,
  getAllApartmentState,
}) {
  const [isFormChange, setIsFormChange] = useState(false);
  const [isApartValue, setIsApartValue] = useState(isApart);

  const { width } = useWindowSize();
  const location = useLocation();

  const [modalWidth, setModalWidth] = useState(1000);

  useEffect(() => {
    if (width < 1550) {
      setModalWidth(800);
    } else {
      setModalWidth(1000);
    }
  }, [width, location.pathname]);

  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };
  const apartValue = (val) => {
    setIsApartValue(val);
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          if (!isFormChange) {
            onCancel(false);
          } else {
            openPrompt(
              "You will lose all changes if you navigate away from this page without saving"
            );
          }
        }}
        width={modalWidth}
        footer={null}
        style={{ borderRadius: "5px", top: "50px" }}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <ListUnit
          isModal={true}
          onCancel={onCancel}
          apartValue={apartValue}
          isEdit={true}
          updateUnit={updateUnit}
          getUpdatedUnit={getUpdatedUnit}
          getAllApartmentState={getAllApartmentState}
        />
      </Modal>
    </>
  );
}

export default UpdateUnitModal;
