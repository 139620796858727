import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { PersonalDetailsWrap } from "./PersonalDetails";

function BusinessDetails() {
  // const {
  //   OrganizationName,
  //   OrganizationNumber,
  //   OrganizationEmail,
  //   TotalListedApartment,
  //   TotalGeneratedRevenue,
  // } = useSelector((state) => state.businessDetails.userbusinessDetails);
  return (
    <PersonalDetailsWrap>
      <Row gutter={[15, 15]} justify="space-between">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 24 }}
        >
          <span>Organization Name</span>
          <h2>Prompt </h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Organization Contact</span>
          <h2>+1 985 9637 74 </h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Organization Email</span>
          <h2>help@prompt.com</h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Total Listed Apartments</span>
          <h2>008</h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Total Generated Revenue</span>
          <h2>$987</h2>
        </Col>
      </Row>
    </PersonalDetailsWrap>
  );
}

export default BusinessDetails;
