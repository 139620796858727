import styled from "styled-components";

export const ThanksPageWrap = styled.div`
  height: 100vh;
  background-color: #eeeeee;
  background-color: #ffffff;
  background-image: url("/images/thanksPgBg.png");
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(37, 54, 78);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.forth}80;
  background-position: fixed;

  @media (max-width: 425px) {
    box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}99;
  }
`;
export const ThanksWrap = styled.div`
  text-align: center;
  background-color: #ffffff;
  width: ${({ boxWidth }) => boxWidth || "40"}%;
  padding: 50px;
  margin: ${({ margin }) => margin || "auto"};
  position: unset;
  top: 0;
  left: 0;
  transform: none;

  .avenew__signup {
    padding-top: 45px;
    color: ${({ theme }) => theme.colors.third};
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }
  
  .personal__profile {
    color: ${({ theme }) => theme.colors.forth};
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
  }

  .welcome__avenew {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #777777;
    margin-bottom: 55px;
  }

  svg {
    width: 52px;
    height: 52px;
  }

  @media (max-width: 1550px) {
    padding: 40px;

    .avenew__signup {
      padding-top: 35px;
      font-size: 24px;
      line-height: 26px;
    }

    .personal__profile {
      font-size: 35px;
      line-height: 40px;
    }

    .welcome__avenew {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 44px;
    }
  }

  @media (max-width: 425px) {
    width: 100%;
    transform: none;
    top: 0;
    left: 0;
    /* this is for background image */
    background: transparent;
  }
  @media (max-width: 625px) {
    .avenew__signup {
      font-size: 27px;
    }
    .personal__profile {
      font-size: 35px;
    }
  }
  @media (max-width: 480px) {
    .avenew__signup {
      font-size: 25px;
    }
    .personal__profile {
      font-size: 30px;
    }
  }
  @media (max-width: 411px) {
    .avenew__signup {
      font-size: 20px;
    }
    .personal__profile {
      font-size: 25px;
    }
  }
  @media (max-width: 341px) {
    .avenew__signup {
      font-size: 17px;
    }
    .personal__profile {
      font-size: 22px;
    }
  }
  @media (max-width: 1199px) {
    width: ${({ smwh }) => smwh || "50"}%;
  }
  @media (max-width: 768px) {
    width: ${({ xswh }) => xswh || "50"}%;
    padding: 25px;
  }
  @media (max-width: 576px) {
    width: ${({ xxswh }) => xxswh || "50"}%;
  }
`;
