import { Button, Menu } from "antd";
import styled from "styled-components";
import { Card } from "../../ui/Card";

export const StyledApparCardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  ${"" /* margin: 30px 0; */}
  margin-left: ${({ ml }) => ml || 15}px;
  width: ${({ wd }) => wd || 98}%;

  @media (max-width: 991.98px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const StyledApparCard = styled(Card)`
  display: flex;
  flex-flow: column;
  padding: 7px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  .ant-skeleton-image,
  img {
    height: 225px;
  }

  .ant-skeleton-element .ant-skeleton-image-svg {
    width: 100px;
    height: 100px;
  }

  h4 {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    margin-bottom: 0px;
    p {
      margin-bottom: 0;
    }
  }

  .text a {
    display: table;
    color: #0061a4;
    margin-top: 33px;
  }

  .card__title {
    padding-top: 15px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #323548;
    font-size: 20px;
    font-weight: 700;
    p {
      margin: 0;
    }
  }

  .card__BHK {
    font-size: 16px;
    line-height: 19px;
    color: #777777;
    .ant-divider-vertical {
      border-left: 1px solid #777777;
      height: 1rem;
    }
  }

  .card__price {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-divider-vertical {
      border-left: 1px solid #777777;
      height: 1rem;
    }
    p {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      color: ${({ theme }) => theme.colors.secondary};
    }
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #777777;
      svg {
        padding-top: 3px;
      }
    }
  }

  :hover {
    border: 3px solid ${({ theme }) => theme.colors.third};
    padding: 4px;
  }

  @media (max-width: 1550px) {
    .card__title {
      padding-left: 12px !important;
      padding-right: 12px !important;
      padding-top: 12px;
      padding-bottom: 8px;
      font-size: 16px;
    }
    .card__BHK {
      padding-left: 12px !important;
      padding-right: 12px !important;
      font-size: 12px;
      line-height: 15px;
    }
    .card__price {
      padding-left: 12px !important;
      padding-right: 12px !important;
      padding-top: 8px;
      p {
        font-size: 16px;
      }
      span {
        font-size: 12px;
        line-height: 13px;
      }
    }
  }

  @media (max-width: 380px) {
    .card__price {
      flex-direction: column;
      align-items: start;
    }
  }
`;

export const StyledApparContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 15px 0 0;

  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #232333;
    margin-bottom: 0;
    /* min-height: 40px; */
  }
  h4 {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    margin-bottom: 0px;
    padding-top: 30px;
    p {
      margin-bottom: 0;
    }
  }
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    min-height: 20px;
    display: flex;
    align-items: center;
  }
  .comment_at {
    font-size: 12px;
    line-height: 22px;
    color: #747487;
  }
  span .user_name {
    margin-left: 15px;
  }
  .text a {
    display: table;
    color: #0061a4;
    margin-top: 33px;
  }
`;

export const StyledApartCardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
`;

export const ShareDropdown = styled(Menu)`
  padding: 5px;
`;

export const MenuItem = styled(Menu.Item)`
  &:hover {
    svg {
      fill: #777777;
    }
  }
`;

export const ShareDropdownMenuItem = styled(MenuItem)`
  .ant-dropdown-menu-item-icon {
    color: #949494;
    font-size: 16px;
    margin-right: 12px;
  }
  .ant-dropdown-menu-title-content > a {
    color: #949494;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }
  .ant-dropdown-menu-title-content > p {
    color: #949494;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
  }

  /* :first-child {
    display: none;
  } */

  /* :first-child span {
    color: #949494;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  } */

  /* @media (max-width: 991.98px) {
    :first-child {
      display: block;
    }
  } */
`;

export const AddNewApr = styled(Button)`
  ${"" /* width: ${({ wh }) => wh || "100%"}; */}
  padding: 0 15px;
  height: ${({ hg }) => hg || "40px"};
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: ${({ theme }) => theme.colors.third};
  color: #ffffff;
  text-transform: uppercase;

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }

  span {
    font-weight: ${({ fw }) => fw || "400"};
    font-size: ${({ fs }) => fs || "18px"};
  }

  @media (max-width: 1550px) {
    span {
      font-size: 14px;
    }
  }
  @media (max-width: 991.98px) {
    display: ${({ displayView }) => displayView || "block"};
  }
`;
