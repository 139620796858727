import styled from "styled-components";
import { Badge } from "antd";

export const BadgeCount = styled(Badge)`
  .ant-badge-count {
    top: 0px;
    right: 1px;
    box-shadow: none;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    font-size: 12px;
    background: ${({ theme }) => theme.colors.third};
  }
`;
