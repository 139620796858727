import React from "react";

const arrowSvg = ({
  color = "#666666",
  width = "20",
  height = "20",
  fill = "#ffffff",
  stroke = "#4160AC",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="6.5" r="6.5" fill={fill} />
      <path d="M4 6.2L6.04545 8L9 5" stroke={stroke} />
    </svg>
  );
};

export default arrowSvg;
