import styled from "styled-components";

export const Container = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "50px 50px 50px 330px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  .ant__spin {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1550px) {
    padding: ${({ paddingSm }) => paddingSm || "40px 40px 40px 265px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
`;
