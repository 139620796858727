import styled from "styled-components";
import { Avatar, Button } from "antd";

export const UnitDetails = styled.div`
  padding: 12px 30px;
`;

export const ApplicationUsers = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid ${({ borderColor }) => borderColor || "#dddddd"};
  border-radius: 5px;
  cursor: pointer;
`;
export const RightSideWrap = styled.div`
  overflow: auto;
  height: 670px;
  white-space: nowrap;

  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }

  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .ant-modal-wrap::-webkit-scrollbar-thumb {
    display: none;
  }

  @media (max-width: 1550px) {
    height: 535px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background: #ededed;
  ${"" /* display: flex; */}
  ${"" /* align-items: center; */}
  ${"" /* justify-content:0 center; */}

  ${
    "" /* .ant-avatar-string {
    z-index: 1;
    color: #eeeeee;
    font-size: calc(${({ size }) => size || 32}px / 2.3);
  } */
  }

  ${
    "" /* &:after {
    position: absolute;
    content: "";
    top: ${({ border }) => border || 1}px;
    left: ${({ border }) => border || 1}px;
    width: calc(100% - ${({ border }) => border * 2 || 2}px);
    height: calc(100% - ${({ border }) => border * 2 || 2}px);
    background-color: #ccc;
    border-radius: 50%;
    z-index: 0;
  } */
  }
  /* // iOS fix */

  ${
    "" /* > img {
    border-radius: 50%;
    padding: ${({ border }) => border || 1}px; // iOS Fix
    z-index: 1; // iOS Fix
  } */
  }
`;

export const ApplicationStatus = styled.div`
  background: ${({ bgColor }) => bgColor || "#eeeeee"};
  border-radius: 5px;
  padding: 4px 10px;
  margin-left: 20px;
  height: fit-content;

  span {
    font-weight: 400;
    font-size: 16px;
    color: ${({ ftColor }) => ftColor || "#eeeeee"};
  }
  @media (max-width: 1550px) {
    margin-left: 15px;
    span {
      font-size: 12px;
    }
  }
`;
export const ApplicantName = styled.div`
  margin-left: 57px;
  margin-top: 5px;

  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #777777;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 5px;
  }
  span {
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #56c274;
  }

  @media (max-width: 1550px) {
    margin-left: 53px;
    margin-top: 5px;

    h2 {
      font-size: 14px;
      line-height: 17px;
    }
    span {
      font-size: 12px;
      line-height: 11px;
    }
  }
`;
export const NameStatusWrap = styled.div`
  display: flex;
  padding: 13px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(131, 131, 131, 0.1);
  border-radius: 5px 5px 0px 0px;
  .avatar__wrap {
    align-items: center;
    display: flex;
    span {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #2b353f;
    }
    .avatarName__wrap {
      margin-left: 20px;
    }
    .ant-avatar-image {
      width: 57px;
      height: 57px;
      background: #ededed;
    }
  }

  @media (max-width: 1550px) {
    padding: 10px;

    .avatar__wrap {
      span {
        font-size: 19px;
        line-height: 23px;
      }
      .avatarName__wrap {
        margin-left: 16px;
      }
      .ant-avatar-image {
        width: 45px;
        height: 45px;
      }
    }
  }

  @media (max-width: 426px) {
    ${
      "" /* .user__icon {
      width: 50px !important;
      height: 40px !important;
    } */
    }
  }
`;

export const StatusWrap = styled.div`
  background: ${({ bgColor }) => bgColor || "#ffeade"};
  border-radius: 5px;
  padding: 4px 10px;
  height: fit-content;
  span {
    font-weight: 400;
    font-size: 16px;
    color: ${({ ftColor }) => ftColor || "#f67c36"};
  }
  @media (max-width: 1550px) {
    span {
      font-size: 12px;
    }
  }
  @media (max-width: 420px) {
    display: none;
  }
`;

export const ApplicationProccess = styled.div`
  padding: 13px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;

  .application__width {
    min-width: 230px;
  }
  .approval__width {
    min-width: 175px;
  }
  .lease__width {
    min-width: 280px;
  }
  .signed__width {
    min-width: 190px;
  }

  .ant-divider-horizontal {
    width: 41px;
    min-width: 10px;
    border: 1px solid #dddddd;
    height: 0px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .ant-row {
    align-items: center;
    flex-flow: nowrap;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #dee8ff;
    border-color: #dee8ff;
  }

  .anticon {
    color: #4160ac;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #4160ac;
    font-weight: 400;
    font-size: 16px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #dee8ff;
    border-color: #dee8ff;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #4160ac;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #eee;
    border-color: #eee;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #2b353f;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #2b353f;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #ddd;
  }

  .ant-steps-vertical {
    display: flex;
    flex-direction: row;
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after,
  .ant-steps-item-tail {
    display: none !important;
  }

  .anticon svg {
    width: 13px;
  }

  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0;
    display: none;
    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  @media (max-width: 1550px) {
    padding: 10px;

    .application__width {
      min-width: 184px;
    }
    .approval__width {
      min-width: 140px;
    }
    .lease__width {
      min-width: 224px;
    }
    .signed__width {
      min-width: 152px;
    }

    .ant-divider-horizontal {
      width: 32px;
      min-width: 8px;
    }

    span {
      font-size: 12px;
      line-height: 15px;
    }

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      font-size: 12px;
    }

    .anticon svg {
      width: 10px;
    }
  }

  @media (max-width: 991.98px) {
    .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
      min-height: auto;
    }
  }
`;

// export const ButtonWrap = styled.div`
//   padding: 150px 15px 50px 15px;
//   display: flex;
//   width: 80%;
//   justify-content: space-between;
// `;

export const ButtonWrap = styled.div`
  padding: ${({ padding }) => padding || "50px 15px 50px 15px"};

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "40px 10px 40px 10px"};
  }
  @media (max-width: 1426px) {
    .status__button {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    .status__button {
      width: 100%;
    }
  }
`;
export const StatusButton = styled(Button)`
  width: 100%;
  height: 50px !important;
  border: 1px solid #ededed !important;
  color: white;
  font-size: 18px;
  background: ${({ bgColor }) => bgColor || "#ededed"};
  border-radius: 5px;
  text-transform: uppercase;

  .anticon {
    transform: rotate(180deg) !important;
  }
  :hover,
  :active,
  :focus {
    background: ${({ bgColor }) => bgColor || "#aaaaaa"}90;
    color: #ffffff;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: #ffffff;
  }

  @media (max-width: 1550px) {
    height: 40px !important;
    font-size: 14px;

    span {
      font-size: 14px;
      line-height: 15px;
    }
  }
`;

export const ApplicationWrap = styled.div`
  width: 100%;
  display: flex;
`;
export const ApplicantLeft = styled.div`
  width: 27%;
  background: #f9f9f9;
  padding: 30px 20px;
  min-height: calc(100vh - 205px);

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.colors.secondary};
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.colors.forth};
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: 60px;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colors.forth};
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: ${({ theme }) => theme.colors.forth};
  }
  .ant-steps-item-title {
    font-weight: 400;
    font-size: 16px;
    padding: 0;
  }
`;
export const ApplicantRight = styled.div`
  width: 73%;
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
`;
export const StepHeading = styled.div`
  padding: 30px;
  h2 {
    margin: 0;
    color: ${({ theme }) => theme.colors.forth};
    font-size: 24px;
    line-height: 29px;
  }
`;

export const CurrentStagWrap = styled.div`
  padding: 15px 15px 30px;
  h2 {
    margin: 0;
    color: #323548;
    font-size: 24px;
  }
  @media (max-width: 1550px) {
    padding: 10px 10px 20px;
    h2 {
      font-size: 19px;
    }
  } ;
`;

export const ApplicationWrapMain = styled.div``;

export const StepWrap = styled.div`
  padding: 0 15px;
  display: flex;

  ${
    "" /* .total__steps {
    padding-left: 15px;
  }

  p {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.forth};
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: block;
    height: 4em;
    margin: 0 12px;
    vertical-align: middle;
    border-top: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  } */
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #dee8ff;
    border-color: #dee8ff;
  }

  .anticon {
    color: #4160ac;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-vertical.ant-steps-small
    .ant-steps-item-container
    .ant-steps-item-title {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.forth};
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
    padding-bottom: 35px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #dee8ff;
    border-color: #dee8ff;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #4160ac;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #eee;
    border-color: #eee;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #2b353f;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #2b353f;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #ddd;
  }

  .anticon svg {
    width: 13px;
  }

  @media (max-width: 1550px) {
    padding: 0 12px;

    .ant-divider-vertical {
      height: 3.2em;
      margin: 0 10px;
    }

    svg {
      width: 22px;
      height: 22px;
    }
    p {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 19px;
    }
    span {
      font-size: 12px;
      line-height: 15px;
      color: #aaaaaa;
    }
    .total__steps {
      padding-left: 12px;
    }
  }
`;
