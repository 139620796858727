import React, { useEffect, useState } from "react";
import { Container } from "../../ui/Layout/Container";
import { Row, Col, Skeleton, Spin, Form, Switch, Table, Checkbox } from "antd";
import { Heading } from "../../ui/Heading/Heading";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { StyledButton } from "../createPersonalProfile/RegistrationStyle";
import AddApartmentModal from "../addApartmentModal/AddApartmentModal";
import { Card } from "../../ui/Card";
import {
  AddNewApr,
  StyledApparCard,
  StyledApparCardWrapper,
  StyledApparContentWrapper,
} from "./Listingstyle";
import ApartmentSection from "./ApartmentSection";
import { apartmentDummyData } from "./dummy.data";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  ApartmentTable,
  FloatButton,
  SecondaryButton,
} from "../apartments-view/ApartmentsViewStyle";
import { HiPlus } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllApartment,
  getAllOrg,
  getApartment,
  getOrg,
  getUnitsByUserId,
} from "../../store/reducers/user.reducer";
import NoListing from "../no-listing page/NoListing";
import { httpAPI } from "../../api";
import Actions, { EditIconWrap } from "../apartments-view/Actions";
import { EditSvg } from "../../assets/svgs";
import UpdateUnitModal from "../addApartmentModal/UpdateUnitModal";
import UpdateApartmentModal from "../addApartmentModal/UpdateApartmentModal";

function AllPropperty({ name, displayShow = true }) {
  const navigate = useNavigate();

  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const { loading } = useSelector((state) => state.user);
  const [getAllApartmentState, setGetAllApartment] = useState({});
  const [getAllUnit, setGetAllUnit] = useState({});
  const [getAllUnitArr, setGetAllUnitArr] = useState([]);
  const [allApartment, setAllApartment] = useState([]);
  const [allPropUnit, setAllPropUnit] = useState([]);
  const [sortAllPropUnit, setSortAllPropUnit] = useState([]);
  const [selectedUnitFromTable, setSelectedUnitFromTable] = useState([]);
  const [padding, setPadding] = useState("50px 50px 0 330px");
  const [showHeader, setShowHeader] = useState(true);
  const [onlyUnit, setOnlyUnit] = useState(false);
  const [valueChanger, setValueChanger] = useState("false");
  const [allApartmentForModal, setAllApartmentForModal] = useState([]);
  const [modalStateUpdate, setModalStateUpdate] = useState(false);
  const [getAllApartmentStateArr, setGetAllApartmentStateArr] = useState({});
  const [apartments, setApartments] = useState([]);

  const valueOfSelect = allApartmentForModal[0];

  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (width < 1550 && width > 991) {
      setPadding("40px 40px 0 265px");
    } else {
      setPadding("50px 50px 0 330px");
    }
  }, [width, location.pathname]);

  const newListedApartment = (value) => {
    setApartments((val) => [...val, value]);
    setShowHeader(true);
    setGetAllApartmentStateArr((val) => [...val, value]);
  };

  useEffect(() => {
    dispatch(getAllApartment()).then(({ payload }) => {
      setGetAllApartment(payload);
    });
  }, []);
  useEffect(() => {
    dispatch(getUnitsByUserId()).then(({ payload }) => {
      setGetAllUnit(payload);
    });
  }, []);

  useEffect(() => {
    if (getAllApartmentState && Object.keys(getAllApartmentState).length) {
      const arr = Object.values(getAllApartmentState);
      setAllApartment(arr);
      setGetAllApartmentStateArr(arr);
    } else {
      setAllApartment([]);
      setGetAllApartmentStateArr([]);
    }
  }, [getAllApartmentState]);

  const handleHeaderChange = (enable) => {
    setShowHeader(enable);
  };

  useEffect(() => {
    if (getAllUnit && Object.keys(getAllUnit).length) {
      const arr = Object.values(getAllUnit);
      setGetAllUnitArr(arr);
    }
  }, [getAllUnit]);

  const [getAllOrgObj, setGetAllOrgObj] = useState({});
  const [getAllOrgArr, setGetAllOrgArr] = useState([]);

  useEffect(() => {
    dispatch(getAllOrg()).then(({ payload }) => {
      setGetAllOrgObj(payload);
    });
  }, []);

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  useEffect(() => {
    if (getAllOrgObj && Object.keys(getAllOrgObj).length) {
      const arr = Object.values(getAllOrgObj);
      setGetAllOrgArr(arr);
      setValueChanger(arr);
    } else {
      setGetAllOrgArr([]);
      setValueChanger([]);
    }
  }, [getAllOrgObj]);

  const getChildOrgNameApart = (ele) => {
    const val = getAllOrgArr?.filter(function (item) {
      return item["id"] === ele[0]?.org_id;
    });
    return val;
  };

  const getChildOrgName = (ele) => {
    const abc = allApartment?.filter(({ id }) => {
      return id === ele.apartment_id;
    });
    return getChildOrgNameApart(abc);
  };

  const unitApartmentName = (value) => {
    if (value && value?.includes("unit")) {
      return value;
    } else {
      return value + " " + "unit";
    }
  };

  useEffect(() => {
    if (getAllUnit) {
      var arr = [];

      for (var item in getAllUnit) {
        if (getAllUnit.hasOwnProperty(item)) {
          arr.push(getAllUnit[item]);
        }
      }

      var newArr = [];

      if (arr.length) {
        const newChild = arr?.map((ele, idx) => ({
          ...ele,
          unitType: ele.unitType + " " + "unit",
        }));
      }

      if (allApartment.length) {
        const newChild = arr?.map((ele, idx) => ({
          ...ele,
          unitType: unitApartmentName(ele.unitType),
          orgName: getChildOrgName(ele),
        }));

        const launchOptimistic = allApartment?.map((elem, idx) => ({
          ...elem,
          key: idx + 1,
          orgName: getAllOrgArr?.filter(function (item) {
            return item["id"] === elem.org_id;
          }),
          children: newChild.filter(function (item) {
            return item["apartment_id"] === elem.id;
          }),
        }));
        if (onlyUnit) {
          setAllPropUnit(getAllUnitArr);
        } else {
          setAllPropUnit(launchOptimistic);
        }
      }

      // var orgValue = arr.filter(function (item) {
      //   return item["apartment_id"] === "867caf48-43d7-4176-b831-13f5e9994883";
      // });
      // setValueOfApartment(orgValue);
    } else {
    }
  }, [allApartment, getAllUnit, onlyUnit, valueChanger]);

  useEffect(() => {
    if (allPropUnit?.length) {
      const arrOfSort = [];
      allPropUnit?.map((value) => {
        let sortForeach = value.children.map((val) => {
          return val.unit_name;
        });
        sortForeach.sort();

        sortForeach?.map((val) => {
          value.children.filter((obj) => {
            if (obj["unit_name"] === val) {
              arrOfSort.push(obj);
            }
          });
        });
      });

      const launchOptimistic = allApartment?.map((elem, idx) => ({
        ...elem,
        key: idx + 1,
        orgName: getAllOrgArr?.filter(function (item) {
          return item["id"] === elem.org_id;
        }),
        children: arrOfSort.filter(function (item) {
          return item["apartment_id"] === elem.id;
        }),
      }));
      setSortAllPropUnit(launchOptimistic);
    }
  }, [allPropUnit]);

  const editValue = (value) => {
    setAllApartmentForModal([value]);
    setModalStateUpdate(true);
  };

  useEffect(() => {
    if (selectedUnitFromTable && Object.values(selectedUnitFromTable).length) {
      if (selectedUnitFromTable?.children) {
        navigate(`/subunit/${selectedUnitFromTable?.id}`);
      } else {
        navigate({
          pathname: `/subunit/${selectedUnitFromTable?.apartment_id}`,
          search: createSearchParams({
            unitId: selectedUnitFromTable?.id,
          }).toString(),
        });
      }
    }
  }, [selectedUnitFromTable]);

  const columns = [
    {
      title: "Unit Type",
      dataIndex: "unitType",
      key: "unitType",
      width: "15%",
    },
    {
      title: "Org Name",
      dataIndex: "unit_name",
      key: "unit_name",
      render: (_, record) => {
        return (
          <>
            {record.orgName ? (
              <span key={record.orgName[0]?.id}>
                {upperCase(record.orgName[0]?.org_name)}
              </span>
            ) : (
              " "
            )}
          </>
        );
      },
    },
    {
      title: "Units Name",
      dataIndex: "unit_name",
      key: "unit_name",
      width: "15%",
    },
    {
      title: "Sub Unit",
      dataIndex: "numberOfUnit",
      key: "numberOfUnit",
    },
    {
      title: "Sq.ft",
      dataIndex: "sqft",
      key: "sqft",
    },
    {
      title: "Bed",
      dataIndex: "bed",
      key: "bed",
    },
    {
      title: "Bath",
      dataIndex: "bath",
      key: "bath",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Status",
      dataIndex: "unitStatus",
      key: "unitStatus",
      render: (_, record) => {
        let color = "red";
        if (record.unitStatus === "Sold") {
          color = "#E04141";
        } else if (record.unitStatus === "Contract Sent") {
          color = "#4160AC";
        } else if (record.unitStatus === "Available") {
          color = "#58D255";
        } else if (record.unitStatus === "Pending") {
          color = "#F67C36";
        } else if (record.unitStatus === "Configure") {
          color = "#F67C36";
        }
        return (
          <span
            style={{ color: color }}
            key={record.unitStatus}
            onClick={(event) => {
              editValue(record);
              event.stopPropagation();
            }}
          >
            {record.unitStatus}
          </span>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      align: "right",
      width: "10%",
      render: (_, record) => {
        return (
          <EditIconWrap
            onClick={(event) => {
              editValue(record);
              event.stopPropagation();
            }}
          >
            <EditSvg />
          </EditIconWrap>
        );
      },
    },
  ];
  const [yScroll, setyScroll] = useState(650);

  const onChange = (e) => {
    setOnlyUnit(e.target.checked);
  };
  const [loadings, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    size: "small",
  });

  const fetchData = (params = {}) => {
    setLoading(true);
    setPagination({
      ...params.pagination,
      // total: 20, // 200 is mock data, you should read it from server
      total: allPropUnit.length,
    });
    setTimeout(() => {
      setLoading(false);
    }, 500);

    //   });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, [allPropUnit]);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  const getUpdatedUnit = (value) => {
    setLoading(true);
    dispatch(getAllApartment()).then(({ payload }) => {
      setGetAllApartment(payload);
    });
    dispatch(getUnitsByUserId()).then(({ payload }) => {
      setGetAllUnit(payload);
      setLoading(false);
    });
    dispatch(getAllOrg()).then(({ payload }) => {
      setGetAllOrgObj(payload);
    });
    if (value && Object.keys(value).length) {
      if (apartments.length) {
        const objIndex = getAllUnitArr.findIndex((obj) => obj.id === value.id);
        getAllUnitArr[objIndex] = value;
        setValueChanger(value);
        setGetAllApartmentStateArr((val) => [...val, value]);
      }
    }
  };

  const getUpdatedApartment = (value) => {
    if (value && Object.keys(value).length) {
      if (apartments.length) {
        const objIndex = apartments.findIndex((obj) => obj.id === value.id);
        apartments[objIndex] = value;
        setValueChanger(value);
      }
    }
  };

  return (
    <>
      <>
        <Container padding={padding} paddingSm={padding}>
          <>
            <Row justify="space-between">
              <Col>
                {/* <Heading>{upperCase(heading)}</Heading> */}
                <Heading>All Properties</Heading>
              </Col>
              <Col style={{ display: "flex" }}>
                {showHeader ? (
                  <Form.Item style={{ marginRight: "20px" }}>
                    {/* <Checkbox onChange={onChange}>Show only Sub units</Checkbox> */}
                  </Form.Item>
                ) : (
                  ""
                )}
                <Form.Item label="Table View" style={{ marginRight: "20px" }}>
                  <Switch checked={showHeader} onChange={handleHeaderChange} />
                </Form.Item>
                {/* <AddNewApr
                  bgColor={"#777777"}
                  fw={"400"}
                  displayView="none"
                  onClick={() => setModalState(true)}
                >
                  Add New Property
                </AddNewApr> */}
              </Col>
            </Row>
            {!showHeader ? (
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <StyledApparCardWrapper wd={"100"} ml={"0"}>
                    <Row gutter={[30, 30]}>
                      {loading &&
                        [...Array(6).keys()].map((v) => (
                          <Col
                            key={v}
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                          >
                            <StyledApparCard>
                              <Skeleton.Image
                                active={loading}
                                style={{ width: "100%" }}
                              ></Skeleton.Image>
                              {/* <StyledVideoProgressWrapper></StyledVideoProgressWrapper> */}
                              <StyledApparContentWrapper mh={"0"}>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: "20px",
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: 20,
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: 20,
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                              </StyledApparContentWrapper>
                            </StyledApparCard>
                          </Col>
                        ))}
                    </Row>
                  </StyledApparCardWrapper>
                  <ApartmentSection
                    apartmentDummyData={apartmentDummyData}
                    apartmentsWithUnits={allApartment}
                  />
                </Col>
              </Row>
            ) : (
              <ApartmentTable>
                <Table
                  scroll={{ x: 1200, y: yScroll }}
                  columns={columns}
                  dataSource={[...sortAllPropUnit]}
                  pagination={pagination}
                  loading={loadings}
                  onChange={handleTableChange}
                  onRow={(r) => ({
                    onClick: (event) => {
                      // navigate({
                      //   pathname: `/subunit/${r.id}`,
                      //   search: createSearchParams({
                      //     unitId: "hello",
                      //   }).toString(),
                      // });
                      event.isDefaultPrevented();
                      setSelectedUnitFromTable(r);
                    },
                  })}
                />
              </ApartmentTable>
            )}
          </>
          {/* <FloatButton>
            <SecondaryButton
              shape="circle"
              icon={<HiPlus className="plus_icon" />}
              onClick={() => setModalState(true)}
            />
          </FloatButton> */}

          {!allApartmentForModal[0]?.children ? (
            <UpdateUnitModal
              onCancel={(value) => setModalStateUpdate(value)}
              modalState={modalStateUpdate}
              isEdit={true}
              updateUnit={valueOfSelect}
              // getUpdatedUnit={getUpdatedUnit}
              getAllApartmentState={getAllApartmentStateArr}
              getUpdatedUnit={getUpdatedUnit}
            />
          ) : (
            <UpdateApartmentModal
              onCancel={(value) => setModalStateUpdate(value)}
              modalState={modalStateUpdate}
              isEdit={true}
              updateUnit={allApartmentForModal}
              getUpdatedApartment={getUpdatedApartment}
              getUpdatedUnitApartment={getUpdatedUnit}
            />
          )}
        </Container>

        {/* {loading ? (
          <Container padding="0 50px 0px 330px">
            <div
              style={{
                position: "relative",
                minHeight: "calc(100vh - 80px)",
              }}
            >
              <Spin className="ant__spin" size="large" />
            </div>
          </Container>
        ) : allApartment && allApartment?.length ? (
          <Container padding={padding} paddingSm={padding} minH="100vh">
            <>
              <Row justify="space-between">
                <Col>
                  <Heading>All Property</Heading>
                </Col>
                <Col>
                  <AddNewApr
                    bgColor={"#777777"}
                    fw={"400"}
                    displayView="none"
                    onClick={() => setModalState(true)}
                  >
                    Add New Property
                  </AddNewApr>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <StyledApparCardWrapper wd={"100"} ml={"0"}>
                    <Row gutter={[30, 30]}>
                      {loading &&
                        [...Array(6).keys()].map((v) => (
                          <Col
                            key={v}
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                          >
                            <StyledApparCard>
                              <Skeleton.Image
                                active={loading}
                                style={{ width: "100%" }}
                              ></Skeleton.Image>
                              <StyledApparContentWrapper mh={"0"}>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: "20px",
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: 20,
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: 20,
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                              </StyledApparContentWrapper>
                            </StyledApparCard>
                          </Col>
                        ))}
                    </Row>
                  </StyledApparCardWrapper>
                  <ApartmentSection
                    apartmentDummyData={apartmentDummyData}
                    apartments={allApartment}
                  />
                </Col>
              </Row>
            </>
            <FloatButton>
              <SecondaryButton
                shape="circle"
                icon={<HiPlus className="plus_icon" />}
                onClick={() => setModalState(true)}
              />
            </FloatButton>
          </Container>
        ) : (
          <>""</>
        )} */}
      </>
      <AddApartmentModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        newListedApartment={newListedApartment}
      />
    </>
  );
}

export default AllPropperty;
