import React, { useEffect } from "react";
import { CreateFirstListing, StyledButton } from "./RegistrationStyle";
import {
  createSearchParams,
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ApartmentLogo } from "../../assets/svgs";

function RegisterFormCreateListing({ pageTitle, bgImage }) {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");

  useEffect(() => {
    bgImage("/images/createorganization.png");
    pageTitle("Create Your Organization");
  }, []);
 
  return (
    <CreateFirstListing smwh="75" xswh="80" xxswh="91">
      {/* <img className="apartment__img" src="/apartment.svg" /> */}
      <ApartmentLogo bgColor="red" />
      <h2>Want to add property now? </h2>
      <StyledButton
        onClick={() =>
          navigate({
            pathname: `/registration/company-details/create-listing/create-new-apartment`,
            search: createSearchParams({
              orgId: orgId,
            }).toString(),
          })
        }
      >
        Create A New Property
      </StyledButton>
      <Link to="/join-with-us">
        <p>SKIP</p>
      </Link>
    </CreateFirstListing>
  );
}

export default RegisterFormCreateListing;
