import React from "react";

const SendInvitationSvg = ({
  bgColor = "#ffffff",
  color = "none",
  width = "73",
  height = "54",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1054_12394)">
        <path
          d="M33.8182 52H2V2H70.1818V24.7273"
          stroke="#58D255"
          stroke-width="4"
          stroke-linejoin="round"
        />
        <path
          d="M2 2L36.0909 27L70.1818 2"
          stroke="#58D255"
          stroke-width="4"
          stroke-linejoin="round"
        />
        <path
          d="M56.5469 31.5469L65.6378 40.6378L56.5469 49.7287"
          stroke="#58D255"
          stroke-width="4"
          stroke-linejoin="round"
        />
        <path
          d="M65.6335 40.6328H42.9062"
          stroke="#58D255"
          stroke-width="4"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendInvitationSvg;
