import React from "react";

const ShareIcon = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.8" cx="17.5" cy="17.5" r="17.5" fill="white" />
      <path
        d="M21.6028 19.1239C20.919 19.1239 20.3039 19.4165 19.8673 19.8835L14.7648 17.3428C14.7823 17.2234 14.7944 17.1025 14.7944 16.9782C14.7944 16.8571 14.7829 16.7389 14.7662 16.6227L19.8612 14.1098C20.2982 14.5806 20.9161 14.8761 21.6028 14.8761C22.9267 14.8761 24 13.7845 24 12.438C24 11.0913 22.9267 10 21.6028 10C20.2789 10 19.2056 11.0913 19.2056 12.438C19.2056 12.5592 19.2171 12.6771 19.2338 12.7935L14.1388 15.3064C13.7018 14.8357 13.0842 14.5402 12.3972 14.5402C11.073 14.5402 10 15.6318 10 16.9782C10 18.3249 11.073 19.4162 12.3972 19.4162C13.081 19.4162 13.6961 19.1237 14.1327 18.6567L19.2352 21.1974C19.2177 21.3165 19.2056 21.4376 19.2056 21.562C19.2056 22.9087 20.2789 24 21.6028 24C22.9267 24 24 22.9087 24 21.562C24 20.2153 22.9267 19.1239 21.6028 19.1239Z"
        fill="#2B353F"
      />
    </svg>
  );
};

export default ShareIcon;
