import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Popover,
  Radio,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { EditIcon, LessthanIcon } from "../../assets/svgs";
import { Heading } from "../../ui/Heading/Heading";
import { Container } from "../../ui/Layout/Container";
import { RiDownloadLine } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledFormSelect,
} from "../../components/createPersonalProfile/RegistrationStyle";
import { IoIosArrowDown } from "react-icons/io";
import TenantsInvoicesModal, { TotalAmount } from "./TenantsInvoicesModal";
import { useDispatch, useSelector } from "react-redux";
import {
  invoicesByTenant,
  sendInvoiceSms,
  updateInvoice,
} from "../../store/reducers/user.reducer";
import { toUSACurrency, upperCase } from "../tenantLedger/TenantLedger";

function TenantsInvoicesDetails() {
  const params = useParams();
  const { invoices } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);
  const [isFormChange, setIsFormChange] = useState(false);
  const [getAllIncoice, setGetAllIncoice] = useState({});
  const [invoiceDetailsFee, setInvoiceDetailsFee] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [tableData, setTableData] = useState({});
  const [getAllIncoiceArr, setGetAllIncoiceArr] = useState([]);
  const [size, setSize] = useState("%");
  const [totalAmount, setTotalAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCloseLoading, setIsCloseLoading] = useState(false);
  const [isInvoiceClosed, setIsInvoiceClosed] = useState();

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      dispatch(invoicesByTenant()).then(({ payload }) => {
        setLoading(false);
        setGetAllIncoice(payload);
      });
    }
  }, [params.id]);

  console.log(getAllIncoiceArr, "getAllIncoiceArr");

  useEffect(() => {
    if (invoiceDetails) {
      const arrOfSort = [];
      const payments = invoiceDetails?.payments;
      let invoiceDetailsObj = { ...invoiceDetails };
      setIsInvoiceClosed(invoiceDetails?.invoiceClose);
      if (payments?.length) {
        let sortForeach = payments?.map((value) => {
          return value.squareResponse.createdAt;
        });
        sortForeach.sort();
        sortForeach.reverse();

        sortForeach?.map((val) => {
          payments.filter((obj) => {
            if (obj.squareResponse["createdAt"] === val) {
              arrOfSort.push(obj);
            }
          });
        });
      }
      invoiceDetailsObj.payments = arrOfSort;

      setTableData(invoiceDetailsObj);
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (getAllIncoice && Object.values(getAllIncoice).length) {
      const arr = Object.values(getAllIncoice);
      setGetAllIncoiceArr(arr);
    }
  }, [getAllIncoice]);

  useEffect(() => {
    const objIndex = getAllIncoiceArr.filter(function (obj) {
      return obj["id"] === params.id;
    });
    setInvoiceDetails(...objIndex);
  }, [getAllIncoiceArr]);

  useEffect(() => {
    if (invoiceDetails?.fees?.length) {
      const arr = [];

      const newArr = invoiceDetails.fees;
      newArr.forEach((val) => {
        arr.push({
          type: val.type,
          amount: val.amount,
          dollerOrPercentage: val.dollerOrPercentage,
          memo: val.memo,
          isEdit: false,
        });
        setInvoiceDetailsFee(arr);
      });
    }
  }, [invoiceDetails]);

  const amountValueChanger = (id) => {
    let changedObj = invoiceDetailsFee[id];
    changedObj["isEdit"] = true;
    invoiceDetailsFee[id] = changedObj;
    setInvoiceDetailsFee((val) => [...val]);
  };

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    // return `${day}-${month}-${year}`;
    return `${month} ${day}, ${year}`;
  }

  const dateConverter = (dateValue) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     rent: 1000.0,
  //   });
  // }, []);

  const sourceTypeFun = (type) => {
    switch (type) {
      case "WALLET":
        return "Cash App";
      case "CARD":
        return "Card";
      case "BANK_ACCOUNT":
        return "ACH";

      default:
        return type;
    }
  };

  const columns = [
    {
      title: "Transaction Date",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(b.squareResponse.createdAt) - new Date(a.squareResponse.createdAt),
      render: (_, record) => <span>{dateConverter(record.squareResponse.createdAt)}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "sourceType",
      render: (_, record) => <span>{sourceTypeFun(record.squareResponse.sourceType)}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => b.amount - a.amount,
      render: (_, record) => (
        <span>{toUSACurrency(Number(record.amount))}</span>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (_, record) => <span>{toUSACurrency(record.balance)}</span>,
    },
  ];

  const onFinish = (value) => {
    value.isEdit = false;
    setInvoiceDetailsFee((val) => [...val, value]);
    setIsFormChange(true);
    form.resetFields();
  };

  const content = (
    <PopoverModal>
      <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
        <StyledForms>
          <Form
            layout="vertical"
            // requiredMark={"optional"}
            form={form}
            onFinish={onFinish}
            // validateMessages={validateMessages}
            // onValuesChange={() => {
            //   setDataChange(true);
            //   setShowPrompt(true);
            //   onFormChange(true);
            // }}
          >
            <StyledFormSelect wh="49" smwh="100">
              <Form.Item name="type" label="Type">
                <Select
                  placeholder="Type"
                  allowClear
                  suffixIcon={<IoIosArrowDown />}
                  // disabled={selectedApartment}
                >
                  <Option value="rate">Rent</Option>
                  <Option value="lateFee">Late Fee</Option>
                  <Option value="parkingFee">Parking Fee</Option>
                  <Option value="avenewFees">Avenew Fee</Option>
                  <Option value="otherFee">Other Fee</Option>
                </Select>
              </Form.Item>
              <Form.Item name="amount" label="Amount ">
                <Input placeholder="Amount" type="number" />
              </Form.Item>
            </StyledFormSelect>

            <StyledFormItem wh="100" smwh="100">
              <Form.Item
                name="memo"
                label="Memo"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Input placeholder="Type note..." />
              </Form.Item>
            </StyledFormItem>

            <Form.Item>
              <StyledButton
                wh="auto"
                hg="40px"
                // disabled={disableBtn}
                // loading={loadings}
                htmlType="submit"
                onClick={hide}
              >
                Add
              </StyledButton>
            </Form.Item>
          </Form>
        </StyledForms>
      </StyledForm>
    </PopoverModal>
  );

  const feeType = (status) => {
    switch (status) {
      case "rate":
        return "Rent";
      case "lateFee":
        return "Late Fee";
      case "parkingFee":
        return "Parking Fee";
      case "avenewFees":
        return "Avenew Fee";
      case "otherFee":
        return "Other Fee";
      case "taxFee":
        return "Tax";
      default:
        return "Other Fee";
    }
  };

  useEffect(() => {
    if (invoiceDetailsFee?.length) {
      let sum = 0;
      invoiceDetailsFee.map((val, id) => {
        if (val.type != "taxFee") {
          sum += Number(val["amount"]);
        }
      });
      setTotalAmount(sum);
    }
  }, [invoiceDetailsFee]);

  function percentageRemaning(num, per) {
    return (num / 100) * per + num;
  }

  function percentageRemaningTax(num, per) {
    return (num / 100) * per;
  }
  const [payableAmount, setpayableAmount] = useState("");
  const [totalTax, setTotalTax] = useState("");

  useEffect(() => {
    let sum = 0;
    if (invoiceDetailsFee?.length) {
      invoiceDetailsFee.map((val) => {
        if (val.type != "taxFee") {
          sum += Number(val["amount"]);
        } else {
          setSize(val.dollerOrPercentage);
          if (val.dollerOrPercentage === "$") {
            const finalAmount = totalAmount + Number(val.amount);
            setpayableAmount(finalAmount);
          } else {
            const finalAmount = percentageRemaning(
              totalAmount,
              Number(val.amount)
            );

            const totalTax = percentageRemaningTax(
              totalAmount,
              Number(val.amount)
            );
            setTotalTax(totalTax);
            setpayableAmount(finalAmount);
          }
        }
      });
    }
    setTotalAmount(sum);
  }, [totalAmount]);

  const onOptionChangeInput = (amount, type, id, dollerOrPercentage) => {
    setIsFormChange(true);
    let changedObj = invoiceDetailsFee[id];
    changedObj["amount"] = amount;
    invoiceDetailsFee[id] = changedObj;
    setInvoiceDetailsFee((val) => [...val]);
    afterChangeInvoice(invoiceDetailsFee);
  };

  const inputChange = (amount, type, id, dollerOrPercentage) => {
    setIsFormChange(true);

    let changedObj = invoiceDetailsFee[id];
    changedObj["amount"] = amount;
    invoiceDetailsFee[id] = changedObj;
    setInvoiceDetailsFee((val) => [...val]);
    afterChangeInvoice(invoiceDetailsFee);
  };

  const amountValueChangerBtn = (value, type, id) => {
    setIsFormChange(true);

    let changedObj = invoiceDetailsFee[id];
    changedObj["dollerOrPercentage"] = value;
    invoiceDetailsFee[id] = changedObj;
    setInvoiceDetailsFee((val) => [...val]);
    afterChangeInvoice(invoiceDetailsFee);
  };

  const afterChangeInvoice = (data) => {
    let sum = 0;
    if (data?.length) {
      data.map((val) => {
        if (val.type != "taxFee") {
          sum += Number(val["amount"]);
        } else {
          if (val.dollerOrPercentage === "$") {
            const finalAmount = totalAmount + Number(val.amount);
            setTotalTax(val.amount);
            setpayableAmount(finalAmount);
          } else {
            const finalAmount = percentageRemaning(
              totalAmount,
              Number(val.amount)
            );

            const totalTax = percentageRemaningTax(
              totalAmount,
              Number(val.amount)
            );
            setTotalTax(totalTax);
            setpayableAmount(finalAmount);
          }
        }
      });
    }
    setTotalAmount(sum);
  };

  const onClickUpdate = (value) => {
    const objCopy = { ...value };
    objCopy.fees = invoiceDetailsFee;
    objCopy.balance = payableAmount
      ? payableAmount
      : totalAmount - invoiceDetails?.payableAmount + invoiceDetails?.balance;

    objCopy.payableAmount = payableAmount ? payableAmount : totalAmount;
    setIsLoading(true);
    dispatch(updateInvoice(objCopy)).then((res) => {
      dispatch(invoicesByTenant()).then(({ payload }) => {
        message.success("Invoice Updated Successfully");
        setIsLoading(false);
        setIsFormChange(false);
        setGetAllIncoice(payload);
      });
    });
  };

  const invoiceClose = (value, item) => {
    setIsCloseLoading(true);
    const objCopy = { ...value };
    objCopy.invoiceClose = item;
    dispatch(updateInvoice(objCopy)).then((res) => {
      dispatch(invoicesByTenant()).then(({ payload }) => {
        message.success("Invoice Closed Successfully");
        setIsCloseLoading(false);
        setGetAllIncoice(payload);
      });
    });
  };

  useEffect(() => {
    if (isEdit) {
      window.addEventListener("click", function (event) {
        // if (invoiceDetails?.fees?.length) {
        //   const arr = [];
        //   const newArr = invoiceDetails.fees;
        //   newArr.forEach((val) => {
        //     arr.push({
        //       type: val.type,
        //       amount: val.amount,
        //       dollerOrPercentage: val.dollerOrPercentage,
        //       memo: val.memo,
        //       isEdit: false,
        //     });
        //     setInvoiceDetailsFee(arr);
        //     setIsEdit(!isEdit);
        //   });
        // }
      });
    }
  }, [isEdit, invoiceDetails]);

  const invoiceSms = (details) => {
    setSmsLoading(true);
    let value = {};
    value.phoneNumber = details.phoneNumber;
    value.invoice_id = details.id;

    dispatch(sendInvoiceSms(value)).then(({ payload }) => {
      setSmsLoading(false);
      message.success(payload?.message);
    });
  };

  return (
    <>
      <Container padding="50px 50px 50px 330px">
        <Row style={{ alignItems: "baseline" }}>
          <Col
            onClick={() => navigate(-1)}
            span={1}
            style={{ cursor: "pointer" }}
          >
            <LessthanIcon onClick={() => navigate(-1)} />
          </Col>
          <Col>
            <Heading marginBottom="30px">
              <Link to={`/tenants-invoices-details/${params.id}`}>
                Tenants Invoices Details
              </Link>
            </Heading>
          </Col>
        </Row>
        {loading ? (
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        ) : (
          <>
            <TenantNameWrapMain>
              <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={12}>
                  <TenantNameWrap>
                    <span>#TY56KL</span>
                    <h2>
                      {upperCase(invoiceDetails?.firstName)}{" "}
                      {upperCase(invoiceDetails?.lastName)}
                    </h2>
                  </TenantNameWrap>
                </Col>
                <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={12}>
                  <Row gutter={[10, 10]} justify="end">
                    <Col xs={24} sm={20} md={20} lg={20} xl={16} xxl={12}>
                      <DateWrap>
                        <Row>
                          {/* <Row justify="space-around"> */}
                          <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                            <span>Bill Date</span>
                            <p>
                              {invoiceDetails?.invoiceCreatedDate
                                ? dateConverter(
                                    invoiceDetails?.invoiceCreatedDate
                                  )
                                : ""}
                            </p>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                            <Divider type="vertical" />
                          </Col>
                          <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                            <span>Due Date</span>
                            <p>
                              {invoiceDetails?.dueDate
                                ? dateConverter(invoiceDetails?.dueDate)
                                : ""}
                            </p>
                          </Col>
                        </Row>
                      </DateWrap>
                    </Col>
                    <Col>
                      <DateWrap style={{ cursor: "pointer" }}>
                        <RiDownloadLine />
                      </DateWrap>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TenantNameWrapMain>
            <TrDetails>
              <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
                  <TrDetailsLeft>
                    <span>Property Name</span>
                    <p>{invoiceDetails?.unit_name}</p>
                    <Row>
                      {invoiceDetailsFee?.map((value, id) => (
                        <Col span={24} key={id}>
                          <RentWrap>
                            <h2>{feeType(value.type)}</h2>
                            {!value.isEdit ? (
                              <Row
                                gutter={[20, 20]}
                                style={{ alignItems: "center" }}
                              >
                                <Col>
                                  {value.dollerOrPercentage ? (
                                    <h3>
                                      {value.dollerOrPercentage === "$"
                                        ? "$"
                                        : ""}
                                      {value.amount}
                                      {value.dollerOrPercentage === "%"
                                        ? "%"
                                        : ""}
                                    </h3>
                                  ) : (
                                    <h3>
                                      {toUSACurrency(Number(value.amount))}
                                    </h3>
                                  )}
                                  {/* <h3>
                                    {value.dollerOrPercentage
                                      ? " "
                                      : toUSACurrency(Number(value.amount))}
                                  </h3> */}
                                </Col>
                                <Col
                                  onClick={() => {
                                    setIsEdit(true);
                                    amountValueChanger(id);
                                  }}
                                >
                                  <EditIcon />
                                </Col>
                              </Row>
                            ) : (
                              <Row justify="end">
                                <Col span={18}>
                                  <Form
                                    layout="vertical"
                                    // requiredMark={"optional"}
                                    form={form}
                                    //   onFinish={onFinish}
                                    //   validateMessages={validateMessages}
                                  >
                                    {value.type === "taxFee" ? (
                                      <StyledFormItem
                                        wh="100"
                                        smwh="100"
                                        inputHeight="40px"
                                        radioHeight="30px"
                                        radioWidth="30px"
                                      >
                                        <Form.Item
                                          name="rent"
                                          //   label="First Name"
                                          //   rules={[{ required: true }]}
                                        >
                                          {console.log(value.amount)}
                                          <Input
                                            // defaultValue={value.amount}
                                            value={value.amount}
                                            type="number"
                                            step="any"
                                            placeholder="Tax"
                                            onChange={(e) =>
                                              inputChange(
                                                e.target.value,
                                                value.type,
                                                id,
                                                size
                                              )
                                            }
                                            addonAfter={
                                              <>
                                                <Radio.Group
                                                  value={size}
                                                  onChange={(e) => {
                                                    setSize(e.target.value);
                                                    amountValueChangerBtn(
                                                      e.target.value,
                                                      value.type,
                                                      id
                                                    );
                                                  }}
                                                >
                                                  <Radio.Button value="$">
                                                    $
                                                  </Radio.Button>
                                                  <Radio.Button value="%">
                                                    %
                                                  </Radio.Button>
                                                </Radio.Group>
                                              </>
                                            }
                                          />
                                        </Form.Item>
                                      </StyledFormItem>
                                    ) : (
                                      <Form.Item
                                        name="rent"
                                        //   label="First Name"
                                        //   rules={[{ required: true }]}
                                      >
                                        {console.log(value.amount)}
                                        <Input
                                          // defaultValue={value.amount}
                                          value={value.amount}
                                          type="number"
                                          step="any"
                                          placeholder="Amount"
                                          onChange={(e) => {
                                            onOptionChangeInput(
                                              e.target.value,
                                              value.type,
                                              id
                                            );
                                          }}
                                        />
                                      </Form.Item>
                                    )}
                                  </Form>
                                </Col>
                              </Row>
                            )}
                          </RentWrap>
                          <MemoWrap>
                            <span>{value.memo}</span>
                          </MemoWrap>
                        </Col>
                      ))}
                    </Row>
                    <Row justify="space-between">
                      <Col>
                        <PopoverWrap>
                          <Popover
                            placement="bottomLeft"
                            content={content}
                            trigger="click"
                            visible={open}
                            onVisibleChange={handleOpenChange}
                          >
                            <p className="new__changes">
                              {/* <FiPlus /> */}+ New Charge
                            </p>
                          </Popover>
                        </PopoverWrap>
                      </Col>
                      <Col>
                        <TotalAmount style={{ marginTop: "25px" }}>
                          <Row
                            justify="space-between"
                            style={{ alignItems: "end" }}
                          >
                            <Col>
                              <span>Total Amount: </span>
                            </Col>
                            <Col style={{ alignItems: "end" }}>
                              {" "}
                              <p>&nbsp; {toUSACurrency(Number(totalAmount))}</p>
                            </Col>
                          </Row>
                          <Row
                            justify="space-between"
                            style={{ alignItems: "end" }}
                          >
                            <Col>
                              <span>Tax: </span>
                            </Col>
                            <Col style={{ alignItems: "end" }}>
                              {" "}
                              <p>&nbsp; {toUSACurrency(Number(totalTax))}</p>
                            </Col>
                          </Row>
                          <Row style={{ alignItems: "end" }}>
                            <Col>
                              <span>Total Payable Amount: </span>
                            </Col>
                            <Col>
                              {" "}
                              <p>
                                &nbsp;{" "}
                                {toUSACurrency(
                                  Number(
                                    payableAmount ? payableAmount : totalAmount
                                  )
                                )}
                              </p>
                            </Col>
                          </Row>
                        </TotalAmount>
                      </Col>
                    </Row>
                  </TrDetailsLeft>
                  {isFormChange ? (
                    <Row justify="end" style={{ marginTop: "20px" }}>
                      <Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          onClick={() => onClickUpdate(invoiceDetails)}
                          loading={isLoading}
                        >
                          Update
                        </StyledButton>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col
                  className="divider__class"
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  xxl={2}
                >
                  <Divider type="vertical" />
                </Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
                  <TrDetailsRight>
                    <h2>Transaction History</h2>
                    <DiscountTable>
                      <Table
                        columns={columns}
                        // rowKey={(record) => record.login.uuid}
                        dataSource={tableData.payments}
                        // loading={loading}
                        // onChange={handleTableChange}
                        //   pagination={{
                        //     current: 1,
                        //     pageSize: 10,
                        //     size: "small",
                        //     // total: 90,
                        //   }}
                        pagination={false}
                        scroll={{ x: 500 }}
                        // scroll={{ x: 1000 }}
                        // scroll={{ y: 350 }}
                      />
                    </DiscountTable>
                    <Row justify="space-between">
                      <Col>
                        <PostTransactionBtn
                          loading={isCloseLoading}
                          onClick={() =>
                            invoiceClose(invoiceDetails, !isInvoiceClosed)
                          }
                        >
                          {invoiceDetails?.invoiceClose
                            ? "Re-open Invoice"
                            : "Close Invoice"}
                        </PostTransactionBtn>
                      </Col>
                      <Col>
                        <PostTransactionBtn
                          loading={smsLoading}
                          onClick={() => {
                            invoiceSms(invoiceDetails);
                          }}
                        >
                          Send SMS
                        </PostTransactionBtn>
                      </Col>
                    </Row>
                  </TrDetailsRight>
                </Col>
              </Row>
            </TrDetails>
          </>
        )}
      </Container>
    </>
  );
}

export default TenantsInvoicesDetails;

const PostTransactionBtn = styled(Button)`
  margin-top: 30px;
  :hover,
  :focus {
    color: #ffffff;
    border-color: #2b353fdb;
    background: #2b353fdb;
  }

  ${
    "" /* span {
    font-weight: ${({ fw }) => fw || "700"};
    font-size: ${({ fs }) => fs || "18px"};
  } */
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  ${"" /* width: ${({ wh }) => wh || "100%"}; */}
  height: ${({ hg }) => hg || "40px"};
  border-radius: ${({ br }) => br || "5px"};
  background: #2b353f;
  padding: 0 35px;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;

  @media (max-width: 1550px) {
    span {
      font-size: ${({ fs }) => fs || "14px"};
    }

    height: ${({ hg }) => hg || "40px"};
  }

  @media (max-width: 991.98px) {
    display: ${({ displayView }) => displayView || "block"};
  }
`;

const TrDetailsRight = styled.div`
  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 15px;
  }
`;

const PopoverModal = styled.div`
  padding: 8px 4px 0 4px;
  width: 500px;

  .ant-form-item-control-input-content {
    justify-content: end;
    display: flex;
  }

  @media (max-width: 550px) {
    width: 280px;
  }
`;

const PopoverWrap = styled.div`
  margin-top: 25px;

  .new__changes {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #f67c36;
    cursor: pointer;
  }
`;

const MemoWrap = styled.div`
  background: #f5f5f5;
  border: 1px solid #dddddd;
  border-radius: 0px 0px 5px 5px;
  padding: 5px 15px;
  border-top: none;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
  }
`;

const RentWrap = styled.div`
  display: flex;
  padding: 5px 15px;
  border: 1px solid #dddddd;
  border-radius: 5px 5px 0 0;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
    padding-top: 5px;
  }

  .ant-input {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
  }

  input,
  .ant-form-item {
    width: 100%;
    height: 40px;
    background: #eeeeee;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0;

    @media screen and (max-width: 595px) {
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #777777;
  }
  h3 {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
  }
`;

const TrDetailsLeft = styled.div`
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
    margin-bottom: 0;
    margin-top: 5px;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }
`;

const TrDetails = styled.div`
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  padding: 45px;

  .divider__class {
    display: flex;
    justify-content: center;
  }

  .ant-divider-vertical {
    height: 100%;
    background: #dddddd;
  }

  @media (max-width: 1550px) {
    padding: 35px;
  }
  @media (max-width: 425px) {
    padding: 15px;
  }
`;

const DateWrap = styled.div`
  padding: 15px;
  background: #ffffff;
  border-radius: 5px;

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
    margin-bottom: 0;
    margin-top: 14px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .ant-divider-vertical {
    height: 100%;
    background: #eeeeee;
  }
  @media (max-width: 370px) {
    padding: 5px;
  }
`;

const TenantNameWrapMain = styled.div`
  background: #f8f8f8;
  border-radius: 10px 10px 0px 0px;
  padding: 45px;

  @media (max-width: 1550px) {
    padding: 35px;
  }
  @media (max-width: 425px) {
    padding: 15px;
  }
`;

const TenantNameWrap = styled.div`
  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #f67c36;
  }
  h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    margin-bottom: 0;
    margin-top: 18px;
  }
`;

const DiscountTable = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

  .ant-table-body {
    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  ${
    "" /* .ant-table-container,
  .ant-table-content,
  table {
    border-radius: 5px 5px 0 0 !important;
  } */
  }

  .ant-table-container .ant-table-content table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  ${
    "" /* .ant-table-container,
  .ant-table-header,
  table {
    border-radius: 5px 5px 0 0 !important;
  } */
  }
  .ant-table-container .ant-table-body table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.forth};
  }

  .ant-table-thead > tr > th {
    background-color: #aaaaaa !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
  }

  /* FOR PAGINATION */
  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.forth};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }

  @media (max-width: 1550px) {
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      padding: 12px 12px;
    }

    .ant-table-tbody > tr > td {
      font-size: 14px;
      line-height: 16px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-pagination.mini .ant-pagination-options {
      display: none;
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 5px 4px;
    }
  }

  @media (max-width: 991.98px) {
    margin-top: 10px;
  }
  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
  }
`;
