import React from "react";

const NoListingsSvg = ({
  color = "#666666",
  width = "388",
  height = "265",
  fill = "#EEEEEE",
  stroke = "#777777",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 388 265"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M382.982 262.196C383.335 232.249 356.794 213.16 357.509 190.405C358.242 167.057 381.043 145.435 368.543 84.8918C356.043 24.3491 294.989 -5.93155 239.077 7.04189C183.165 20.0153 195.684 54.6205 142.732 53.7481C89.7808 52.8758 47.9094 52.8851 16.2553 126.401C-13.2272 194.934 5.86173 254.465 8.59005 262.196H382.982Z"
        fill="#4160AC"
      />
      <path
        opacity="0.7"
        d="M382.982 262.196C383.335 232.249 356.794 213.16 357.509 190.405C358.242 167.057 381.043 145.435 368.543 84.8918C356.043 24.3491 294.989 -5.93155 239.077 7.04189C183.165 20.0153 195.684 54.6205 142.732 53.7481C89.7808 52.8758 47.9094 52.8851 16.2553 126.401C-13.2272 194.934 5.86173 254.465 8.59005 262.196H382.982Z"
        fill="white"
      />
      <path
        d="M376.422 261.546L258.288 18.9297L214.022 99.2574L157.498 62.2673L108.453 115.627L85.1507 93.1418L19.5597 241.678L5.54688 261.546H376.422Z"
        fill="#4160AC"
      />
      <path
        opacity="0.5"
        d="M376.422 261.546L258.288 18.9297L214.022 99.2574L157.498 62.2673L108.453 115.627L85.1507 93.1418L19.5597 241.678L5.54688 261.546H376.422Z"
        fill="white"
      />
      <path
        d="M252.844 127.203L255.702 129.393"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.688 100.352L248.822 124.127"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.188 122.523L82.5469 146.28"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M267.649 74.1643V49.0618L270.712 50.2868L270.962 44.9601L258.286 18.9297L242.25 48.041L251.122 40.4964L252.959 49.0618L257.859 45.3963L267.649 74.1643Z"
        fill="white"
      />
      <path
        d="M172.704 82.2269L174.467 73.3738L157.494 62.2656L144.391 76.529L154.162 72.7242L160.343 82.2269L160.816 75.0999L172.704 82.2269Z"
        fill="white"
      />
      <path
        d="M359.085 154.109H203.766V261.136H359.085V154.109Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M298.302 209.164H276.633V261.132H298.302V209.164Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M295.982 212.883H278.953V261.139H295.982V212.883Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M311.408 202.141H306.805V261.134H311.408V202.141Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M312.218 240.422H306V261.135H312.218V240.422Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M363.837 155.496H219.515C219.459 155.496 219.405 155.477 219.362 155.441C219.319 155.406 219.289 155.357 219.278 155.302C219.267 155.247 219.276 155.191 219.302 155.141C219.328 155.092 219.37 155.053 219.422 155.032L259.725 137.576C260.454 137.259 261.24 137.094 262.036 137.094H317.66C318.398 137.093 319.129 137.235 319.813 137.511L363.93 155.023C363.991 155.037 364.044 155.074 364.078 155.127C364.112 155.179 364.125 155.243 364.113 155.304C364.101 155.366 364.065 155.42 364.014 155.456C363.962 155.492 363.899 155.506 363.837 155.496Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M316.703 206.688H233.805L240.004 188.75H305.725L316.703 206.688Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M269.298 154.109H198.297V261.136H269.298V154.109Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M269.298 242.578H198.297V261.138H269.298V242.578Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.763 136.292L194.001 153.924C193.948 153.946 193.905 153.985 193.879 154.035C193.853 154.086 193.845 154.143 193.857 154.199C193.869 154.254 193.901 154.303 193.946 154.338C193.99 154.373 194.046 154.39 194.103 154.388H273.502C273.559 154.39 273.615 154.373 273.659 154.338C273.704 154.303 273.736 154.254 273.748 154.199C273.76 154.143 273.752 154.086 273.726 154.035C273.7 153.985 273.657 153.946 273.604 153.924L234.842 136.292C234.516 136.141 234.161 136.062 233.802 136.062C233.443 136.062 233.089 136.141 232.763 136.292Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M287.458 222.626C288.483 222.626 289.314 221.795 289.314 220.77C289.314 219.745 288.483 218.914 287.458 218.914C286.433 218.914 285.602 219.745 285.602 220.77C285.602 221.795 286.433 222.626 287.458 222.626Z"
        fill="white"
      />
      <path
        d="M354.445 213.805H320.109V242.573H354.445V213.805Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M341.454 216.422H333.102V222.621H341.454V216.422Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M341.454 225.406H333.102V239.957H341.454V225.406Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M330.78 216.422H323.227V222.621H330.78V216.422Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M351.327 216.422H343.773V222.621H351.327V216.422Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M351.327 225.406H343.773V239.957H351.327V225.406Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M330.78 225.406H323.227V239.957H330.78V225.406Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M255.608 213.805H211.992V242.573H255.608V213.805Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.336 216.422H224.984V222.621H233.336V216.422Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.336 225.406H224.984V239.957H233.336V225.406Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.663 216.422H215.109V222.621H222.663V216.422Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.21 216.422H235.656V222.621H243.21V216.422Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.21 225.406H235.656V239.957H243.21V225.406Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.96 216.422H245.406V222.621H252.96V216.422Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.96 225.406H245.406V239.957H252.96V225.406Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.663 225.406H215.109V239.957H222.663V225.406Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M255.608 158.594H211.992V187.362H255.608V158.594Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.336 161.211H224.984V167.41H233.336V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.336 170.195H224.984V184.746H233.336V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.663 161.211H215.109V167.41H222.663V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.21 161.211H235.656V167.41H243.21V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.21 170.195H235.656V184.746H243.21V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.96 161.211H245.406V167.41H252.96V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.96 170.195H245.406V184.746H252.96V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.663 170.195H215.109V184.746H222.663V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M354.445 158.594H320.109V187.362H354.445V158.594Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M341.454 161.211H333.102V167.41H341.454V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M341.454 170.195H333.102V184.746H341.454V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M330.78 161.211H323.227V167.41H330.78V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M351.327 161.211H343.773V167.41H351.327V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M351.327 170.195H343.773V184.746H351.327V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M330.78 170.195H323.227V184.746H330.78V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M300.151 158.594H276.023V187.362H300.151V158.594Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M297.368 161.211H289.016V167.41H297.368V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M297.368 170.195H289.016V184.746H297.368V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.695 161.211H279.141V167.41H286.695V161.211Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.695 170.195H279.141V184.746H286.695V170.195Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M345.396 242.578H329.156V247.218H345.396V242.578Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M242.693 242.578H226.453V247.218H242.693V242.578Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.721 168.336H34.875V261.136H160.721V168.336Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.315 170.953H24.2734L35.261 174.953H160.318L171.315 170.953Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.335 170.968H24.2656L34.3159 156.528C34.6086 156.108 34.9986 155.764 35.4526 155.527C35.9066 155.289 36.4112 155.165 36.9236 155.164H158.659C159.172 155.164 159.679 155.288 160.134 155.526C160.59 155.763 160.981 156.107 161.276 156.528L171.335 170.968Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.4415 143.781H36.9922V157.785H47.4415V143.781Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.4344 143.781H44.6875V157.785H47.4344V143.781Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.1671 139.375H35.2656V146.576H49.1671V139.375Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.1664 139.375H44.3594V146.576H49.1664V139.375Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.2305 175.492H50.0625V202.209H67.2305V175.492Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.3779 177.156H51.9219V200.885H65.3779V177.156Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.3779 177.156H51.9219V190.148H65.3779V177.156Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.6648 177.156H55.6328V190.148H61.6648V177.156Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.0173 227H47.2812V253.717H70.0173V227Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5598 228.656H49.7422V252.385H67.5598V228.656Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5598 228.656H49.7422V241.648H67.5598V228.656Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.6463 228.656H54.6562V241.648H62.6463V228.656Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.97 227H125.234V253.717H147.97V227Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.505 228.656H127.688V252.385H145.505V228.656Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.505 228.656H127.688V241.648H145.505V228.656Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.599 228.656H132.609V241.648H140.599V228.656Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.113 175.492H128.945V202.209H146.113V175.492Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.253 177.156H130.797V200.885H144.253V177.156Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.253 177.156H130.797V190.148H144.253V177.156Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.548 177.156H134.516V190.148H140.548V177.156Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.961 179.477H90.9062V198.231H102.961V179.477Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.641 180.641H92.2031V196.899H101.641V180.641Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.641 180.641H92.2031V189.754H101.641V180.641Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.0442 180.641H94.8125V189.754H99.0442V180.641Z"
        fill="#263238"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.094 219.375H81.4844V252.319H114.094V219.375Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.381 224.016H85.1953V252.32H110.381V224.016Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.2139 224.016H89.8359V252.32H93.2139V224.016Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.354 224.016H100.977V252.32H104.354V224.016Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.7206 217.672H82.8044L97.7916 204.68L112.788 217.672H108.993V219.834H120.296L98.3298 200.82C98.1809 200.689 97.9896 200.617 97.7916 200.617C97.5936 200.617 97.4023 200.689 97.2534 200.82L75.2969 219.834H86.7206V217.672Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.252 252.32H81.4844V255.206H114.252V252.32Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.252 255.125H81.4844V258.011H114.252V255.125Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.252 257.93H81.4844V260.816H114.252V257.93Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.1977 224.016H81.9219"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.963 224.016H110.688"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195.756 243.825C195.756 243.825 204.489 244.688 208.915 250.099C210.505 248.172 212.503 246.621 214.764 245.559C217.024 244.497 219.493 243.949 221.991 243.955C224.041 241.479 225.347 238.472 225.754 235.283C226.162 232.094 225.656 228.854 224.295 225.942C222.933 223.029 220.773 220.563 218.064 218.831C215.356 217.099 212.211 216.171 208.996 216.156C205.782 216.142 202.629 217.04 199.904 218.748C197.18 220.455 194.997 222.901 193.609 225.801C192.221 228.701 191.685 231.935 192.063 235.128C192.442 238.32 193.719 241.34 195.747 243.835L195.756 243.825Z"
        fill="#4160AC"
      />
      <path
        opacity="0.5"
        d="M195.756 243.825C195.756 243.825 204.489 244.688 208.915 250.099C210.505 248.172 212.503 246.621 214.764 245.559C217.024 244.497 219.493 243.949 221.991 243.955C224.041 241.479 225.347 238.472 225.754 235.283C226.162 232.094 225.656 228.854 224.295 225.942C222.933 223.029 220.773 220.563 218.064 218.831C215.356 217.099 212.211 216.171 208.996 216.156C205.782 216.142 202.629 217.04 199.904 218.748C197.18 220.455 194.997 222.901 193.609 225.801C192.221 228.701 191.685 231.935 192.063 235.128C192.442 238.32 193.719 241.34 195.747 243.835L195.756 243.825Z"
        fill="white"
      />
      <path
        d="M208.625 217.344L208.328 261.136H209.804L208.625 217.344Z"
        fill="#263238"
      />
      <path
        d="M209.061 248.463C209.061 248.463 212.012 243.155 217.32 241.531C217.32 241.531 211.752 242.858 208.922 247.424L209.061 248.463Z"
        fill="#263238"
      />
      <path
        d="M209.061 233.417C209.061 233.417 212.012 228.108 217.32 226.484C217.32 226.484 211.752 227.811 208.922 232.386L209.061 233.417Z"
        fill="#263238"
      />
      <path
        d="M209.061 240.931C209.061 240.931 212.012 235.623 217.32 234.008C217.32 234.008 211.752 235.335 208.922 239.901L209.061 240.931Z"
        fill="#263238"
      />
      <path
        d="M208.626 237.252C208.626 237.252 205.787 231.944 200.664 230.32C200.664 230.32 206.065 231.647 208.775 236.222L208.626 237.252Z"
        fill="#263238"
      />
      <path
        d="M208.626 244.618C208.626 244.618 205.787 239.319 200.664 237.695C200.664 237.695 206.065 239.022 208.775 243.588L208.626 244.618Z"
        fill="#263238"
      />
      <path
        d="M357.505 243.825C357.505 243.825 366.237 244.688 370.673 250.099C372.263 248.172 374.26 246.621 376.521 245.559C378.782 244.497 381.251 243.949 383.749 243.955C385.799 241.479 387.104 238.472 387.512 235.283C387.92 232.094 387.414 228.854 386.052 225.942C384.691 223.029 382.53 220.563 379.822 218.831C377.114 217.099 373.969 216.171 370.754 216.156C367.539 216.142 364.386 217.04 361.662 218.748C358.938 220.455 356.755 222.901 355.367 225.801C353.979 228.701 353.443 231.935 353.821 235.128C354.2 238.32 355.477 241.34 357.505 243.835V243.825Z"
        fill="#4160AC"
      />
      <path
        opacity="0.5"
        d="M357.505 243.825C357.505 243.825 366.237 244.688 370.673 250.099C372.263 248.172 374.26 246.621 376.521 245.559C378.782 244.497 381.251 243.949 383.749 243.955C385.799 241.479 387.104 238.472 387.512 235.283C387.92 232.094 387.414 228.854 386.052 225.942C384.691 223.029 382.53 220.563 379.822 218.831C377.114 217.099 373.969 216.171 370.754 216.156C367.539 216.142 364.386 217.04 361.662 218.748C358.938 220.455 356.755 222.901 355.367 225.801C353.979 228.701 353.443 231.935 353.821 235.128C354.2 238.32 355.477 241.34 357.505 243.835V243.825Z"
        fill="white"
      />
      <path
        d="M370.381 217.344L370.094 261.136H371.56L370.381 217.344Z"
        fill="#263238"
      />
      <path
        d="M370.852 248.463C370.852 248.463 373.803 243.155 379.111 241.531C379.111 241.531 373.543 242.858 370.703 247.424L370.852 248.463Z"
        fill="#263238"
      />
      <path
        d="M370.852 233.417C370.852 233.417 373.803 228.108 379.111 226.484C379.111 226.484 373.543 227.811 370.703 232.386L370.852 233.417Z"
        fill="#263238"
      />
      <path
        d="M370.852 240.931C370.852 240.931 373.803 235.623 379.111 234.008C379.111 234.008 373.543 235.335 370.703 239.901L370.852 240.931Z"
        fill="#263238"
      />
      <path
        d="M370.386 237.252C370.386 237.252 367.537 231.944 362.414 230.32C362.414 230.32 367.824 231.647 370.525 236.222L370.386 237.252Z"
        fill="#263238"
      />
      <path
        d="M370.386 244.618C370.386 244.618 367.537 239.319 362.414 237.695C362.414 237.695 367.824 239.022 370.525 243.588L370.386 244.618Z"
        fill="#263238"
      />
      <path
        d="M6.7236 243.825C6.7236 243.825 15.4561 244.688 19.8919 250.099C21.4818 248.172 23.4792 246.621 25.7401 245.559C28.001 244.497 30.4694 243.949 32.9674 243.955C35.018 241.479 36.3232 238.472 36.731 235.283C37.1388 232.094 36.6326 228.854 35.2712 225.942C33.9097 223.029 31.7492 220.563 29.0409 218.831C26.3326 217.099 23.1879 216.171 19.973 216.156C16.7581 216.142 13.6051 217.04 10.881 218.748C8.15688 220.455 5.97371 222.901 4.58557 225.801C3.19743 228.701 2.66142 231.935 3.03993 235.128C3.41843 238.32 4.69588 241.34 6.7236 243.835V243.825Z"
        fill="#4160AC"
      />
      <path
        opacity="0.5"
        d="M6.7236 243.825C6.7236 243.825 15.4561 244.688 19.8919 250.099C21.4818 248.172 23.4792 246.621 25.7401 245.559C28.001 244.497 30.4694 243.949 32.9674 243.955C35.018 241.479 36.3232 238.472 36.731 235.283C37.1388 232.094 36.6326 228.854 35.2712 225.942C33.9097 223.029 31.7492 220.563 29.0409 218.831C26.3326 217.099 23.1879 216.171 19.973 216.156C16.7581 216.142 13.6051 217.04 10.881 218.748C8.15688 220.455 5.97371 222.901 4.58557 225.801C3.19743 228.701 2.66142 231.935 3.03993 235.128C3.41843 238.32 4.69588 241.34 6.7236 243.835V243.825Z"
        fill="white"
      />
      <path
        d="M19.5924 217.344L19.3047 261.136H20.7802L19.5924 217.344Z"
        fill="#263238"
      />
      <path
        d="M20.0704 248.463C20.0704 248.463 23.0214 243.155 28.3296 241.531C28.3296 241.531 22.7616 242.858 19.9219 247.424L20.0704 248.463Z"
        fill="#263238"
      />
      <path
        d="M20.0704 233.417C20.0704 233.417 23.0214 228.108 28.3296 226.484C28.3296 226.484 22.7616 227.811 19.9219 232.386L20.0704 233.417Z"
        fill="#263238"
      />
      <path
        d="M20.0704 240.931C20.0704 240.931 23.0214 235.623 28.3296 234.008C28.3296 234.008 22.7616 235.335 19.9219 239.901L20.0704 240.931Z"
        fill="#263238"
      />
      <path
        d="M19.6043 237.252C19.6043 237.252 16.7554 231.944 11.6328 230.32C11.6328 230.32 17.0431 231.647 19.7435 236.222L19.6043 237.252Z"
        fill="#263238"
      />
      <path
        d="M19.6043 244.618C19.6043 244.618 16.7554 239.319 11.6328 237.695C11.6328 237.695 17.0431 239.022 19.7435 243.588L19.6043 244.618Z"
        fill="#263238"
      />
      <path
        d="M148.829 230.902C148.829 230.902 164.085 232.414 171.815 241.871C171.815 241.871 179.982 230.92 194.663 231.143C198.262 226.814 200.557 221.552 201.279 215.968C202.002 210.385 201.122 204.712 198.744 199.609C196.365 194.507 192.585 190.186 187.844 187.15C183.103 184.113 177.597 182.487 171.967 182.461C166.337 182.435 160.816 184.01 156.047 187.001C151.278 189.993 147.458 194.279 145.032 199.359C142.606 204.439 141.674 210.104 142.344 215.694C143.015 221.284 145.26 226.568 148.819 230.93L148.829 230.902Z"
        fill="#4160AC"
      />
      <path
        opacity="0.3"
        d="M148.829 230.902C148.829 230.902 164.085 232.414 171.815 241.871C171.815 241.871 179.982 230.92 194.663 231.143C198.262 226.814 200.557 221.552 201.279 215.968C202.002 210.385 201.122 204.712 198.744 199.609C196.365 194.507 192.585 190.186 187.844 187.15C183.103 184.113 177.597 182.487 171.967 182.461C166.337 182.435 160.816 184.01 156.047 187.001C151.278 189.993 147.458 194.279 145.032 199.359C142.606 204.439 141.674 210.104 142.344 215.694C143.015 221.284 145.26 226.568 148.819 230.93L148.829 230.902Z"
        fill="white"
      />
      <path
        d="M171.317 184.656L170.797 261.133H173.377L171.317 184.656Z"
        fill="#263238"
      />
      <path
        d="M172.08 238.993C172.08 238.993 177.231 229.713 186.501 226.883C186.501 226.883 176.711 229.203 171.82 237.184L172.08 238.993Z"
        fill="#263238"
      />
      <path
        d="M172.08 212.72C172.08 212.72 177.231 203.44 186.501 200.656C186.501 200.656 176.711 202.976 171.82 210.957L172.08 212.72Z"
        fill="#263238"
      />
      <path
        d="M172.08 225.869C172.08 225.869 177.231 216.589 186.501 213.805C186.501 213.805 176.711 216.125 171.82 224.105L172.08 225.869Z"
        fill="#263238"
      />
      <path
        d="M171.334 219.423C171.334 219.423 166.36 210.143 157.414 207.359C157.414 207.359 166.861 209.67 171.575 217.651L171.334 219.423Z"
        fill="#263238"
      />
      <path
        d="M171.334 232.291C171.334 232.291 166.36 223.011 157.414 220.227C157.414 220.227 166.861 222.547 171.575 230.527L171.334 232.291Z"
        fill="#263238"
      />
      <path
        d="M382.893 260.516H4.89062V264.228H382.893V260.516Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.241 124.895C181.179 123.215 179.787 124.617 178.673 123.215C177.56 121.814 181.745 118.195 173.374 114.576C173.374 114.576 172.818 106.762 161.385 106.484C149.952 106.205 153.033 114.297 149.952 116.246C146.871 118.195 146.045 115.133 142.982 117.081C139.92 119.03 141.303 122.937 138.797 123.494C136.291 124.051 132.941 120.988 129.87 122.937C126.798 124.886 126.789 126.575 125.397 126.575C124.005 126.575 121.221 125.461 119.541 126.575C117.861 127.688 115.922 129.08 115.922 129.08H186.45C186.45 129.08 187.313 126.575 184.241 124.895Z"
        fill="white"
      />
      <path
        d="M154.725 114.009C152.498 112.784 151.477 113.804 150.669 112.784C149.862 111.763 152.897 109.127 146.809 106.492C146.809 106.492 146.401 100.803 138.067 100.599C129.734 100.395 131.979 106.288 129.715 107.707C127.451 109.127 126.866 106.9 124.63 108.32C122.393 109.74 123.414 112.589 121.586 112.96C119.758 113.331 117.317 111.104 115.09 112.561C112.863 114.018 112.863 115.196 111.842 115.196C110.821 115.196 108.798 114.389 107.573 115.196C106.348 116.004 104.938 117.052 104.938 117.052H156.339C156.339 117.052 156.961 115.224 154.725 114.009Z"
        fill="white"
      />
      <path
        d="M172.354 25.7936C168.178 23.52 166.275 25.4132 164.763 23.52C163.25 21.6269 168.939 16.6807 157.543 11.7437C157.543 11.7437 156.782 1.10885 141.21 0.728371C125.638 0.347891 129.824 11.3633 125.638 14.0266C121.453 16.69 120.33 12.5047 116.145 15.1588C111.96 17.8129 113.871 23.1396 110.456 23.9005C107.041 24.6615 102.475 20.4762 98.2994 23.1396C94.1234 25.8029 94.1234 28.0765 92.221 28.0765C90.3186 28.0765 86.5231 26.5546 84.2495 28.0765C81.9759 29.5985 79.3125 31.4916 79.3125 31.4916H175.388C175.388 31.4916 176.53 28.0765 172.354 25.7936Z"
        fill="#4160AC"
      />
      <path
        opacity="0.8"
        d="M172.354 25.7936C168.178 23.52 166.275 25.4132 164.763 23.52C163.25 21.6269 168.939 16.6807 157.543 11.7437C157.543 11.7437 156.782 1.10885 141.21 0.728371C125.638 0.347891 129.824 11.3633 125.638 14.0266C121.453 16.69 120.33 12.5047 116.145 15.1588C111.96 17.8129 113.871 23.1396 110.456 23.9005C107.041 24.6615 102.475 20.4762 98.2994 23.1396C94.1234 25.8029 94.1234 28.0765 92.221 28.0765C90.3186 28.0765 86.5231 26.5546 84.2495 28.0765C81.9759 29.5985 79.3125 31.4916 79.3125 31.4916H175.388C175.388 31.4916 176.53 28.0765 172.354 25.7936Z"
        fill="white"
      />
    </svg>
  );
};

export default NoListingsSvg;
