import React from "react";

const EditIcon = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.8" cx="17.5" cy="17.5" r="17.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 21.2556V24H13.7444L21.6889 15.9833L18.9444 13.2389L11 21.2556ZM23.7833 13.8889C24.0722 13.6 24.0722 13.1667 23.7833 12.8778L22.1222 11.2167C21.8333 10.9278 21.4 10.9278 21.1111 11.2167L19.8111 12.5167L22.5556 15.2611L23.7833 13.8889Z"
        fill="#2B353F"
      />
    </svg>
  );
};

export default EditIcon;
