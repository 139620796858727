import React from "react";
import { CurrentStagWrap } from "./ApplicationsStyle";

function DepositSubmitted() {
  return (
    <>
      <CurrentStagWrap>
        <h2>Step : 5 Deposit Submitted</h2>
      </CurrentStagWrap>
    </>
  );
}

export default DepositSubmitted;
