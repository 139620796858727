import { Col, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { EditSvg } from "../../assets/svgs";
import AddTenantsModal from "../../components/addTenantsModal/AddTenantsModal";
import { EditIconWrap } from "../../components/apartments-view/Actions";
import { ApartmentTable } from "../../components/apartments-view/ApartmentsViewStyle";
import { NameWrap, TenantsDetails } from "../../components/tenants/Tenants";
import {
  getTenant,
  getTenantFees,
  getTenantTr,
  invoicesByTenant,
} from "../../store/reducers/user.reducer";
import { UserAvatar } from "../../ui/Avatar";
import { Container } from "../../ui/Layout/Container";
import { DiscountTable } from "../invoices/Invoices";
import { PostIncome } from "../tenantLedger/TenantLedger";
import TenantsInvoicesModal from "../tenantsInvoicesDetails/TenantsInvoicesModal";

function TenantDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tenantDetailsObj, setTenantDetailsObj] = useState({});
  const [tenantTrObj, setTenantTrObj] = useState({});
  const [tenantDetailsArr, setTenantDetailsArr] = useState([]);
  const [tenantTrArr, setTenantTrArr] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [editModalState, setEditModalState] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getAllIncoice, setGetAllIncoice] = useState({});
  const [getAllIncoiceArr, setGetAllIncoiceArr] = useState([]);
  const [shortedData, setShortedData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [tenantFees, setTenantFees] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({});

  useEffect(() => {
    const val = getAllIncoiceArr.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.currentDayTime) - new Date(a.currentDayTime);
    });

    setShortedData(val);
  }, [getAllIncoiceArr]);

  useEffect(() => {
    if (params.id) {
      localStorage.setItem("tenant_id", params.id);
      setLoading(true);
      dispatch(getTenantFees()).then(({ payload }) => {
        setTenantFees(payload);
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (getAllIncoice && Object.values(getAllIncoice).length) {
      const arr = Object.values(getAllIncoice);
      setGetAllIncoiceArr(arr);
    }
  }, [getAllIncoice]);

  useEffect(() => {
    setLoading(true);

    dispatch(invoicesByTenant()).then(({ payload }) => {
      setGetAllIncoice(payload);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (params.id) {
      localStorage.setItem("tenant_id", params.id);
      setLoading(true);
      dispatch(getTenant()).then(({ payload }) => {
        setTenantDetails(payload[0]);
        setTenantDetailsObj(payload);
        setLoading(false);
      });
      dispatch(getTenantTr()).then(({ payload }) => {
        setTenantTrObj(payload);
        setLoading(false);
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (tenantDetailsObj && Object.keys(tenantDetailsObj).length) {
      const arr = Object.values(tenantDetailsObj);
      setTenantDetailsArr(arr);
    }
    if (tenantTrObj && Object.keys(tenantTrObj).length) {
      const arr = Object.values(tenantTrObj);
      setTenantTrArr(arr);
    }
  }, [tenantDetailsObj, tenantTrObj]);

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const columns = [
    {
      title: "Tx ID",
      dataIndex: "transactionId",
      width: 180,

      render: (_, record) => <span>#{record.transactionId}</span>,
    },
    {
      title: "Unit",
      dataIndex: "unit_name",
      width: 300,
      render: (_, record) => (
        <span>
          {record.unit_name}, {record.apartment_name}
        </span>
      ),
    },
    {
      title: "Rent",
      dataIndex: "rate",
      width: 110,

      render: (_, record) => (
        <span>{toUSACurrency(Number(record?.payableAmount))}</span>
      ),
    },

    {
      title: "Payment Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 130,

      render: (_, record) => (
        <span>{dateConverter(record?.squareResponse.createdAt)}</span>
      ),
    },
    {
      title: "Received from",
      dataIndex: "firstName",
      width: 180,

      render: (_, record) => (
        <div>
          <td>
            {upperCase(record.firstName)} {upperCase(record.lastName)}
          </td>
        </div>
      ),
    },
    {
      title: "Payment Type",
      dataIndex: "sourceType",
      key: "sourceType",
      width: 130,

      render: (_, record) => (
        <span>{sourceTypeFun(record.squareResponse.sourceType)}</span>
      ),
    },
    {
      title: "Payment Details",
      dataIndex: "paymentDetails",
      key: "sourceType",
      width: 250,
      render: (_, record) => (
        <>
          {record.squareResponse.cardDetails ? (
            <span>
              {lowerCase(record.squareResponse.cardDetails?.card?.cardBrand)}{" "}
              {lowerCase(record.squareResponse.cardDetails?.card?.cardType)}, *
              {record.squareResponse.cardDetails?.card?.last4}
            </span>
          ) : record.squareResponse.bankAccountDetails ? (
            <span>
              {lowerCase(
                record.squareResponse.bankAccountDetails?.accountOwnershipType
              )}{" "}
              {lowerCase(
                record.squareResponse.bankAccountDetails?.achDetails
                  ?.accountType
              )}
              , *
              {lowerCase(
                record.squareResponse.bankAccountDetails?.achDetails
                  ?.accountNumberSuffix
              )}
            </span>
          ) : (
            <span></span>
          )}
        </>
      ),
    },
    {
      title: "Received Amt",
      dataIndex: "amount",
      width: 130,

      render: (_, record) => (
        <span>
          {toUSACurrency(
            Number(record.squareResponse.totalMoney?.amount) / 100
          )}
        </span>
      ),
    },

    // {
    //   title: "Balance",
    //   dataIndex: "amount",
    //   key: "amount",
    //   width: 90,

    //   render: (_, record) => <span>{toUSACurrency(0)}</span>,
    // },

    // {
    //   dataIndex: "action",
    //   key: "action",
    //   align: "right",
    //   // width: 0,
    //   render: (_, record) => {
    //     return (
    //       <Actions
    //       // options={actionOptions}
    //       // item={record._id}
    //       // onChange={actionHandler}
    //       />
    //     );
    //   },
    // },F
  ];

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day} ${month}, ${year}`;
  }

  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  const sourceTypeFun = (type) => {
    switch (type) {
      case "WALLET":
        return "Cash App";
      case "CARD":
        return "Card";
      case "BANK_ACCOUNT":
        return "ACH";

      default:
        return type;
    }
  };

  const lowerCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    size: "small",
  });
  const fetchData = (params = {}) => {
    setPagination({
      ...params.pagination,
      total: tenantTrArr.length, // 200 is mock data, you should read it from server
    });
  };
  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  const editValue = (value) => {
    setSelectedInvoice(value);
    setModalState(true);
    setIsEdit(true);
  };

  const columnsForInvoice = [
    {
      title: "Bill Date",
      dataIndex: "invoiceCreatedDate",
      sorter: (a, b) =>
        new Date(a.invoiceCreatedDate) - new Date(b.invoiceCreatedDate),
      render: (_, record) => (
        <span>{dateConverter(record.invoiceCreatedDate)}</span>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      // ellipsis: true,
      render: (_, record) => <span>{dateConverter(record.dueDate)}</span>,
    },
    // {
    //   title: "Payment Type",
    //   dataIndex: "paymentType",
    // },
    {
      title: "Amount",
      dataIndex: "payableAmount",
      sorter: (a, b) => a.payableAmount - b.payableAmount,
      render: (_, record) => <span>{toUSACurrency(record.payableAmount)}</span>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (_, record) => <span>{toUSACurrency(record.balance)}</span>,
    },
    {
      title: "Closed",
      dataIndex: "invoiceClose",
      render: (_, record) => (
        <>
          {record?.invoiceClose ? (
            <span>
              <AiOutlineClose />
            </span>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      align: "right",
      width: "8%",
      render: (_, record) => {
        return (
          <EditIconWrap
            onClick={(event) => {
              event.stopPropagation();
              editValue(record);
            }}
          >
            <EditSvg />
          </EditIconWrap>
        );
      },
    },
  ];

  const newInvoice = (value) => {
    setGetAllIncoiceArr((val) => [value, ...val]);
  };

  const updatedData = (data) => {
    setTableLoading(true);
    dispatch(invoicesByTenant()).then(({ payload }) => {
      setGetAllIncoice(payload);
      setTableLoading(false);
    });
  };

  const tenantDetailsPage = (value) => {
    navigate(`/tenants-invoices-details/${value.id}`);
  };

  const updatedTenat = (data) => {
    setTenantDetailsArr([data]);
  };

  return (
    <>
      {loading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <Container padding="50px 50px 0 330px">
          <Row gutter={[5, 5]}>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
              <ProfileDetailsWrap
                br="10px 0 0 10px"
                style={{ background: "white" }}
              >
                <RightSideTDetails>
                  <NameWrap>
                    <UserAvatar
                      className="userName__icon"
                      fullName={"Mark Jecno"}
                      // fullName={`${firstName || ""} ${lastName || ""}`}
                      // src={
                      //   item?.user?.avatar?.path
                      //     ? imagePath(item?.user?.avatar?.path)
                      //     : ""
                      // }
                      src={"/images/tenants.png"}
                    />
                  </NameWrap>
                  <MyAccountWrapRight>
                    <Row justify="space-between">
                      <Col>
                        <h2>
                          {upperCase(tenantDetailsArr[0]?.firstName)}{" "}
                          {upperCase(tenantDetailsArr[0]?.lastName)}
                        </h2>
                      </Col>
                      <Col>
                        <span onClick={() => setEditModalState(true)}>
                          Edit
                        </span>
                      </Col>
                    </Row>
                  </MyAccountWrapRight>
                </RightSideTDetails>
                <NameDetailsWrap>
                  {tenantDetailsArr[0]?.email ? (
                    <Row>
                      <Col span={24}>
                        <span>Email</span>
                      </Col>
                      <Col span={24}>
                        <p>{tenantDetailsArr[0].email}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {tenantDetailsArr[0]?.phoneNumber ? (
                    <Row>
                      <Col span={24}>
                        <span>Phone</span>
                      </Col>
                      <Col span={24}>
                        <p>{tenantDetailsArr[0]?.phoneNumber}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col span={24}>
                      <h4>Unit Details</h4>
                    </Col>
                  </Row>
                  <Row>
                    {tenantDetailsArr[0]?.unitNumber ? (
                      <Col span={12}>
                        <Row>
                          <Col span={24}>
                            <span>Unit No</span>
                          </Col>
                          <Col span={24}>
                            <p>{tenantDetailsArr[0]?.unitNumber}</p>
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      ""
                    )}
                    {tenantDetailsArr[0]?.unitType ? (
                      <Col span={12}>
                        <Row>
                          <Col span={24}>
                            <span>Unit Type</span>
                          </Col>
                          <Col span={24}>
                            <p>{tenantDetailsArr[0]?.unitType}</p>
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  {tenantDetailsArr[0]?.leaseStart ? (
                    <Row>
                      <Col span={24}>
                        <span>Lease Start</span>
                      </Col>
                      <Col span={24}>
                        <p>{tenantDetailsArr[0]?.leaseStart}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {tenantDetailsArr[0]?.leaseTerm ? (
                    <Row>
                      <Col span={24}>
                        <span>Lease Term</span>
                      </Col>
                      <Col span={24}>
                        <p> {tenantDetailsArr[0]?.leaseTerm}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {tenantDetailsArr[0]?.leaseEnd ? (
                    <Row>
                      <Col span={24}>
                        <span>Lease End</span>
                      </Col>
                      <Col span={24}>
                        <p>{tenantDetailsArr[0]?.leaseEnd}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {tenantDetailsArr[0]?.rate ? (
                    <Row>
                      <Col span={24}>
                        <span>Rent</span>
                      </Col>
                      <Col span={24}>
                        <p>
                          {toUSACurrency(Number(tenantDetailsArr[0]?.rate))}
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </NameDetailsWrap>
              </ProfileDetailsWrap>
            </Col>
            <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
              <ProfileDetailsWrap>
                <Row style={{ marginBottom: "10px" }} justify="space-between">
                  <Col>
                    <h2>Transactions</h2>
                  </Col>
                  <Col style={{ marginRight: "10px" }}>
                    <PostIncome
                      onClick={() => {
                        setModalState(true);
                        setIsEdit(false);
                      }}
                      bgColor={"#777777"}
                      fw={"400"}
                      displayView="none"
                    >
                      Create Invoice
                    </PostIncome>
                  </Col>
                </Row>
                <DiscountTable>
                  <Table
                    columns={columns}
                    dataSource={[...tenantTrArr]}
                    loading={loading}
                    onChange={handleTableChange}
                    pagination={pagination}
                    scroll={{ x: 1300 }}
                  />
                </DiscountTable>
                <Row style={{ marginBottom: "10px" }} justify="space-between">
                  <Col>
                    <h2
                      onClick={() => {
                        navigate(`/tenants-ledger/${tenantDetailsArr[0]?.id}`);
                      }}
                      style={{ marginTop: "20px", cursor: "pointer" }}
                    >
                      Invoices
                    </h2>
                  </Col>
                  {/* <Col style={{ marginRight: "10px" }}>
                    <PostIncome
                      onClick={() => {
                        setModalState(true);
                        setIsEdit(false);
                      }}
                      bgColor={"#777777"}
                      fw={"400"}
                      displayView="none"
                    >
                      Create Invoice
                    </PostIncome>
                  </Col> */}
                </Row>
                <ApartmentTable>
                  <Table
                    columns={columnsForInvoice}
                    dataSource={[...shortedData]}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={pagination}
                    scroll={{ x: 800 }}
                    onRow={(r) => ({
                      onClick: () => {
                        tenantDetailsPage(r);
                      },
                    })}
                  />
                </ApartmentTable>
              </ProfileDetailsWrap>
            </Col>
          </Row>
          <AddTenantsModal
            onCancel={(value) => setEditModalState(value)}
            modalState={editModalState}
            isEdit={true}
            tenantDetails={tenantDetailsArr[0]}
            updatedTenat={updatedTenat}
            // newAddedVal={newAddedVal}
            // from={adminRoutes.TEAM}
          />
          <TenantsInvoicesModal
            onCancel={(value) => setModalState(value)}
            modalState={modalState}
            tenantDetails={tenantDetails}
            newInvoice={newInvoice}
            selectedInvoice={selectedInvoice}
            tenantFees={tenantFees}
            isEdit={isEdit}
            updatedData={updatedData}

            // newAddedVal={newAddedVal}
          />
        </Container>
      )}
    </>
  );
}

export default TenantDetails;

const NameDetailsWrap = styled.div`
  padding-top: 30px;
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #777777;
  }
  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #374049;
    padding-top: 10px;
  }
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #777777;
    padding-top: 30px;
    padding-bottom: 20px;
  }
`;

const MyAccountWrapRight = styled.div`
  padding: 20px 30px;
  width: 100%;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 0;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.third};
  }
  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    cursor: pointer;
  }
`;

const RightSideTDetails = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileDetailsWrap = styled.div`
  background: #ffffff;
  border-radius: 0 10px 10px 0;
  border-radius: ${({ br }) => br || "0 10px 10px 0"};
  padding: 30px 0 30px 30px;
  min-height: ${({ minH }) => minH || "calc(100vh - 145px)"};
  max-height: calc(100vh - 145px);
  overflow-y: scroll;

  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }

  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;
