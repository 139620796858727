import React, { useEffect, useState } from "react";
import {
  LoginMain,
  LoginWrap,
  CreateAccount,
  ManagePropertyBtn,
  LoginRight,
  StyledBg,
} from "./LoginStyle";
import { Row, Col, Form, Input, Divider } from "antd";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledButton,
} from "../createPersonalProfile/RegistrationStyle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AvenewLogo } from "../../assets/svgs";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { login } from "../../store/reducers/user.reducer";

function LoginForm() {
  var formRef = React.createRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invalidUserOrPass, setInvalidUserOrPass] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");

  const onFinish = async (value) => {
    value.email = value.email.toLowerCase();
    setEmailValidation("");
    setloadings(true);
    await dispatch(login(value)).then((res) => {
      setloadings(false);
      setEmailValidation(res.payload.error || res.payload.Error);
      setInvalidUserOrPass(res.payload.Error?.includes("password"));
    });
  };

  useEffect(() => {
    if (
      emailValidation?.length &&
      emailValidation?.includes("User with email")
    ) {
      formRef.current.setFields([
        {
          name: "email",
          errors: ["Email is not exist"],
        },
      ]);
    }
  }, [emailValidation, loadings]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  const { width } = useWindowSize();
  const location = useLocation();
  const [zoom, setZoom] = useState("100%");

  useEffect(() => {
    if (width < 1550 && width > 991) {
      setZoom("80%");
    } else {
      setZoom("100%");
    }
  }, [width, location.pathname]);

  return (
    <LoginMain>
      <LoginWrap>
        <Row justify="center">
          <Col
            xl={{ span: 12 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <AvenewLogo className="avenew__logo" />
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 12 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <h1 className="personal__profile">Sign in</h1>
          </Col>
        </Row>
        <StyledForm wh="50" smwh="93" xswh="80" xxswh="91">
          <StyledForms>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={"optional"}
              validateMessages={validateMessages}
              ref={formRef}
            >
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="password"
                  label="Password"
                  className="password-w-icon"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 8,
                      message: "password should contain minimum 8 characters",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>
              </StyledFormItem>
              {invalidUserOrPass ? (
                <p style={{ color: "#ff4d4f" }}>
                  * Incorrect Email or Password.
                </p>
              ) : (
                ""
              )}
              <Form.Item>
                <StyledButton htmlType="submit" loading={loadings}>
                  Sign In Now
                </StyledButton>
              </Form.Item>
            </Form>
            <CreateAccount>
              <p>Don't Have Account? </p>
              <Link to={"/registration"}>
                <h3> CREATE A NEW ACCOUNT</h3>
              </Link>
            </CreateAccount>
            {/* <Row justify="center" className="or__option">
              <Col xl={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 9 }}>
                <Divider />
              </Col>
              <Col>
                <p style={{ color: "#aaaaaa" }}>OR</p>
              </Col>
              <Col xl={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 9 }}>
                <Divider />
              </Col>
            </Row>
            <ManagePropertyBtn onClick={() => navigate("/join-company")}>
              <p>Want to manage existing property?</p>
              <h3>JOIN NOW {">"}</h3>
            </ManagePropertyBtn> */}
          </StyledForms>
        </StyledForm>
      </LoginWrap>
      {/* <LoginRight></LoginRight> */}
      <StyledBg></StyledBg>
    </LoginMain>
  );
}

export default LoginForm;
