import { Button } from "antd";
import styled from "styled-components";

export const StyledUploadPreviewWrapper = styled.div`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "flex")};
  flex-wrap: wrap;
  margin-top: 10px;

  .ant-image-mask {
    border-radius: 10px;
  }
  .ant-image {
    margin: 0 5px;
  }

  img {
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
  }

  @media (max-width: 575px) {
    .ant-image {
      width: 100% !important;
    }
    img {
      width: 100%;
    }
  }
`;
export const StyledUploadPreviewWrapperCover = styled.div`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "flex")};
  flex-wrap: wrap;
  margin-top: 10px;
  position: relative;
  max-height:95px;

  .ant-image-mask {
    border-radius: 10px;
  }

  img {
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
  }

  @media (max-width: 575px) {
    .ant-image {
      width: 100% !important;
    }
    img {
      width: 100%;
    }
  }
`;

export const StyledCover = styled.div`
  position: absolute;
  width: 95px;
  background: #f58341;
  border-radius: 0 0 10px 10px;
  bottom: 0;
  text-align: center;
  p {
    color: white;
    margin-bottom: 0;
  }
`;

export const UploadBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #777777;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
  height: 80px;

  @media (max-width: 1550px) {
    height: 65px;
  }
`;
export const UploadInput = styled.div`
  display: flex;
  height: 100%;
  background: #eeeeee;
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 15px;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #aaaaaa;
  }
  .upload__icon {
    color: ${({ theme }) => theme.colors.third};
  }
  .ant-upload-text {
    padding-right: 35px;
  }
  .ant-upload-drag-icon {
    padding-right: 5px;
    padding-top: 2px;
  }
`;

export const DropDownView = styled.div`
  margin-top: 5px;
  padding: 15px 20px;
  border-radius: 0 0 5px 5px;
  transform: rotate(360deg);
  transition: transform 0.5s;
  box-shadow: 0px 2px 5px 0px rgb(217 217 217);

  .ant-form-item input {
    position: fixed;
  }
  .ant-checkbox-wrapper {
    // width: 23%;
    color: ${({ theme }) => theme.colors.forth};
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    background-color: #f6f6f6;
    border: 1px solid #d9d9d9;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
`;

export const PropertyBtn = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  width: 100%;
  height: 50px;
  border-radius: ${({ br }) => br || "5px"};
  font-size: 18px;
  font-weight: 700;
  color: #777777;
  background: #eeeeee;
  cursor: pointer;
  align-items: center;
  padding: 0 11px;

  :hover,
  :focus {
    color: #777777;
    border-color: #eeeeee;
    background: #eeeeee;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.forth};
    line-height: 22px;
  }

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    position: fixed;
    right: 80px;
    color: ${({ theme }) => theme.colors.third};
  }
  @media (max-width: 1550px) {
    height: 40px;
  }
`;

export const ListWrap = styled.div`
  display: flex;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  align-items: center;
  margin-top: 10px;
  transition: transform 0.4s;

  :hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  }

  p {
    margin-bottom: 0;
    margin-right: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #484c4c;
  }

  svg {
    color: #aaaaaa;
    cursor: pointer;
    :hover {
      color: #000;
    }
  }
  ${
    "" /* .ant-list-bordered.ant-list-lg .ant-list-header,
  .ant-list-bordered.ant-list-lg .ant-list-footer {
    display: none;
  }

  .ant-list-bordered.ant-list-lg .ant-list-item {
    padding: 16px 24px;
    display: inline-block;
  } */
  }
`;
