import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isLoggedIn: false,
    companyDetails: null,
    value: "",
    openAccountTab: "",
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    onEdit: (state, action) => {
      state.value = action.payload;
    },
    openMyAccountTab: (state, action) => {
      state.openAccountTab = action.payload;
    },
  },
});

export const { login, logout, onEdit, openMyAccountTab } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
