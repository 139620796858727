import React from "react";

const homeSvg = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9072 9.3519C18.6976 9.91892 18.1783 10.2854 17.5836 10.2854H17.0998V17.3571C17.0998 17.712 16.8161 18 16.4665 18H12.6667V13.4998C12.6667 11.7274 11.2462 10.2854 9.50013 10.2854C7.75411 10.2854 6.33361 11.7274 6.33361 13.4998V18H2.53378C2.18419 18 1.90047 17.712 1.90047 17.3571V10.2854H1.41599C0.821321 10.2854 0.302011 9.91957 0.0923871 9.35254C-0.117237 8.78552 0.0366562 8.16192 0.485036 7.76461L7.88141 0.654297C8.78957 -0.218099 10.2113 -0.218099 11.1189 0.654297L18.533 7.78133C18.9623 8.16127 19.1169 8.78487 18.9072 9.3519Z"
        fill="#848687"
      />
    </svg>
  );
};

export default homeSvg;
