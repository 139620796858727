import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { CallSvg, MailSvg, NeedcallemailSvg } from "../../assets/svgs";
import { Container } from "../../ui/Layout/Container";
import { useWindowSize } from "../../utils/hooks/useWindowSize";

function CallOrEmail() {
  return (
    <CallOrEmailMainWrap>
      <CallOrEmailWrap>
        <Row justify="center">
          <Col className="contact__us">
            <NeedcallemailSvg />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <h2>Contact us directly</h2>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={24} md={22} lg={22} xl={18} xxl={17}>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </Col>
        </Row>
        <Row justify="center" gutter={[25, 25]}>
          <Col className="contact__number">
            <CallSvg />
            <h3>+1 123 456 7890</h3>
          </Col>
          <Col className="contact__number">
            <MailSvg />
            <h3>support@avenew.app</h3>
          </Col>
        </Row>
      </CallOrEmailWrap>
    </CallOrEmailMainWrap>
  );
}

export default CallOrEmail;

export const CallOrEmailMainWrap = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "160px 220px 160px 500px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "128px 176px 0 400px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
`;

export const CallOrEmailWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  ${"" /* padding: 50px 200px; */}
  padding: 50px 0;
  text-align: center;
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    color: #2b353f;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #aaaaaa;
  }
  .contact__number {
    display: flex;
    align-items: center;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #2b353f;
    margin-left: 15px;
    margin-bottom: 0;
  }

  .contact__us {
    svg {
      width: 266px;
      height: 201px;
    }
  }

  @media (max-width: 1550px) {
    padding: 40px 0;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;
      color: #2b353f;
    }
    p {
      font-size: 13px;
      line-height: 22px;
    }
    h3 {
      font-size: 14px;
      line-height: 22px;
      margin-left: 12px;
    }
    .contact__us {
      svg {
        width: 212px;
        height: 160px;
      }
    }
  }
  @media (max-width: 425px) {
    padding: 25px 15px;
  }
`;
