import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import MyAccountSidebar from "./MyAccountSidebar";

function MyAccountLayout({ isEdit, isEditTab, imgState }) {
  const [minHeight, setMinHeight] = useState("calc(100vh - 80px)");
  const { width } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    if (width < 1550 && width > 991) {
      setMinHeight("calc(100vh - 320px)");
    } else {
      setMinHeight("440px");
    }
  }, [width, location.pathname]);
  return (
    <MyAccountLayoutWrap minHeight={minHeight}>
      <MyAccountSidebar isEdit={isEdit} imgState={imgState} isEditTab={isEditTab} />
    </MyAccountLayoutWrap>
  );
}

export default MyAccountLayout;

export const MyAccountLayoutWrap = styled.div`
  background: #ffffff;
  min-height: 440px;
  border-radius: 0 0 5px 5px;

  @media (max-width: 1550px) {
    min-height: calc(100vh - 420px);
  }
`;
