import React from "react";
import DeleteAccountSvg from "../../assets/svgs/deleteAccount";
import { Radio, Space, Button, Row, Col } from "antd";
import styled from "styled-components";
import { StyledButton } from "../createPersonalProfile/RegistrationStyle";

function DeleteAccount() {
  return (
    <>
      <DeleteWrap>
        <Row gutter={[10, 10]} justify="center">
          <Col
            xl={{ span: 4 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 22 }}
            xs={{ span: 24 }}
            className="delete__svg"
          >
            <DeleteAccountSvg />
          </Col>
        </Row>
        <Row gutter={[10, 10]} justify="center">
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 22 }}
            xs={{ span: 24 }}
          >
            <h1 className="heading__delete">
              Deactivate or delete your account
            </h1>
          </Col>
        </Row>
        <Row gutter={[10, 10]} justify="center">
          <Col
            xxl={{ span: 14 }}
            xl={{ span: 14 }}
            lg={{ span: 20 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 24 }}
          >
            <Row justify="center">
              <Col>
                <Radio.Group>
                  <Radio className="radio__button" value={1}>
                    Deactivate your account
                  </Radio>
                  <Radio className="radio__button" value={2}>
                    Delete your account
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col
            xl={{ span: 6 }}
            lg={{ span: 6 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
            xs={{ span: 24 }}
          >
            <StyledButton>Continue</StyledButton>
          </Col>
        </Row>
      </DeleteWrap>
    </>
  );
}

export default DeleteAccount;

export const DeleteWrap = styled.div`
  .heading__delete {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: ${({ theme }) => theme.colors.forth};
    margin-left: -35px;
    margin-bottom: 15px;
  }
  svg {
    margin-left: -45px;
  }

  @media (max-width: 450px) {
    .heading__delete {
      margin-left: 0;
    }
    svg {
      margin-left: 0px;
    }
  }
  .ant-radio-group {
    width: 100%;
  }
  span.ant-radio + * {
    padding-left: 20px;
  }
  .delete__svg {
    text-align: center;
  }

  .radio__button {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin: 10px 0;
    align-items: center;
    padding-left: 15px;
    .ant-radio {
      margin-bottom: 5px;
    }
  }

  .ant-radio-inner {
    background-color: #d9d9d9;
  }
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #777777;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #777777;
  }

  .continue__button {
    cursor: pointer;
    background: #4160ac;
    border-radius: 5px;
    width: 177px;
    height: 50px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;

    :hover,
    :focus {
      color: #ffffff;
      border-color: ${({ theme }) => theme.colors.third}90;
      background: #767bcc;
    }
  }

  @media (max-width: 1550px) {
    .heading__delete {
      font-size: 20px;
      line-height: 28px;
      margin-left: 0;
      margin-bottom: 12px;
    }
    svg {
      margin-left: -36px;
      width: 60px;
      height: 65px;
    }
    span.ant-radio + * {
      padding-left: 16px;
    }
    .radio__button {
      height: 48px;
      font-size: 16px;
      line-height: 20px;
      margin: 8px 0;
      padding-left: 12px;
      .ant-radio {
        margin-bottom: 4px;
      }
    }
  }
  @media (max-width: 991.98px) {
    svg {
      margin-left: 0px;
    }
  }
`;
