import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { me } from "../../store/reducers/user.reducer";

function PersonalDetails() {
  // const { FirstName, LastName, PhoneNumber, Email } = useSelector(
  //   (state) => state.personalDetails.userPersonalDetails
  // );

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(me());
  }, [dispatch]);

  return (
    <PersonalDetailsWrap>
      <Row gutter={[15, 15]} justify="space-between">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>First Name</span>
          <h2>
            {user?.firstName.charAt(0).toUpperCase() + user?.firstName.slice(1)}
          </h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Last Name</span>
          <h2>
            {user?.lastName.charAt(0).toUpperCase() + user?.lastName.slice(1)}
          </h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Contact Number</span>
          <h2>{user?.phonenumber}</h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Email</span>
          <h2>{user?.email}</h2>
        </Col>
      </Row>
    </PersonalDetailsWrap>
  );
}

export default PersonalDetails;

export const PersonalDetailsWrap = styled.div`
  ${"" /* padding: 0 60px; */}
  @media (max-width: 1441px) {
    padding: 0;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #aaaaaa;
  }
  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.forth};
  }
  @media (max-width: 1550px) {
    padding: 0;
    span {
      font-size: 12px;
      line-height: 13px;
    }
    h2 {
      font-size: 16px;
      line-height: 19px;
    }
  }
  @media (max-width: 991.98px) {
    padding: 0 20px;
  }
  @media (max-width: 420px) {
    padding: 0 10px;
  }
`;
