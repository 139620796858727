export const apartmentDummyData = [
  {
    _id: 1,
    title: "Apartment Name Here",
    bhk: "1 BHK - 2 BHK - 3 BHK",
    bedroom: "1 Bedroom - 3 Bedroom",
    minPrice: "1598.56",
    maxPrice: "2256.00",
    img: "/images/apartment1.png",
  },
  {
    _id: 2,
    title: "Apartment 2",
    bhk: "1 BHK - 2 BHK - 3 BHK",
    bedroom: "1 Bedroom - 3 Bedroom",
    minPrice: "1598.56",
    maxPrice: "2256.00",
    img: "/images/apartment2.png",
  },
  {
    _id: 3,
    title: "Apartment 003",
    bhk: "1 BHK - 2 BHK - 3 BHK",
    bedroom: "1 Bedroom - 3 Bedroom",
    minPrice: "1598.56",
    maxPrice: "2256.00",
    img: "/images/apartment3.png",
  },
  {
    _id: 4,
    title: "Apartment 2",
    bhk: "1 BHK - 2 BHK - 3 BHK",
    bedroom: "1 Bedroom - 3 Bedroom",
    minPrice: "1598.56",
    maxPrice: "2256.00",
    img: "/images/apartment2.png",
  },
  {
    _id: 5,
    title: "Apartment 003",
    bhk: "1 BHK - 2 BHK - 3 BHK",
    bedroom: "1 Bedroom - 3 Bedroom",
    minPrice: "1598.56",
    maxPrice: "2256.00",
    img: "/images/apartment3.png",
  },
  {
    _id: 6,
    title: "Apartment Name Here",
    bhk: "1 BHK - 2 BHK - 3 BHK",
    bedroom: "1 Bedroom - 3 Bedroom",
    minPrice: "1598.56",
    maxPrice: "2256.00",
    img: "/images/apartment1.png",
  },
];
