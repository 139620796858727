import React from "react";
import {
  JoinCompanyMain,
  JoinCompanyLeft,
  JoinCompanyRight,
} from "./JoinCompanyStyle";
import { Row, Col, Form, Input, Divider } from "antd";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../createPersonalProfile/RegistrationStyle";

function JoinCompany() {
  const onFinish = (value) => {
    console.log(value);
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };
  return (
    <JoinCompanyMain>
      <JoinCompanyLeft>
        <Row justify="center">
          <Col
            xl={{ span: 14 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <h2 className="avenew__signup">Avenew </h2>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 14 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <h1 className="personal__profile">Join Company</h1>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 14 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <p className="company__details">COMPANY DETAILS</p>
          </Col>
        </Row>
        {/* For search OR Send request  */}
        <StyledForm wh="60" smwh="93" xswh="80" xxswh="91">
          <StyledForms>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={"optional"}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="search-property"
                  label="Search value"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Search by property name, ID, Address..." />
                </Form.Item>
              </StyledFormItem>
              <Form.Item>
                <StyledButton htmlType="submit">SEND REQUEST</StyledButton>
              </Form.Item>
            </Form>
            <Row justify="center" className="or__option">
              <Col span={10}>
                <Divider />
              </Col>
              <Col>
                <p style={{ color: "#aaaaaa" }}>OR</p>
              </Col>
              <Col span={10}>
                <Divider />
              </Col>
            </Row>
          </StyledForms>
        </StyledForm>

        {/* For join company */}
        <StyledForm wh="60" smwh="93" xswh="80" xxswh="91">
          <StyledForms>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={"optional"}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="property-name"
                  label="Property Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Property Name" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="property-id"
                  label="Property ID"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Property ID" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="full-name"
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <StyledButton htmlType="submit">Join Now</StyledButton>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </JoinCompanyLeft>
      <JoinCompanyRight></JoinCompanyRight>
    </JoinCompanyMain>
  );
}

export default JoinCompany;
