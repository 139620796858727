import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledTitle,
} from "../../components/createPersonalProfile/RegistrationStyle";
import moment from "moment";
import styled from "styled-components";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  createInvoice,
  updateInvoice,
} from "../../store/reducers/user.reducer";
import { FaAddressBook } from "react-icons/fa";
import { BsCurrencyDollar } from "react-icons/bs";

function TenantsInvoicesModal({
  modalState,
  onCancel,
  tenantDetails,
  newInvoice,
  selectedInvoice = {},
  tenantFees = {},
  updatedData = console.log,
  isEdit = false,
}) {
  const today = new Date();
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { Option } = Select;
  const currentDayTime = new Date();
  const [size, setSize] = useState("%");
  const [lenOfCharge, setLenOfCharge] = useState(1);
  const [amountValueChange, setAmountValueChange] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedAutoInvoice, setCheckedAutoInvoice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numOffee, setNumOffee] = useState([]);
  const [totalField, setTotalField] = useState([]);
  const [newSetalFields, setNewSetalFields] = useState([
    { type: null, amount: null, memo: null },
  ]);
  const [finalTotalField, setFinalTotalField] = useState([]);
  const [taxField, setTaxField] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [payableAmount, setpayableAmount] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        invoiceCreatedDate: moment(selectedInvoice?.invoiceCreatedDate),
        dueDate: moment(selectedInvoice?.dueDate),
      });
      const newArr = [...selectedInvoice?.fees];
      setNewSetalFields(newArr);
    } else {
      form.setFieldsValue({
        invoiceCreatedDate: moment(today),
        dueDate: null,
      });
      setNewSetalFields([{ type: null, amount: null, memo: null }]);
    }
  }, [isEdit, form, selectedInvoice]);

  useEffect(() => {
    if (isEdit) {
      newSetalFields.forEach((val) => {
        if (val.type === "taxFee") {
          setChecked(true);
        }
      });
    } else {
      // setChecked(false);
    }
  }, [isEdit, newSetalFields]);

  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      const arr = [];
      arr.push({
        type: "taxFee",
        amount: tenantFees?.fees ? tenantFees?.fees["taxFee"] : null,
        memo: null,
        dollerOrPercentage: size,
      });
      setNewSetalFields((val) => [...val, ...arr]);
    } else {
      const arr = [];

      newSetalFields.map((val) => {
        if (val.type === "taxFee") {
        } else {
          arr.push(val);
        }
      });
      setNewSetalFields(arr);
    }
  };

  const autoInvoiceCheck = (e) => {
    setCheckedAutoInvoice(e.target.checked);
  };

  // useEffect(() => {
  //   if (!isEdit) {
  //     form.setFieldsValue({
  //       invoiceCreatedDate: moment(today),
  //     });
  //   } else {
  //     form.setFieldsValue({
  //       invoiceCreatedDate: null,
  //     });
  //   }
  // }, [form, isEdit]);

  useEffect(() => {
    const fees = ["rate", "lateFee", "parkingFee", "avenewFees"];
    const arr = [];
    if (tenantDetails) {
      Object.keys(tenantDetails)?.forEach((val) => {
        if (fees.includes(val)) {
          arr.push({ type: val, amount: tenantDetails[val], memo: null });
        }
      });
    }
    if (Object.values(tenantFees)?.length) {
      if (tenantFees?.fees) {
        Object.keys(tenantFees?.fees)?.forEach((val) => {
          if (fees.includes(val)) {
            arr.push({ type: val, amount: tenantFees.fees[val], memo: null });
          } else if (val === "taxFee") {
            arr.push({
              type: val,
              amount: tenantFees.fees[val],
              memo: null,
              dollerOrPercentage: size,
            });
          }
        });
      }
    }
    setTotalField(arr);
  }, [tenantDetails, isEdit, tenantFees]);

  useEffect(() => {
    if (newSetalFields?.length) {
      let sum = 0;
      newSetalFields.map((val, id) => {
        if (val.type != "taxFee") {
          sum += Number(val["amount"]);
        }
        if (val.type === "taxFee") {
          setTaxField({ ...val, id: id });
        }
      });
      setTotalAmount(sum);
    }
  }, [newSetalFields]);

  // useEffect(() => {
  //   if (totalField?.length) {
  //     let sum = 0;
  //     totalField.map((val, id) => {
  //       if (val.type != "taxFee") {
  //         sum += Number(val["amount"]);
  //       }
  //       if (val.type === "taxFee") {
  //         setTaxField({ ...val, id: id });
  //       }
  //     });
  //     setTotalAmount(sum);
  //   }
  // }, [totalField]);

  const selectChange = (value, amount, id) => {
    // let changedObj = totalField[id];
    // changedObj["type"] = value;
    // totalField[id] = changedObj;
    // setTotalField(totalField);
    // afterChangeInvoice(totalField);
  };

  const onOptionChange = (type, amount, id) => {
    if (isEdit) {
      let editObj = [...newSetalFields];
      let changedObj = editObj[id];
      let eValue = { ...changedObj };
      eValue["type"] = type;
      totalField.forEach((val) => {
        if (val.type === type) {
          eValue["amount"] = val.amount;
        }
      });
      editObj[id] = eValue;
      setNewSetalFields(editObj);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(editObj);
    } else {
      let changedObj = newSetalFields[id];
      changedObj["type"] = type;
      totalField.forEach((val) => {
        if (val.type === type) {
          changedObj["amount"] = val.amount;
        }
      });
      newSetalFields[id] = changedObj;
      setNewSetalFields(newSetalFields);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(newSetalFields);
    }
  };

  const onOptionChangeInput = (amount, type, id, dollerOrPercentage) => {
    setAmountValueChange(amount);
    // let changedObj = totalField[id];
    // changedObj["amount"] = amount;
    // totalField[id] = changedObj;
    // setFinalTotalField(totalField);
    // setTotalField(totalField);
    // afterChangeInvoice(totalField);
    if (isEdit) {
      let editObj = [...newSetalFields];
      let changedObj = editObj[id];
      let eValue = { ...changedObj };
      eValue["amount"] = amount;
      editObj[id] = eValue;
      setNewSetalFields(editObj);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(editObj);
    } else {
      let changedObj = newSetalFields[id];
      changedObj["amount"] = amount;
      newSetalFields[id] = changedObj;
      setNewSetalFields(newSetalFields);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(newSetalFields);
    }
  };

  const inputChange = (amount, type, id, dollerOrPercentage) => {
    // let changedObj = totalField[id];
    // changedObj["amount"] = amount;
    // totalField[id] = changedObj;
    // setFinalTotalField(totalField);
    // setTotalField(totalField);
    // afterChangeInvoice(totalField);
    if (isEdit) {
      let editObj = [...newSetalFields];
      let changedObj = editObj[id];
      let eValue = { ...changedObj };
      eValue["amount"] = amount;
      editObj[id] = eValue;
      setNewSetalFields(editObj);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(editObj);
    } else {
      let changedObj = newSetalFields[id];
      changedObj["amount"] = amount;
      newSetalFields[id] = changedObj;
      setNewSetalFields(newSetalFields);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(newSetalFields);
    }
  };

  const inputMemoChange = (memo, type, id) => {
    // let changedObj = totalField[id];
    // changedObj["memo"] = memo;
    // totalField[id] = changedObj;
    // setFinalTotalField(totalField);
    // setTotalField(totalField);
    // afterChangeInvoice(totalField);
    if (isEdit) {
      let editObj = [...newSetalFields];
      let changedObj = editObj[id];
      let eValue = { ...changedObj };
      eValue["memo"] = memo;
      editObj[id] = eValue;
      setNewSetalFields(editObj);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(editObj);
    } else {
      let changedObj = newSetalFields[id];
      changedObj["memo"] = memo;
      newSetalFields[id] = changedObj;
      setNewSetalFields(newSetalFields);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(newSetalFields);
    }
  };

  const amountValueChanger = (value, type, id) => {
    // let changedObj = totalField[id];
    // changedObj["dollerOrPercentage"] = value;
    // totalField[id] = changedObj;
    // setFinalTotalField(totalField);
    // setTotalField(totalField);
    // afterChangeInvoice(totalField);

    if (isEdit) {
      let editObj = [...newSetalFields];
      let changedObj = editObj[id];
      let eValue = { ...changedObj };
      eValue["dollerOrPercentage"] = value;
      editObj[id] = eValue;
      setNewSetalFields(editObj);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(editObj);
    } else {
      let changedObj = newSetalFields[id];
      changedObj["dollerOrPercentage"] = value;
      newSetalFields[id] = changedObj;
      setNewSetalFields(newSetalFields);
      setNewSetalFields((val) => [...val]);
      afterChangeInvoice(newSetalFields);
    }
  };

  const addChanges = () => {
    newSetalFields.push({ type: null, amount: null, memo: null });
    setLenOfCharge(lenOfCharge + 1);
  };

  useEffect(() => {
    const arr = [];
    for (let i = 0; i < lenOfCharge; i++) {
      arr.push(i);
    }
    setNumOffee(arr);
  }, [lenOfCharge]);

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  const afterChangeInvoice = (data) => {
    let sum = 0;
    if (data?.length) {
      data.map((val) => {
        if (val.type != "taxFee") {
          sum += Number(val["amount"]);
        } else {
          if (val.dollerOrPercentage === "$") {
            const finalAmount = totalAmount + Number(val.amount);
            setpayableAmount(finalAmount);
          } else {
            const finalAmount = percentageRemaning(
              totalAmount,
              Number(val.amount)
            );
            setpayableAmount(finalAmount);
          }
        }
      });
    }
    setTotalAmount(sum);
  };

  function percentageRemaning(num, per) {
    return (num / 100) * per + num;
  }

  // useEffect(() => {
  //   if (totalField?.length) {
  //     totalField.map((val) => {
  //       if (val.type === "taxFee") {
  //         if (val.dollerOrPercentage === "$") {
  //           const finalAmount = totalAmount + Number(val.amount);
  //           setpayableAmount(finalAmount);
  //         } else {
  //           const finalAmount = percentageRemaning(
  //             totalAmount,
  //             Number(val.amount)
  //           );
  //           setpayableAmount(finalAmount);
  //         }
  //       }
  //     });
  //   }
  // }, [totalField, size, totalAmount]);

  useEffect(() => {
    if (newSetalFields?.length) {
      newSetalFields.map((val) => {
        if (val.type === "taxFee") {
          if (val.dollerOrPercentage === "$") {
            const finalAmount = totalAmount + Number(val.amount);
            setpayableAmount(finalAmount);
          } else {
            const finalAmount = percentageRemaning(
              totalAmount,
              Number(val.amount)
            );
            setpayableAmount(finalAmount);
          }
        }
      });
    }
  }, [newSetalFields, size, totalAmount]);

  const onFinish = (value) => {
    if (params.id) {
      value.tenant_id = params.id;
      value.fees = newSetalFields;
      value.payableAmount = checked ? payableAmount : totalAmount;
      value.org_id = tenantDetails?.org_id;
      value.apartment_id = tenantDetails?.apartment_id;
      value.unit_id = tenantDetails?.unit_id;
      value.firstName = tenantDetails?.firstName;
      value.lastName = tenantDetails?.lastName;
      value.unit_name = tenantDetails?.unit_name;
      value.apartment_name = tenantDetails?.apartment_name;
      value.phoneNumber = tenantDetails?.phoneNumber;

      if (isEdit) {
        setIsLoading(true);
        value.currentDayTime = selectedInvoice?.currentDayTime;
        value.id = selectedInvoice.id;
        value.payments = selectedInvoice?.payments;
        value.invoiceCode = selectedInvoice?.invoiceCode;
        value.invoiceClose = selectedInvoice?.invoiceClose;
        value.autoInvoice = selectedInvoice?.autoInvoice;
        value.leaseTerm = selectedInvoice?.leaseTerm;
        // value.balance = selectedInvoice?.balance;
        value.balance = checked
          ? payableAmount
          : totalAmount -
            selectedInvoice?.payableAmount +
            selectedInvoice?.balance;
        dispatch(updateInvoice(value)).then(({ payload }) => {
          updatedData(payload);
          setIsLoading(false);
          onCancel(false);
          message.success("Invoice Updated Successfully");
        });
      } else {
        value.invoiceClose = false;
        value.leaseTerm = tenantDetails.leaseTerm;
        if (checkedAutoInvoice) {
          value.autoInvoice = true;
          const totalTerm = Number(tenantDetails.leaseTerm.replace(/\D/g, ""));
          for (let i = 0; i < totalTerm; i++) {
            const arrValue = { ...value };
            arrValue.currentDayTime = timeConverter(currentDayTime, i * 2000);
            arrValue.dueDate = dateConverter(value.dueDate, i);
            setIsLoading(true);
            dispatch(createInvoice(arrValue)).then(({ payload }) => {
              setIsLoading(false);
              onCancel(false);
              newInvoice(payload);
            });
          }
        } else {
          setIsLoading(true);
          value.currentDayTime = currentDayTime;
          value.autoInvoice = false;

          dispatch(createInvoice(value)).then(({ payload }) => {
            setIsLoading(false);
            onCancel(false);
            newInvoice(payload);
          });
        }
      }
    }
  };

  const timeConverter = (dateValue, month) => {
    var date = new Date(dateValue);
    var newDate = new Date(date.setTime(date.getTime() + month));

    return newDate;
  };

  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);
    var newDate = new Date(date.setMonth(date.getMonth() + month));

    return moment(newDate);
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
        // if (!isFormChange) {
        //   onCancel(false);
        // } else {
        //   openPrompt(
        //     "You will lose all changes if you navigate away from this page without saving"
        //   );
        // }
      }}
      width={730}
      footer={null}
      style={{ borderRadius: "5px", top: "50px" }}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <TenantsInvoicesWrap>
        <StyledForm wh={"100"} smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <StyledTitle>
              {isEdit ? "Update Invoice" : "Create Invoice"}
            </StyledTitle>

            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="49" smwh="100">
                <Form.Item
                  rules={[{ required: true }]}
                  name="invoiceCreatedDate"
                  label="Invoice Date"
                >
                  <DatePicker
                    allowClear={false}
                    defaultValue={moment(today)}
                    format="MMM DD, YYYY"
                    onChange={(date) => setSelectedDate(date)}
                    // disabled
                  />
                </Form.Item>
                <Form.Item
                  label="Due Date"
                  name="dueDate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    // defaultValue={moment(today)}
                    format="MMM DD, YYYY"
                    onChange={(date) => setSelectedDate(date)}
                    // disabled
                  />
                </Form.Item>
              </StyledFormItem>
            </Form>

            <p className="charges__class">Charges</p>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              {newSetalFields.map((val, id) => (
                <StyledFormItem key={id} wh="32" fd={"column"} smwh={"100"}>
                  {val.type === "taxFee" ? (
                    " "
                  ) : (
                    <>
                      <Form.Item>
                        <Select
                          defaultValue={val.type}
                          placeholder="Type"
                          allowClear
                          suffixIcon={<IoIosArrowDown />}
                          onChange={(value) => {
                            selectChange(value, val.amount, id);
                            onOptionChange(value, val.amount, id);
                          }}
                          // disabled={selectedApartment}
                        >
                          <Option value="rate">Rent</Option>
                          <Option value="lateFee">Late Fee</Option>
                          <Option value="parkingFee">Parking Fee</Option>
                          <Option value="avenewFees">Avenew Fee</Option>
                          <Option value="otherFee">Other Fee</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item className="input__affix">
                        <Input
                          // defaultValue={val.amount}
                          value={val.amount}
                          type="number"
                          step="any"
                          placeholder="Amount"
                          prefix={
                            <BsCurrencyDollar className="site-form-item-icon" />
                          }
                          onChange={(e) => {
                            // inputChange(e.target.value, val.type, id)
                            onOptionChangeInput(e.target.value, val.type, id);
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input
                          value={val.memo}
                          placeholder="Write Memo"
                          onChange={(e) =>
                            inputMemoChange(e.target.value, val.type, id)
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                </StyledFormItem>
              ))}

              <p
                className="new__changes"
                onClick={() => {
                  addChanges();
                  // setNewChange(false);
                }}
              >
                {/* <FiPlus /> */}+ New Charge
              </p>

              <Checkbox
                checked={checked}
                onChange={onChange}
                style={{ marginBottom: "20px" }}
              >
                Want to include tax?
              </Checkbox>
              {checked ? (
                <StyledFormItem
                  wh="100"
                  smwh="100"
                  radioWidth="35px"
                  radioHeight="35px"
                >
                  <Form.Item>
                    <Input
                      // defaultValue={taxField.amount}
                      value={taxField.amount}
                      placeholder="Tax"
                      type="number"
                      step="any"
                      onChange={(e) =>
                        inputChange(
                          e.target.value,
                          taxField.type,
                          taxField.id,
                          size
                        )
                      }
                      addonAfter={
                        <>
                          <Radio.Group
                            value={size}
                            onChange={(e) => {
                              setSize(e.target.value);
                              amountValueChanger(
                                e.target.value,
                                taxField.type,
                                taxField.id
                              );
                            }}
                          >
                            <Radio.Button value="$">$</Radio.Button>
                            <Radio.Button value="%">%</Radio.Button>
                          </Radio.Group>
                        </>
                      }
                    />
                  </Form.Item>
                </StyledFormItem>
              ) : (
                ""
              )}
              {tenantDetails?.leaseTerm ? (
                <Row>
                  <TotalAmount>
                    <Row justify="space-between">
                      <Col>
                        <span>Total Amount: </span>
                      </Col>
                      <Col>
                        {" "}
                        <p>
                          &nbsp;{" "}
                          {toUSACurrency(checked ? payableAmount : totalAmount)}
                        </p>
                      </Col>
                    </Row>
                  </TotalAmount>
                </Row>
              ) : (
                ""
              )}
              <Row justify="space-between" style={{ alignItems: "center" }}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Col>
                    <Checkbox
                      checked={checkedAutoInvoice}
                      onChange={autoInvoiceCheck}
                      style={{ marginBottom: "20px" }}
                    >
                      <p className="auto__invoice">
                        Auto Invoice Generate for {tenantDetails?.leaseTerm}
                      </p>
                    </Checkbox>
                  </Col>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item>
                    <StyledButton loading={isLoading} htmlType="submit">
                      {isEdit ? "Update Invoice" : "Create Invoice"}
                    </StyledButton>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </StyledForms>
        </StyledForm>
      </TenantsInvoicesWrap>
    </Modal>
  );
}

export default TenantsInvoicesModal;

export const TotalAmount = styled.div`
  width: 100%;
  margin-bottom: 15px;

  span,
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #777777;
  }
  p {
    color: #2b353f;
    font-weight: 600;
  }
`;

const TenantsInvoicesWrap = styled.div`
  .auto__invoice {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #f67c36;
    cursor: pointer;
  }
  .input__affix .ant-input-affix-wrapper {
    min-height: auto;
    background: #eeeeee;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0;
  }
  .input__affix input {
    border-left: none;
  }
  .ant-input-prefix {
    margin-left: 4px;
  }

  .ant-checkbox-inner {
    border-radius: 3px;
  }
  .ant-form label {
    font-size: 14px;
    font-weight: 400;
    font-size: 16px;
    ${"" /* line-height: 19px; */}
    color: #000000;
  }
  .new__changes {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #f67c36;
    cursor: pointer;
    display: flex;
    justify-content: end;
  }
  .charges__class {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;
