import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AvenewLogo } from "../../assets/svgs";
import { UserAvatar } from "../../ui/Avatar";
import { Container } from "../../ui/Layout/Container";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import Header from "../layout/header/Header";
import HeaderNotification from "../layout/header/HeaderNotification";
import {
  StyledHeaderDropdownContentWrappeer,
  StyledHeaderDropdownMenuItem,
  StyledHeaderTop,
  StyledLeftSideMenu,
  StyledRightSideMenu,
} from "../layout/header/TheHeader.styles";
import UserSettingsModal from "../userSettings/UserSettingsModal";
import FaqsLayout from "./FaqsLayout";

function Faqs() {
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [headerHeight, setHeaderHeight] = useState("80px");
  const { width } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    if (width < 1550) {
      setHeaderHeight("65px");
    } else if (width > 991) {
      setHeaderHeight("80px");
    }
  }, [width, location.pathname]);

  const handleLogout = () => {
    console.log("Logout");
  };

  const menu = (
    <StyledHeaderDropdownContentWrappeer>
      <StyledHeaderDropdownMenuItem key="0">
        <Link to="/my-account" className="nav-link">
          My Account
        </Link>
      </StyledHeaderDropdownMenuItem>
      <StyledHeaderDropdownMenuItem key="1" onClick={() => setModalState(true)}>
        <p className="nav-link">Invite User</p>
      </StyledHeaderDropdownMenuItem>

      <StyledHeaderDropdownMenuItem key="2">
        <Link to="/login" onClick={handleLogout} className="nav-link">
          Logout
        </Link>
      </StyledHeaderDropdownMenuItem>
    </StyledHeaderDropdownContentWrappeer>
  );
  return (
    <>
      <StyledHeaderTop className="sticky-header">
        <StyledLeftSideMenu>
          <Link to="/" className="nav-link">
            <AvenewLogo className="avenew__title" />
          </Link>
        </StyledLeftSideMenu>
        <StyledRightSideMenu>
          <HeaderNotification />
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="header_dropdown"
          >
            <UserAvatar
              fullName={`Umesh avaiya`}
              src="images/User.png"
              style={{ marginLeft: "10px" }}
              size={40}
            />
          </Dropdown>
        </StyledRightSideMenu>
      </StyledHeaderTop>
      <UserSettingsModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
      <FaqsWrap style={{ paddingTop: headerHeight }}>
        <Container padding="0 160px" paddingSm="0 100px">
          <Link to="/">
            <BackToDash>
              <AiOutlineLeft />
              <p>Back to Dashboard</p>
            </BackToDash>
          </Link>
          <FaqsLayout />
        </Container>
      </FaqsWrap>
    </>
  );
}

export default Faqs;

export const FaqsWrap = styled.div`
  a {
    display: inline-block;
  }
`;

export const BackToDash = styled.div`
  display: flex;
  align-items: center;
  padding: 22px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #aaaaaa;
  p {
    margin-bottom: 0;
    margin-left: 18px;
  }
`;
