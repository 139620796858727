import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Skeleton,
  Divider,
  Dropdown,
  Button,
  Menu,
  Space,
} from "antd";
import {
  ShareDropdown,
  ShareDropdownMenuItem,
  StyledApartCardWrapper,
  StyledApparCard,
} from "./Listingstyle";
import { Link, useLocation } from "react-router-dom";
import { MdShare } from "react-icons/md";
import { FaGreaterThan } from "react-icons/fa";
import { StyledHeaderDropdownContentWrappeer } from "../layout/header/TheHeader.styles";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { AvenewImgSvg } from "../../assets/svgs";

function ApartmentSection({ apartmentsWithUnits }) {
  const routeApart = "/subunit/:id";
  const buttonRef = useRef(null);

  const getBed = (valueObj) => {
    const value = [valueObj];
    var a = [];

    value?.map(function ({ children }) {
      if (children?.length) {
        a.push(...children);
      }
    });

    var b = [];
    a?.map((value) => b.push(value.bed));
    function removeNull(array) {
      return array.filter((x) => x !== undefined);
    }

    const max = Math.max(...removeNull(b));
    const min = Math.min(...removeNull(b));

    if (min === Infinity || max === Infinity) {
      return <span>0 Bedroom</span>;
    } else {
      return (
        <span>
          {min} Bedroom - {max} Bedroom
        </span>
      );
    }
  };

  // useEffect(() => {
  //   apartmentsWithUnits.map((value) => {
  //     getBed(value);
  //   });
  // }, []);

  const shareHandler = (e) => {
    e.preventDefault();
  };

  const menu = (
    <ShareDropdown>
      <ShareDropdownMenuItem>
        <Link to="/listings">Share</Link>
      </ShareDropdownMenuItem>
    </ShareDropdown>
  );

  const { width } = useWindowSize();
  const location = useLocation();
  const [zoom, setZoom] = useState("100%");

  useEffect(() => {
    if (width < 1550 && width > 991) {
      setZoom("80%");
    } else {
      setZoom("100%");
    }
  }, [width, location.pathname]);

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  return (
    <StyledApartCardWrapper>
      <Row gutter={[15, 15]}>
        {apartmentsWithUnits?.map((items, _id) => {
          let coverImg = items.images?.filter(
            (item, key) => item.isCover == true
          );
          return (
            <Col key={_id} xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
              {
                <Link to={routeApart.replace(":id", items.id)}>
                  <StyledApparCard hoverable>
                    {/* <Skeleton.Image active={false} style={{ width: "100%" }}> */}
                    {/* <AvenewImgSvg
                    style={{
                      fontSize: 40,
                      color: "#bfbfbf",
                      width: "100%",
                    }}
                  /> */}
                    {coverImg?.length ? (
                      <img alt="example" src={coverImg[0]?.path} />
                    ) : (
                      <Skeleton.Image
                        active={false}
                        style={{ width: "100%" }}
                      />
                    )}

                    {/* </Skeleton.Image> */}
                    {/* {items?.images?.length ? (
                      <>
                        {items?.images.map((item) => {
                          if (item.isCover === true) {
                            return <img alt="example" src={coverImg[0].url} />;
                          } else {
                          }
                        })}
                      </>
                    ) : (
                      <img alt="example" src="/images/apartment1.png" />
                    )} */}
                    {/* <img alt="example" src="/images/apartment1.png" /> */}

                    <Col className="card__title">
                      <p>{upperCase(items.unit_name)}</p>

                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottom"
                        className=""
                        arrow
                      >
                        <MdShare onClick={(e) => shareHandler(e)} />
                      </Dropdown>
                    </Col>
                    <Col className="card__BHK">
                      <span>1 BHK - 2 BHK - 3 BHK</span>
                      <Divider type="vertical" />
                      {getBed(items)}
                    </Col>
                    <Col className="card__price">
                      <p>$1598.65 - $1987.85</p>
                      {/* <div>
                      <span>EDIT</span>
                      <Divider type="vertical" />
                      <span>
                        VIEW <FaGreaterThan />
                      </span>
                    </div> */}
                    </Col>
                  </StyledApparCard>
                </Link>
              }
            </Col>
          );
        })}
      </Row>
    </StyledApartCardWrapper>
  );
}

export default ApartmentSection;
