import React from "react";

const mailSvg = ({
  wSize = "30",
  hSize = "20",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V20H30V0H0ZM15 10.9297L2.28125 1.25H27.7188L15 10.9297ZM1.25 18.75V2.03906L10.2422 8.88281L4.92188 14.9219L5.07812 15.0781L11.2422 9.64062L15 12.5L18.7578 9.64062L24.9219 15.0781L25.0781 14.9219L19.7578 8.875L28.75 2.03906V18.75H1.25Z"
        fill="#F67C36"
      />
    </svg>
  );
};

export default mailSvg;
