import React, { useEffect, useState } from "react";
import { Avatar, Dropdown } from "antd";
import {
  StyledHeaderTop,
  StyledLeftSideMenu,
  StyledRightSideMenu,
  StyledHeaderDropdownContentWrappeer,
  StyledHeaderDropdownMenuItem,
} from "./TheHeader.styles";
import { CgMenu } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import HeaderNotification from "./HeaderNotification";
import UserSettingsModal from "../../userSettings/UserSettingsModal";
import { AvenewLogo } from "../../../assets/svgs";
import { UserAvatar } from "../../../ui/Avatar";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { logout, me } from "../../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";

function Header() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const { width } = useWindowSize();
  const location = useLocation();
  const [openSideBar, setopenSider] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);

  const openSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSider");
    if (x.length > 0) {
      document.body.classList.remove("openSider");
      setopenSider(true);
    } else {
      document.body.classList.add("openSider");
      setopenSider(false);
    }
  };
  const handleLogout = () => {
    dispatch(logout());
  };

  const menu = (
    <StyledHeaderDropdownContentWrappeer>
      <StyledHeaderDropdownMenuItem key="0">
        {/* <StyledHeaderDropdownMenuItem key="0" icon={<FaUser />}> */}
        <Link to="/my-account" className="nav-link">
          My Account
        </Link>
      </StyledHeaderDropdownMenuItem>
      <StyledHeaderDropdownMenuItem key="1" onClick={() => setModalState(true)}>
        <p className="nav-link">Invite User</p>
      </StyledHeaderDropdownMenuItem>

      <StyledHeaderDropdownMenuItem key="2" className="logout__wrap">
        <Link to="/login" onClick={handleLogout} className="nav-link">
          Logout
        </Link>
      </StyledHeaderDropdownMenuItem>
    </StyledHeaderDropdownContentWrappeer>
  );
  return (
    <>
      <StyledHeaderTop openSideBar={openSideBar} className="sticky-header">
        <StyledLeftSideMenu>
          <span className="header_hamburger_open">
            <CgMenu className="header_hamburger_menu" onClick={openSider} />
          </span>
          <Link to="/" className="nav-link">
            <AvenewLogo className="avenew__title" />
          </Link>
        </StyledLeftSideMenu>
        <StyledRightSideMenu>
          <HeaderSearch bgcolor="#eeeeee" />
          <HeaderNotification />
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="header_dropdown"
          >
            <UserAvatar
              fullName={`${user?.firstName || ""} ${user?.lastName || ""}`}
              className="user__icon"
              src={user?.image?.path}
            />
          </Dropdown>
        </StyledRightSideMenu>
      </StyledHeaderTop>
      <UserSettingsModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </>
  );
}

export default Header;
