import { Col, Divider, Row, Badge, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "../../ui/Layout/Container";
import { Heading } from "../../ui/Heading/Heading";
import HeaderSearch from "../layout/header/HeaderSearch";
import {
  ApplicationUsers,
  ApplicationStatus,
  ApplicantName,
  NameStatusWrap,
  StatusWrap,
  ApplicationProccess,
  RightSideWrap,
  UnitDetails,
} from "./ApplicationsStyle";
import { ArrowSvgIcon, NoApplicationsvg } from "../../assets/svgs";
import { UserAvatar } from "../../ui/Avatar";
import { Link } from "react-router-dom";
import { Steps } from "antd";
import { ContainerWrap } from "../../ui/Layout/ContainerWrap";
import styled from "styled-components";
import Approval from "./Approval";
import SendLease from "./SendLease";
import LeaseSigned from "./LeaseSigned";
import DepositSubmitted from "./DepositSubmitted";
import { useDispatch } from "react-redux";
import {
  getApplications,
  getUserApplication,
} from "../../store/reducers/user.reducer";
import {
  CallOrEmailMainWrap,
  CallOrEmailWrap,
} from "../call-or-email/CallOrEmail";

function Applications() {
  const { Step } = Steps;
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(1);
  const [allApplicatiosObj, setAllApplicationsObj] = useState({});
  const [selectedApplication, setSelectedApplication] = useState({});
  const [allApplicationsArr, setAllApplicationsArr] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const statusBgColor = (status) => {
    switch (status) {
      case "Awaiting Approval":
        return "#FFEADE";
      case "Waiting for Lease":
        return "#DEE8FF";
      case "Deposit Pending":
        return "#FFF3CA";
      case "Awaiting Approval":
        return "#FFEADE";
      case "Approved":
        return "#D0FFDD";
      case "Denied":
        return "#d7dadd";
      default:
        return "#ffffff";
    }
  };
  const statusFtColor = (status) => {
    switch (status) {
      case "Awaiting Approval":
        return "#F78341";
      case "Waiting for Lease":
        return "#4160AC";
      case "Deposit Pending":
        return "#FFC123";
      case "Awaiting Approval":
        return "#4160AC";
      case "Approved":
        return "#56C274";
      case "Denied":
        return "#2B353F";
      default:
        return "#aaaaaa";
    }
  };

  const onChange = (value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    setIsloading(true);
    dispatch(getApplications()).then(({ payload }) => {
      setAllApplicationsObj(payload);
      setIsloading(false);
    });
  }, []);

  useEffect(() => {
    if (allApplicatiosObj && Object.keys(allApplicatiosObj).length) {
      const arr = Object.values(allApplicatiosObj);
      setAllApplicationsArr(arr);
      setSelectedApplication(arr[0]);
      getApplicationFun(arr[0].id);
    } else {
      setAllApplicationsArr([]);
    }
  }, [allApplicatiosObj]);

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  const getApplicationFun = (id) => {
    localStorage.setItem("application_id", id);
    if (localStorage.getItem("application_id") === id) {
      dispatch(getUserApplication()).then(({ payload }) => {
        // setSelectedApplication(payload[0]);
        console.log(payload[0], "res");
      });
    } else {
      console.log("not same");
    }
  };

  useEffect(() => {
    if (Object.keys(selectedApplication).length) {
      if (
        selectedApplication.leaseAgreementText ||
        selectedApplication?.leaseAgreementDoc
      ) {
        setCurrentTab(3);
      } else if (selectedApplication.isApprove) {
        setCurrentTab(2);
      } else {
        setCurrentTab(1);
      }
    }
  }, [selectedApplication]);

  const updatedApplication = (application) => {
    setSelectedApplication(application);

    let editObj = [...allApplicationsArr];
    let changedObj = editObj.filter((item) => {
      return item["id"] === application.id;
    });
    let eValue = { ...changedObj[0] };
    eValue["isApprove"] = application.isApprove;
    const newUpdatedArr = editObj.map((val) => {
      if (val.id === eValue.id) {
        return eValue;
      } else {
        return val;
      }
    });
    setAllApplicationsArr(newUpdatedArr);
  };

  return (
    <>
      {isLoading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : allApplicationsArr?.length ? (
        <ApplicationsWrap>
          {/* <FetureSoon /> */}

          <Row gutter={[30, 30]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
              <Heading>
                <Link to="/applications">Applications</Link>
              </Heading>
              <Badge.Ribbon text="Awaiting Approval">
                <ContainerWrap
                  minH="auto"
                  padding="0"
                  bgColor="#ffffff"
                  brRadius="10px"
                >
                  <NameStatusWrap>
                    <div className="avatar__wrap">
                      <UserAvatar
                        fullName={`${
                          selectedApplication?.userDetails?.firstName || ""
                        } ${selectedApplication?.userDetails?.lastName || ""}`}
                        className="user__icon"
                        src={
                          selectedApplication?.userDetails?.image
                            ? selectedApplication?.userDetails?.image
                            : ""
                        }
                      />
                      <span className="avatarName__wrap">
                        {upperCase(selectedApplication?.userDetails?.firstName)}{" "}
                        {upperCase(selectedApplication?.userDetails?.lastName)}
                      </span>
                    </div>
                    <StatusWrap
                      bgColor={statusBgColor(
                        selectedApplication?.isApprove === true
                          ? "Approved"
                          : selectedApplication?.isApprove === false
                          ? "Denied"
                          : "Awaiting Approval"
                      )}
                      ftColor={statusFtColor(
                        selectedApplication?.isApprove === true
                          ? "Approved"
                          : selectedApplication?.isApprove === false
                          ? "Denied"
                          : "Awaiting Approval"
                      )}
                    >
                      <span>
                        {selectedApplication?.isApprove === true
                          ? "Approved"
                          : selectedApplication?.isApprove === false
                          ? "Denied"
                          : "Awaiting Approval"}
                      </span>
                    </StatusWrap>
                  </NameStatusWrap>
                  {/* <UnitDetails>Hello</UnitDetails> */}
                  <ApplicationProccess>
                    <Steps
                      direction="horizontal"
                      size="small"
                      onChange={onChange}
                      current={currentTab}
                    >
                      <Step
                        className="application__width"
                        title="Application Submitted"
                      />
                      <Step className="approval__width" title="Approval" />
                      <Step
                        className="lease__width"
                        title="Send Lease & Deposit Total"
                      />
                      <Step className="signed__width" title="Lease Signed" />
                      <Step
                        className="application__width"
                        title="Deposit Submitted"
                      />
                    </Steps>
                  </ApplicationProccess>
                  <div style={{ padding: "0px 13px" }}>
                    <Divider style={{ margin: "0", background: "#dddddd" }} />
                  </div>
                  {currentTab === 0 ? (
                    <Approval
                      updatedApplication={updatedApplication}
                      selectedApplication={selectedApplication}
                    />
                  ) : currentTab === 1 ? (
                    <Approval
                      updatedApplication={updatedApplication}
                      selectedApplication={selectedApplication}
                    />
                  ) : currentTab === 2 ? (
                    <SendLease
                      updatedApplication={updatedApplication}
                      selectedApplication={selectedApplication}
                    />
                  ) : currentTab === 3 ? (
                    <LeaseSigned selectedApplication={selectedApplication} />
                  ) : currentTab === 4 ? (
                    <DepositSubmitted
                      selectedApplication={selectedApplication}
                    />
                  ) : (
                    ""
                  )}
                </ContainerWrap>
              </Badge.Ribbon>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <Container
                minH="auto"
                padding="15px 15px 0"
                paddingSm="15px 15px 0"
                bgColor="#ffffff"
                brRadius="10px"
                className="applicant__wrap"
                // style={{ position: " fixed" }}
              >
                <HeaderSearch
                  marginBottom="16px"
                  bgcolor="#eeeeee"
                  position="static"
                  maxWidth="100%"
                />
                <RightSideWrap>
                  {allApplicationsArr.map((item, _id) => (
                    <ApplicationUsers
                      borderColor={
                        item.id === selectedApplication.id
                          ? "#F67C36"
                          : "#dddddd"
                      }
                      key={_id}
                      onClick={() => {
                        getApplicationFun(item.id);
                        setSelectedApplication(item);
                      }}
                    >
                      <Row>
                        {/* <StyledAvatar  size={37} icon={<UserSvgIcon />} /> */}
                        <UserAvatar
                          className="user__icon"
                          fullName={`${item.userDetails?.firstName || ""} ${
                            item.userDetails?.lastName || ""
                          }`}
                          // src={avatar}
                          src={
                            item.userDetails?.image
                              ? item.userDetails?.image
                              : ""
                          }
                          size={37}
                        />
                        <ApplicationStatus
                          bgColor={statusBgColor(
                            item?.isApprove === true
                              ? "Approved"
                              : item?.isApprove === false
                              ? "Denied"
                              : "Awaiting Approval"
                          )}
                          ftColor={statusFtColor(
                            item?.isApprove === true
                              ? "Approved"
                              : item?.isApprove === false
                              ? "Denied"
                              : "Awaiting Approval"
                          )}
                        >
                          <span>Awaiting Approval</span>
                        </ApplicationStatus>
                      </Row>
                      <ApplicantName>
                        <Row>
                          <h2>
                            {upperCase(item.userDetails?.firstName)}{" "}
                            {upperCase(item.userDetails?.lastName)}
                          </h2>
                        </Row>
                        <Row>
                          <Col style={{ marginRight: "6px" }}>
                            <ArrowSvgIcon fill="#D0FFDD" stroke="#56C274" />
                          </Col>
                          <span>Credit score flgged</span>
                        </Row>
                      </ApplicantName>
                    </ApplicationUsers>
                  ))}
                </RightSideWrap>
              </Container>
            </Col>
          </Row>
        </ApplicationsWrap>
      ) : (
        <CallOrEmailMainWrap>
          <CallOrEmailWrap>
            <Row justify="center">
              <Col className="contact__us">
                <NoApplicationsvg />
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <h2>No Application Available</h2>
              </Col>
            </Row>
          </CallOrEmailWrap>
        </CallOrEmailMainWrap>
      )}
    </>
  );
}

export default Applications;

export const ApplicationsWrap = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "50px 50px 0px 330px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  .ant-ribbon {
    display: none;
  }
  .applicant__wrap {
    position: fixed;
    width: 19%;
  }

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "40px 40px 0 265px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }
  @media (max-width: 1200px) {
    .applicant__wrap {
      position: static;
      width: auto;
    }
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
  @media (max-width: 420px) {
    .ant-ribbon,
    .ant-ribbon-text {
      display: block;
      background-color: #ffeade;
      color: #f67c36;
    }
  }
`;
