import styled from "styled-components";
import { Button } from "antd";

export const LoginMain = styled.div`
  display: flex;
  height: 100vh;
`;

export const LoginWrap = styled.div`
  padding-top: 140px;
  padding-bottom: 140px;
  width: 50%;
  background-color: #ffffff;

  .avenew__signup {
    color: #777777;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }
  .avenew__logo {
    margin-bottom: 15px;
  }

  .personal__profile {
    color: #323548;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
  }

  .welcome__avenew {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #777777;
    margin-bottom: 33px;
  }

  .nav__bar {
    align-items: center;
    margin-bottom: 42px;
  }

  .nav__font {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 0;
    padding: 0px 10px 0 10px;
  }
  .or__option {
    align-items: center;
    margin-top: 18px;
    p {
      margin: 0 15px 0 15px;
      font-weight: 400;
      font-size: 18px;
    }
  }
  svg {
    width: 350px;
    height: 125px;
  }

  @media (max-width: 1550px) {
    padding-top: 112px;
    padding-bottom: 112px;
    svg {
      width: 280px;
      height: 100px;
    }
    .personal__profile {
      font-size: 32px;
      line-height: 39px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    ${'' /* background-image: url("/images/loginBg.png"); */}
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}80;
    background-position: initial;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  @media (max-width: 425px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
`;
export const LoginRight = styled.div`
  // min-height: 100vh;
  width: 50%;
  background-color: #aaaaaa;
  background-image: url("/images/loginBg.png");

  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(37, 54, 78);
  background-position: initial;
  animation: 5s ease 0s 1 normal none running fadeIn;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const StyledBg = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  display: flex;
  width: 50%;
  position: relative;
  background-image: url("/images/loginBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.primary};
  ${
    "" /* box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}30; */
  }
  background-position: initial;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
  @keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const CreateAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.forth};
    padding-right: 5px;
  }
  h3 {
    margin: 0;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.third};
  }
  @media (max-width: 1550px) {
    margin-top: 36px;
    p {
      font-size: 14px;
    }
    h3 {
      font-size: 14px;
    }
  }

  @media (max-width: 395px) {
    flex-direction: column;
  }
`;

export const ManagePropertyBtn = styled.div`
  display: flex;
  border: 1px solid transparent;
  border-color: #d9d9d9;
  margin-top: 0px;
  width: 100%;
  height: 50px;
  border-radius: ${({ br }) => br || "5px"};
  font-size: 14px;
  font-weight: 700;
  color: #777777;
  background: #ffffff;
  cursor: pointer;
  align-items: center;
  padding: 0 30px 0 30px;
  img {
  }

  p {
    margin: 0;
    margin-left: 15px;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.forth};
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  a {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    right: 40px;
    color: ${({ theme }) => theme.colors.third};
    position: absolute;
    transition: transform 0.4s;

    :hover {
      color: ${({ theme }) => theme.colors.third}80;
    }
  }

  svg {
    margin-top: 1px;
    margin-right: -30px;
    font-weight: 700;
    font-size: 22px;
    right: 40px;
    color: ${({ theme }) => theme.colors.third};
    right: 0;
    position: absolute;
  }

  @media (max-width: 1550px) {
    height: 40px;
    font-size: 12px;
    padding: 0 24px 0 24px;
    img {
      width: 105px;
    }

    p {
      margin-left: 12px;
      font-size: 16px;
      line-height: 27px;
    }

    h3 {
      font-size: 14px;
    }

    svg {
      margin-top: 1px;
      margin-right: -30px;
      font-weight: 700;
      font-size: 22px;
      right: 80px;
      color: ${({ theme }) => theme.colors.third};
      right: 0;
      position: absolute;
    }
  }
  @media (max-width: 872px) {
    display: none;
  }
  @media (max-width: 425px) {
    padding: 0 5px 0 5px;
  }
  @media (max-width: 375px) {
    p {
      font-size: 14px;
    }
    h3 {
      font-size: 14px;
    }
  }
  @media (max-width: 350px) {
    p {
      font-size: 12px;
    }
    h3 {
      font-size: 12px;
    }
  }
`;
export const ManagePropertyBtnSm = styled.div`
  justify-content: space-between;
  margin-top: 0px;
  width: 100%;
  border-radius: ${({ br }) => br || "5px"};
  font-size: 14px;
  font-weight: 700;
  color: #777777;
  background: #ffffff;
  cursor: pointer;
  align-items: center;
  padding: 0 30px 0 30px;
  display: none;
  flex-direction: column;
  height: auto;
  border: 1px solid transparent;
  border-color: #d9d9d9;

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 0;
    color: ${({ theme }) => theme.colors.forth};
  }

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.third};
    :hover {
      color: ${({ theme }) => theme.colors.third}80;
    }
  }
  .smScreen__btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    height: auto;
  }

  .resman__img {
    width: 90px;
  }

  @media (max-width: 872px) {
    display: flex;
  }
  @media (max-width: 425px) {
    padding: 0 10px;
  }
  @media (max-width: 415px) {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 376px) {
    h3 {
      font-size: 14px;
    }
  }
  @media (max-width: 350px) {
    p {
      font-size: 12px;
    }
    h3 {
      font-size: 12px;
    }
  }
`;
