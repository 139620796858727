import React from "react";

const myAccountSvg = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2479 9.38178C15.1564 8.38463 15.7143 7.07152 15.7143 5.62963C15.7143 2.52559 13.1507 0 10 0C6.84929 0 4.28571 2.52559 4.28571 5.62963C4.28571 8.73367 6.84929 11.2593 10 11.2593C11.1107 11.2593 12.145 10.9405 13.0236 10.3979C16.1307 11.5513 18.27 14.3527 18.5421 17.5926H1.45857C1.64357 15.4118 2.68214 13.3781 4.37143 11.9271C4.66929 11.6716 4.7 11.2276 4.44071 10.9341C4.18214 10.6414 3.73071 10.6104 3.43286 10.8659C1.25071 12.7391 0 15.447 0 18.2963C0 18.6854 0.32 19 0.714286 19H19.2857C19.6807 19 20 18.6854 20 18.2963C20 14.4358 17.7286 10.9883 14.2479 9.38178ZM5.71429 5.62963C5.71429 3.30107 7.63643 1.40741 10 1.40741C12.3636 1.40741 14.2857 3.30107 14.2857 5.62963C14.2857 7.95819 12.3636 9.85185 10 9.85185C7.63643 9.85185 5.71429 7.95819 5.71429 5.62963Z"
        fill="#777777"
      />
    </svg>
  );
};

export default myAccountSvg;
