import React from "react";

const callSvg = ({
  size = "26",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6087 18.1262C15.6087 18.1262 17.6332 16.9671 18.1632 16.6886C18.6917 16.4091 19.2392 16.3386 19.5717 16.5416C20.0752 16.8496 24.3032 19.6622 24.6592 19.9112C25.0152 20.1607 25.1867 20.8732 24.6972 21.5712C24.2097 22.2692 21.9607 25.0293 21.0077 24.9998C20.0532 24.9688 16.0847 24.8818 8.60123 17.3961C1.11925 9.91302 1.03075 5.94346 1.00025 4.98894C0.969751 4.03493 3.72974 1.78539 4.42774 1.29739C5.12674 0.809878 5.84024 0.993381 6.08824 1.33639C6.37023 1.72689 9.15023 5.94146 9.45623 6.42447C9.66572 6.75447 9.58872 7.30498 9.30923 7.83399C9.03123 8.364 7.87223 10.3885 7.87223 10.3885C7.87223 10.3885 8.68923 11.7821 11.4522 14.5446C14.2157 17.3076 15.6087 18.1262 15.6087 18.1262Z"
        stroke="#F67C36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default callSvg;
