import React, { useState, useEffect, useCallback } from "react";
import { List, Popover, Skeleton } from "antd";
import {
  StyledListItem,
  StyledListWrapper,
  StyledNotificationInline,
  StyledNotificationWrapper,
} from "./TheHeader.styles";
import { NotificationBellSvg } from "../../../assets/svgs";
import "./style.css";
import { BadgeCount } from "../../../ui/Badge";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { useProgressiveImage } from "../../../utils/hooks/useProgressiveImage";
import { ScrollView } from "../../../ui/ScrollView";
import { ShowMoreMenu } from "../../../ui/Menu";
import { MdMoreVert } from "react-icons/md";
import { UserAvatarRelationship } from "../../../ui/Avatar";
import { imagePath } from "../../../utils/fn";
import {
  getNotifications,
  updateNotifications,
} from "../../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import io from "socket.io-client";
import { AiOutlineClose } from "react-icons/ai";

const NotificationBellIcon = (props) => {
  return <NotificationBellSvg {...props} />;
};
// const endPoint = "http://localhost:4000";
var socket;

const HeaderNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [socketConnected, setSocketConnected] = useState(false);
  const [badge, setBadge] = useState(1);
  const [state, setstate] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);

  const handleVisibleChange = (visible) => {
    setstate({ visible });
    setPopoverVisible(visible);
  };

  const hide = () => {
    setstate({
      visible: false,
    });
  };

  useEffect(() => {
    socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.emit("setup", user?.id);
    socket.on("connected", () => setSocketConnected(true));
  }, [user]);

  useEffect(() => {
    if (localStorage.getItem("user_id")) {
      dispatch(getNotifications()).then(({ payload }) => {
        setAllNotifications(payload.notifications);
      });
    }
  }, []);

  useEffect(() => {
    if (socketConnected) {
      socket.on("new notification", (newNotification) => {
        setAllNotifications([...allNotifications, ...newNotification]);
      });
    }
  });

  const notificationClick = (item) => {
    navigate({
      pathname: `/messages`,
      search: createSearchParams({
        chatId: item.chatId,
      }).toString(),
    });
    hide();
  };

  const dismissNotification = (item, id) => {
    const value = { ...item };
    value.is_dismiss = true;

    const notiArr = [...allNotifications];
    const arr = [];
    notiArr.map((item, idx) => {
      if (idx === id) {
      } else {
        arr.push(item);
      }
    });
    setAllNotifications(arr);
    dispatch(updateNotifications(value));
  };

  const getTime = (date) => {
    const ms = new Date(date);
    let newTime = ms.toLocaleTimeString("en-US");
    let hour = newTime.split(":")[0];
    let amPm = newTime.split(" ")[1];
    let seconds = newTime.split(":")[2].replace(amPm, "");

    let noAmPm = newTime.replace(amPm, "");
    let noAmPmSeconds = newTime.replace(":" + seconds, " ");
    let noSeconds = newTime.replace(":" + seconds, " ");

    if (parseInt(hour) < 9) {
      newTime = "0" + newTime;
      noAmPm = "0" + noAmPm;
      noSeconds = "0" + noSeconds;
      noAmPmSeconds = "0" + noAmPmSeconds;
    }

    return noAmPmSeconds;
  };

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${month} ${day}, ${year}`;
  }

  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  const content = (
    <ScrollView maxHeight={"500px"}>
      <StyledListWrapper
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
        itemLayout="horizontal"
        dataSource={allNotifications}
        renderItem={(item, idx) => (
          <StyledListItem
            key={idx}
            onClick={(e) => {
              notificationClick(item);
            }}
            // actions={[
            //   <ShowMoreMenu
            //     Icon={MdMoreVert}
            //     options={
            //       item.isRead
            //         ? [
            //             {
            //               label: "Mark as unread",
            //               value: "unread",
            //               isDanger: false,
            //             },
            //             {
            //               label: "Delete",
            //               value: "delete",
            //               isDanger: false,
            //             },
            //           ]
            //         : [
            //             {
            //               label: "Mark as read",
            //               value: "read",
            //               isDanger: false,
            //             },
            //             {
            //               label: "Delete",
            //               value: "delete",
            //               isDanger: false,
            //             },
            //           ]
            //     }
            //     item={item}
            //     onChange={({ key, item }) => {
            //       console.log(key, item);
            //     }}
            //   />,
            // ]}
            // notificationRead={item.isRead}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                onClick={(e) => {
                  // eslint-disable-next-line no-unused-expressions
                  e.defaultPrevented ? "" : notificationClick(item);
                  e.stopPropagation();
                }}
                avatar={
                  <UserAvatarRelationship
                    size={50}
                    fullName={"umesh avaiya"}
                    src={item.path ? item.path : ""}
                  />
                }
                description={
                  <>
                    {item?.message && (
                      <>
                        <StyledNotificationInline>
                          <span>
                            {dateConverter(item.parseCreatedAtDate)}|{" "}
                            {getTime(item.parseCreatedAtDate)}
                          </span>
                          <p>{item.message}</p>
                        </StyledNotificationInline>
                        <AiOutlineClose
                          onClick={(e) => {
                            e.preventDefault();
                            dismissNotification(item, idx);
                            // console.log("clicked e");
                          }}
                        />
                      </>
                    )}
                  </>
                }
              />
              {/* <span className="header_notification_time">
                {truncatedDateForMobile(dayjs(item.createdAt).fromNow())}
              </span> */}
            </Skeleton>
          </StyledListItem>
        )}
      />
    </ScrollView>
  );

  const handleReadAllNotification = (notification) => {
    // NotificationAPI.getNotificationList(
    //   querystring({
    //     userId: (user && user?._id) || localStorage.getItem("userId"),
    //     isRead: false,
    //   })
    // ).then(({ data }) => {
    //   if (data && data.data.length > 0) {
    //     for (const notif of data.data) {
    //       NotificationAPI.updateNotification(notif._id, { isRead: true });
    //     }
    //   }
    // });
    // if (notification && notification._id) {
    //   NotificationAPI.updateNotification(notification._id, { isRead: true })
    //     .then(({ data }) => {
    //       getNotificationList(pagination);
    //     })
    //     .catch((error) => {});
    // }
  };

  return (
    <>
      <StyledNotificationWrapper>
        <Popover
          placement="bottomRight"
          content={content}
          onClick={handleReadAllNotification}
          trigger="click"
          overlayClassName="header_popover_wrap"
          visible={state.visible}
          onVisibleChange={handleVisibleChange}
        >
          {allNotifications?.length ? (
            <BadgeCount count={allNotifications?.length} size={"small"}>
              <NotificationBellIcon
                className="bell_icon"
                width="26"
                height="26"
                color="#2B353F"
              />
            </BadgeCount>
          ) : (
            <NotificationBellIcon
              className="bell_icon"
              width="26"
              height="26"
              color="#2B353F"
            />
          )}
        </Popover>
      </StyledNotificationWrapper>
    </>
  );
};

export default HeaderNotification;
