import { createSlice } from "@reduxjs/toolkit";

export const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState: {
    userPersonalDetails: {
      FirstName: "Immanuel",
      LastName: "Kaydence ",
      PhoneNumber: "+1 256 789 1450",
      Email: "immanuel.kaydence@gmail.com",
    },
  },
  reducers: {
    personalDetails: (state, action) => {
      state.userPersonalDetails = action.payload;
    },
  },
});

export const { personalDetails } = personalDetailsSlice.actions;

export const selectPersonalDetails = (state) =>
  state.personalDetails.personalDetails;

export default personalDetailsSlice.reducer;
