import { Col, Pagination, Row } from "antd";
import React from "react";
import styled from "styled-components";
import HeaderSearch from "../layout/header/HeaderSearch";

function GettingStarted() {
  return (
    <GettingStartedWrap>
      <Row style={{ marginBottom: "50px" }} justify="space-between">
        <Col xs={24} sm={10} md={8} lg={11} xl={10} xxl={8}>
          <h2>Getting Started</h2>
        </Col>
        <Col xs={24} sm={10} md={8} lg={11} xl={10} xxl={8}>
          <HeaderSearch bgcolor="#eeeeee" />
        </Col>
      </Row>
      <Row gutter={[50, 50]}>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3>What’s the difference between a lease break & sublet?</h3>
          <p>
            You’ve signed a lease with your landlord but prior to the end of the
            lease, you need to move. What do you do!? This is when the
            important…
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3>What transportation options do I have to get to an airport?</h3>
          <p>
            From Manhattan to the Airport If you're new to New York there's a
            lot of things to get acquainted with. The different modes of public
            transportation are one…
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3>What is section 8?</h3>
          <p>
            Section 8 is a term most of us have heard being through around in
            conversation or mentioned on the news. But what is actually section
            8? The…
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3>Can I rent an apartment with a bad credit score?</h3>
          <p>
            Do you have a credit score lower than 650? Have you ever gotten
            denied for rental applications? Renting can be incredibly
            challenging when you have a bad…
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3>Can I install a washer and dryer in my apartments?</h3>
          <p>
            Find out whether or not you're allowed to install a washer and dryer
            in your apartment and why it's not worth it to install a washer and
            dryer if your landlord says no.
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3>What's the difference between a prewar and postwar apartment?</h3>
          <p>
            When you are looking around for a new apartment to rent or check
            out, there are some terms that often get tossed around that you may
            not know…
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3>What do I do with the previous tenant's mail?</h3>
          <p>
            Learn what protocol you should follow if you continue to receive the
            previous tenant's mail.
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3> Where are the bike lanes in New York?</h3>
          <p>
            Find out where the best neighborhoods for biking are located! What
            the data reveals may surprise you!
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
          <h3>What makes an apartment illegal?</h3>
          <p>
            Find out what constitutes as a illegal apartment. If you’re
            wondering if your apartment is illegal, you can check with the
            Department of Buildings. All buildings built after April 1929 have a
            certificate of occupancy which will tell you the ways the building
            is permitted to be used.
          </p>
        </Col>
      </Row>

      <Row gutter={[15,15]} style={{ marginTop: "70px", alignItems: "center" }} justify="space-between">
        <Col>
          <h4>
            Do you have any questions? <span>Contact Us</span>
          </h4>
        </Col>
        <Col>
          <Pagination size="small" defaultPageSize={1} total={9} />
        </Col>
      </Row>
    </GettingStartedWrap>
  );
}

export default GettingStarted;

export const GettingStartedWrap = styled.div`
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
    color: #2b353f;
    margin-bottom: 0;
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
    margin-bottom: 0;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #aaaaaa;
    margin-bottom: 0;
  }
  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
    span {
      color: #f67c36;
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  /* FOR PAGINATION */
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .pagination__wrap {
    padding-top: 30px;
  }
  .ant-pagination-prev .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-left: 5px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.secondary};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }

  @media (max-width: 1550px) {
    h2 {
      font-size: 20px;
      line-height: 40px;
    }
    h3 {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
    h4 {
      font-size: 14px;
      line-height: 15px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-pagination.mini .ant-pagination-options {
      display: none;
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 0;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 5px 4px;
    }
  }
  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 5px;
    }
  }
`;
