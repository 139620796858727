import { createSlice } from "@reduxjs/toolkit";

export const businessDetailsSlice = createSlice({
  name: "businessDetails",
  initialState: {
    userbusinessDetails: {
      OrganizationName: "Imman Chesapeake Energy",
      OrganizationNumber: "+1 256 789 1450",
      OrganizationEmail: "Imman.emery@gmail.com",
      TotalListedApartment: "52",
      TotalGeneratedRevenue: "5698.00",
    },
  },
  reducers: {
    businessDetails: (state, action) => {
      state.userbusinessDetails = action.payload;
    },
  },
});

export const { businessDetails } = businessDetailsSlice.actions;

export const selectbusinessDetails = (state) =>
  state.businessDetails.businessDetails;

export default businessDetailsSlice.reducer;
