import React, { useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { IoIosClose } from "react-icons/io";
import { Modal } from "antd";
import ListApartment from "../apartment-listing/ListApartment";
import ListUnit from "../unit-listing/ListUnit";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation } from "react-router";
import AddTenantsForm from "./AddTenantsForm";
import InviteTenantsForm from "./InviteTenantsForm";

function InviteTenantsModal({
  modalState,
  onCancel,
  isApart = false,
  inviteTenant,
  newAddedVal
}) {
  const [isFormChange, setIsFormChange] = useState(false);
  const [isApartValue, setIsApartValue] = useState(isApart);
  const [apartmentName, setApartmentName] = useState("");

  const { width } = useWindowSize();
  const location = useLocation();

  const [modalWidth, setModalWidth] = useState(750);

  useEffect(() => {
    if (width < 1550) {
      setModalWidth(600);
    } else {
      setModalWidth(750);
    }
  }, [width, location.pathname]);

  function fromChanges(val) {
    setIsFormChange(val);
  }

  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };
  const apartValue = (val) => {
    setIsApartValue(val);
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  const ApartmentValue = (val) => {
    setApartmentName(val.apartment_name);
  };
  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          if (!isFormChange) {
            onCancel(false);
          } else {
            openPrompt(
              "You will lose all changes if you navigate away from this page without saving"
            );
          }
        }}
        width={modalWidth}
        footer={null}
        style={{ borderRadius: "5px", top: "50px" }}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <InviteTenantsForm newAddedVal={newAddedVal} inviteTenant={inviteTenant} isModal={true} onCancel={onCancel} />
      </Modal>
    </>
  );
}

export default InviteTenantsModal;
