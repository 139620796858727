import { Col, Row, Steps } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { updateApplication } from "../../store/reducers/user.reducer";
import {
  ButtonWrap,
  CurrentStagWrap,
  StatusButton,
  StepWrap,
} from "./ApplicationsStyle";

function Approval({ selectedApplication, updatedApplication }) {
  const { Step } = Steps;
  const dispatch = useDispatch();
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);

  const statusBgColor = (status) => {
    switch (status) {
      case true:
        return "finish";
      case false:
        return "wait";
      case "Deposit Pending":
        return "#FFF3CA";
      case "Awaiting Approval":
        return "#FFEADE";
      default:
        return "#ffffff";
    }
  };

  const approveApplication = () => {
    setApproveLoading(true);
    const value = {};
    value.isApprove = true;
    value.adminUserId = selectedApplication.adminUserId;
    value.backgroundCheckVerified = selectedApplication.backgroundCheckVerified;
    value.creditScoreVerified = selectedApplication.creditScoreVerified;
    value.id = selectedApplication.id;
    value.incomeVerified = selectedApplication.incomeVerified;
    value.leaseLength = selectedApplication.leaseLength;
    value.previousRental = selectedApplication.previousRental;
    value.prospectUserId = selectedApplication.prospectUserId;
    value.selectedDate = selectedApplication.selectedDate;
    value.unitId = selectedApplication.unitId;
    dispatch(updateApplication(value)).then((res) => {
      updatedApplication(res.payload);
      setApproveLoading(false);
    });
  };
  const denyApplication = () => {
    setDenyLoading(true);
    const value = {};
    value.isApprove = false;
    value.adminUserId = selectedApplication.adminUserId;
    value.backgroundCheckVerified = selectedApplication.backgroundCheckVerified;
    value.creditScoreVerified = selectedApplication.creditScoreVerified;
    value.id = selectedApplication.id;
    value.incomeVerified = selectedApplication.incomeVerified;
    value.leaseLength = selectedApplication.leaseLength;
    value.previousRental = selectedApplication.previousRental;
    value.prospectUserId = selectedApplication.prospectUserId;
    value.selectedDate = selectedApplication.selectedDate;
    value.unitId = selectedApplication.unitId;
    dispatch(updateApplication(value)).then((res) => {
      updatedApplication(res.payload);
      setDenyLoading(false);
    });
  };

  return (
    <ApprovalWrap>
      <CurrentStagWrap>
        <h2>Step : 2 Approval</h2>
      </CurrentStagWrap>
      <StepWrap>
        <Steps direction="vertical" size="small">
          <Step
            status={statusBgColor(selectedApplication?.creditScoreVerified)}
            title="Credit Score"
            description="This applicant's credit score has been flagged because it does
                  not meet the minimum requiements you have set for this
                  property."
          />
          <Step
            status={statusBgColor(selectedApplication?.backgroundCheckVerified)}
            title="Background Check"
            description="Verified with Avenew to meet your minimum threshold."
          />
          <Step
            status={statusBgColor(selectedApplication?.incomeVerified)}
            title="Income"
            description="Verified with Avenew to meet your minimum threshold."
          />
          <Step
            status={statusBgColor(selectedApplication?.previousRental)}
            title="Previous Rental Information"
            description="Verified with Avenew to meet your minimum threshold."
          />
        </Steps>
      </StepWrap>
      <ButtonWrap>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
            <StatusButton className="status__button" bgColor={"#4160AC"}>
              <span>Message Applicant</span>
            </StatusButton>
          </Col>
          <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
            <StatusButton
              onClick={() => approveApplication()}
              className="status__button"
              bgColor={"#F67C36"}
              loading={approveLoading}
            >
              <span>Approve Application</span>
            </StatusButton>
          </Col>

          <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
            <StatusButton
              onClick={() => denyApplication()}
              className="status__button"
              bgColor={"#2B353F"}
              loading={denyLoading}
            >
              <span>Deny Application</span>
            </StatusButton>
          </Col>
        </Row>
      </ButtonWrap>
    </ApprovalWrap>
  );
}

export default Approval;

const ApprovalWrap = styled.div`
  .ant-steps-item-tail {
    display: none !important;
  }
`;
