import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { AiOutlineUpload } from "react-icons/ai";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";
import { CoverImage, ImagePreview } from "../ImagePreview/index";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledFormSelect,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import {
  DropDownView,
  ListWrap,
  PropertyBtn,
  StyledCover,
  StyledUploadPreviewWrapper,
  StyledUploadPreviewWrapperCover,
  UploadBox,
  UploadInput,
} from "./ListApartmentStyle";
import { useBeforeunload } from "react-beforeunload";
import { ManagePropertyBtn, ManagePropertyBtnSm } from "../login/LoginStyle";
import { MdClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  createApartment,
  createOrg,
  createUnit,
  me,
  updateApartment,
} from "../../store/reducers/user.reducer";
import data from "./state.json";
import useDebounce from "../../utils/hooks/useDebounce";
import { BsCurrencyDollar } from "react-icons/bs";

function ListApartment({
  pageTitle,
  bgImage,
  pageMargin,
  isModal = false,
  isEdit = false,
  onCancel,
  updateUnit,
  apartValue,
  onFormChange = console.log,
  getUpdatedUnit = console.log,
  newListedApartment,
  newListedSubUnit,
  getUpdatedUnitApartment,
}) {
  const { Option } = Select;
  const currentDateTime = new Date();
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  const currentDayTime = new Date();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const upload = React.useRef(null);
  const [state, setState] = useState([]);
  const [selectAmmenities, setSelectAmmenities] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [viewAmmenities, setViewAmmenities] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [citesValue, setCitesValue] = useState([]);
  const [stateValue, setstateValue] = useState("");
  const [selectImgIdx, setSelectImgIdx] = useState(0);
  const [searchCityValue, setSearchCityValue] = useState("");
  const [searchStateValue, setSearchStateValue] = useState("");
  const [multiProperty, setMultiProperty] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [amenitiesLength, setAmenitiesLength] = useState(12);

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const [statesValue, setStatesValue] = useState(states);

  const removeAmmenities = (value) => {
    setSelectAmmenities(selectAmmenities.filter((item) => item !== value));
  };

  useEffect(() => {
    if (pageTitle) {
      pageTitle("List Your Property");
      pageMargin("50px auto");
      bgImage("/images/listApartment.png");
    }
  }, []);

  const imagePath = (path, isStatic = false) => {
    if (isStatic) return path;
    return process.env.REACT_APP_API_URL
      ? `${process.env.REACT_APP_API_URL}${path}`
      : path;
  };

  // const fileChangeHandler = (event) => {
  //   console.log(event.target.files);
  //   setState((s) => [
  //     ...s,
  //     ...Array.from(event.target.files).map((file) => ({
  //       image: file,
  //       path: URL.createObjectURL(file),
  //     })),
  //   ]);
  // };

  const fileChangeHandler = async (event) => {
    console.log(event.target.files);
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    // Array(...event.target.files).map(async (img) => {
    //   const convertImg = await convertToBase64(img);
    //   setState((s) => [
    //     ...s,
    //     {
    //       image: convertImg,
    //       path: URL.createObjectURL(img),
    //       name: img.name,
    //     },
    //   ]);
    // });

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        ...s,
        {
          image: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  const fileChangeHandlerDrag = async (files) => {
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    await Promise.all(
      Array(...files).map(async (img) => {
        const convertImg = await convertToBase64(img);
        setState((s) => [
          ...s,
          {
            image: convertImg,
            path: URL.createObjectURL(img),
            name: img.name,
          },
        ]);
      })
    );
    // setState((s) => [
    //   ...s,
    //   ...Array.from(files).map((file) => ({
    //     image: file,
    //     path: URL.createObjectURL(file),
    //   })),
    // ]);
  };

  useEffect(() => {
    if (dataChange) {
      onFormChange(true);
    }
    return () => {
      console.log("closed");
    };
  }, [dataChange]);

  useEffect(() => {
    if (isEdit && updateUnit.length) {
      onChangeMultiPropertyUpDown(updateUnit[0]?.numberOfUnit);
      form.setFieldsValue({
        images: state,
        ammenities: selectAmmenities,
        numberOfUnit: updateUnit[0]?.numberOfUnit,
      });
    } else {
      form.setFieldsValue({
        images: state,
        ammenities: selectAmmenities,
        numberOfUnit: 1,
      });
    }
  }, [form, state, selectAmmenities, isEdit, updateUnit]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");

  useEffect(() => {
    if (orgId) {
      dispatch(me());
    }
  }, []);

  const onCoverChange = (idx) => {
    const st = [...state];
    st.map((item, key) => {
      if (key != idx) {
        delete item.isCover;
      } else {
        st[idx].isCover = true;
      }
    });
    setState(st);
  };

  const onFinish = (value) => {
    if (params.id) {
      if (isEdit && updateUnit.length) {
        value.multi_property = multiProperty;
        value.org_id = updateUnit[0].org_id;
        value.user_id = updateUnit[0].user_id;
        value.id = updateUnit[0].id;
        value.fees = updateUnit[0]?.fees;
        value.numberOfUnit = updateUnit[0]?.numberOfUnit;
        value.updatedAt = currentDateTime;
        value.createdAt = updateUnit.createdAt
          ? updateUnit.createdAt
          : currentDateTime;
        // value.images = updateUnit[0]?.images;
        // if (state?.length && value.images) {
        //   value.images.map((item, key) => {
        //     if (key != selectImgIdx) {
        //       delete item.isCover;
        //     } else {
        //       value.images[selectImgIdx].isCover = true;
        //     }
        //   });
        // }
        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }

        // console.log(value, "value");

        setloadings(true);
        dispatch(updateApartment(value)).then(({ payload }) => {
          getUpdatedUnit(payload);
          setloadings(false);
          onCancel();
        });
      } else {
        value.multi_property = multiProperty;
        value.org_id = params.id;
        value.user_id = user.id;
        value.updatedAt = currentDateTime;
        value.createdAt = currentDateTime;
        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }
        setloadings(true);
        dispatch(createApartment(value)).then(({ payload }) => {
          newListedApartment(payload);

          if (payload && payload.numberOfUnit) {
            for (let i = 0; i < Number(payload.numberOfUnit); i++) {
              const arrValue = {};
              arrValue.user_id = user.id;
              arrValue.apartment_id = payload.id;
              // arrValue.unitType = payload.unitType;
              arrValue.unitType = payload.unitType;
              arrValue.unitStatus = "Configure";
              arrValue.currentDayTime = currentDayTime;

              if (!multiProperty) {
                arrValue.unit_name = payload.unit_name;
                if (payload.default_rent) {
                  arrValue.price = payload.default_rent;
                }
                if (payload.sqft) {
                  arrValue.sqft = payload.sqft;
                }
                if (payload.bed) {
                  arrValue.bed = payload.bed;
                }
                if (payload.bath) {
                  arrValue.bath = payload.bath;
                }
                if (payload.sqft && payload.bed && payload.bath) {
                  arrValue.unitStatus = "Available";
                } else {
                  arrValue.unitStatus = "Configure";
                }
              } else {
                arrValue.unit_name = `Sub Unit ${i + 1}`;
              }

              dispatch(createUnit(arrValue)).then(({ payload }) => {
                newListedSubUnit(payload);
                setloadings(false);
                onCancel();
              });
            }
          } else {
            setloadings(false);
            onCancel();
          }
        });
      }
    } else if (orgId) {
      setloadings(true);
      value.multi_property = multiProperty;
      value.org_id = orgId;
      value.user_id = user.id;
      value.updatedAt = currentDateTime;
      value.createdAt = currentDateTime;
      if (state?.length && value.images) {
        const arr = [];
        value.images.map((item) => {
          arr.push(...Object.keys(item));
        });
        if (!arr.includes("isCover")) {
          value.images[0].isCover = true;
        }
      }

      dispatch(createApartment(value)).then(({ payload }) => {
        if (payload && payload.numberOfUnit) {
          for (let i = 0; i < Number(payload.numberOfUnit); i++) {
            const arrValue = {};
            arrValue.user_id = user.id;
            arrValue.unitStatus = "Configure";
            arrValue.apartment_id = payload.id;
            // arrValue.unitType = payload.unitType;
            arrValue.unit_name = `Sub Unit ${i + 1}`;
            arrValue.unitType = payload.unitType;

            dispatch(createUnit(arrValue)).then(({ payload }) => {
              newListedSubUnit(payload);
              setloadings(false);
              onCancel();
            });
          }
          navigate(`/join-with-us`);
        } else {
          setloadings(false);
          onCancel();
        }
      });
    } else {
      if (isEdit && Object.keys(updateUnit).length) {
        setloadings(true);
        value.multi_property = multiProperty;
        value.org_id = updateUnit[0].org_id;
        value.user_id = updateUnit[0].user_id;
        value.id = updateUnit[0].id;
        value.fees = updateUnit[0]?.fees;
        value.numberOfUnit = updateUnit[0]?.numberOfUnit;
        value.updatedAt = currentDateTime;
        value.createdAt = updateUnit.createdAt
          ? updateUnit.createdAt
          : currentDateTime;

        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }

        dispatch(updateApartment(value)).then(({ payload }) => {
          getUpdatedUnitApartment(payload);
          setloadings(false);
          onCancel();
        });
      }
    }

    // setState([]);
    // form.resetFields();

    // if (!isModal) {
    //   navigate(
    //     "/registration/company-details/create-listing/create-new-apartment/list-unit"
    //   );
    // } else {
    //   // apartValue(true);
    //   // ApartmentValue(value);
    // }
    setDataChange(false);
  };
  // useBeforeunload((event) => {
  //   if (showPrompt) {
  //     event.preventDefault();
  //   }
  // });

  useEffect(() => {
    if (isEdit && updateUnit.length) {
      setSelectAmmenities(updateUnit[0]?.ammenities);
      form.setFieldsValue({
        unitType: updateUnit[0]?.unitType,
        unit_name: updateUnit[0]?.unit_name,
        description: updateUnit[0]?.description,
        address: updateUnit[0]?.address,
        phonenumber: updateUnit[0]?.phonenumber,
        state: updateUnit[0]?.state,
        city: updateUnit[0]?.city,
        zipcode: updateUnit[0]?.zipcode,
        images: updateUnit[0]?.images,
        // images: updateUnit[0]?.images,
        ammenities: updateUnit[0]?.ammenities,
        multi_property: updateUnit[0]?.multi_property,
        numberOfUnit: updateUnit[0]?.numberOfUnit,
        default_rent: updateUnit[0]?.default_rent,
        default_security: updateUnit[0]?.default_security,
        sqft: updateUnit[0]?.sqft,
        bed: updateUnit[0]?.bed,
        bath: updateUnit[0]?.bath,
        price: updateUnit[0]?.price,
        diposit: updateUnit[0]?.diposit,
      });
    }
  }, [isEdit, updateUnit]);

  useEffect(() => {
    if (updateUnit?.length && updateUnit[0]?.images && isEdit) {
      updateUnit[0]?.images.map((img) => {
        setState((s) => [
          ...s,
          {
            ...(img.isCover ? { isCover: img.isCover } : ""),
            key: img.key,
            // url: img.url,
            path: img.path,
          },
        ]);
      });
    }
  }, [updateUnit, isEdit]);

  //
  const onChange = (checkedValues) => {
    setSelectAmmenities(checkedValues);
  };

  const onChangeMultiPropertyUpDown = (e) => {
    if (0 < Number(e) && Number(e) <= 1) {
      setMultiProperty(false);
    } else if (Number(e) > 1) {
      setMultiProperty(true);
    } else {
      setMultiProperty(true);
    }

    if (0 < Number(e)) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const drop = React.useRef(null);
  React.useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);

    // return () => {
    //   drop.current.removeEventListener('dragover', handleDragOver);
    //   drop.current.removeEventListener('drop', handleDrop);
    // };
  }, []);
  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  const onChangeState = (newValue) => {
    setstateValue(newValue);
    // form.setFieldsValue({ city: [] });
  };

  const debouncedSearchValue = useDebounce(searchCityValue, 800);
  const debouncedSearchStateValue = useDebounce(searchStateValue, 800);
  useEffect(() => {
    if (
      debouncedSearchValue?.length &&
      !citesValue.includes(debouncedSearchValue)
    ) {
      setCitesValue((val) => [...val, debouncedSearchValue]);
    }
  }, [searchCityValue, debouncedSearchValue]);

  useEffect(() => {
    if (
      debouncedSearchStateValue?.length &&
      !statesValue.includes(debouncedSearchStateValue)
    ) {
      setStatesValue((val) => [...val, debouncedSearchStateValue]);
      states.push(debouncedSearchStateValue);
    }
  }, [searchStateValue, debouncedSearchStateValue]);

  const handleChange = (value) => {
    if (value?.length === 0) {
      const cityArr = Object.values(data);
      const value = flatDeep(cityArr, cityArr.length);
      let uniqueChars = [...new Set(value)];
      setCitesValue(uniqueChars);
    } else {
      filterFunction(value);
    }

    if (value.length > 3) {
      setSearchCityValue(upperCase(value.toLowerCase()));
    }
  };

  const filterFunction = (input) => {
    const filter = input.toUpperCase();
    var arr = [];

    for (let i = 0; i < citesValue.length; i++) {
      const txtValue = citesValue[i];
      if (txtValue.toUpperCase().startsWith(filter)) {
        arr.push(txtValue);
      } else {
      }
    }
    setCitesValue(arr);
  };

  const handleChangeState = (value) => {
    setSearchStateValue(upperCase(value.toLowerCase()));
    // states.push(value)
  };

  function flatDeep(arr, d = 1) {
    if (!Array.isArray(arr)) {
      return arr;
    }
    return d > 0
      ? arr.reduce((acc, val) => acc.concat(flatDeep(val, d - 1)), [])
      : arr.slice();
  }

  useEffect(() => {
    const cityArr = Object.values(data);
    const value = flatDeep(cityArr, cityArr.length);
    let uniqueChars = [...new Set(value)];
    setCitesValue(uniqueChars);
  }, []);

  const amenities = [
    "Bedroom",
    "Energy",
    "Ac",
    "Dishwasher",
    "Washroom",
    "Gym",
    "Kitchen",
    "Roof",
    "Fridge",
    "Yard",
    "Washer and Dryer",
    "Outdoor areas",
    "Cable ready",
    "Swimming pool",
    "Dining Area",
    "Gated Access",
    "Master Suite",
    "24/7 Water",
    "Pets allowed",
    "Internet access",
  ];

  return (
    <StyledForm wh={isModal ? "100" : "100"} smwh="100" xswh="100" xxswh="100">
      <StyledForms>
        {isModal && (
          <StyledTitle>{isEdit ? "Update" : "List "} Your Property</StyledTitle>
        )}

        {/* <ManagePropertyBtn>
          <img className="resman__img" src="/images/Resman.png" />
          <p>Want to add property directly through Resman? </p>
          <Link to="/apartment-resman">
            LINK HERE <IoIosArrowForward />
          </Link>
        </ManagePropertyBtn>
        <ManagePropertyBtnSm>
          <div className="smScreen__btn">
            <img className="resman__img" src="/images/Resman.png" />
            <h3>
              LINK HERE <IoIosArrowForward />
            </h3>
          </div>
          <p>Want to add property directly through Resman? </p>
        </ManagePropertyBtnSm>
        <Row justify="center" className="or__option">
          <Col
            span={11}
          >
            <Divider />
          </Col>
          <Col span={2}>
            <p>OR</p>
          </Col>
          <Col
            span={11}
          >
            <Divider />
          </Col>
        </Row> */}

        <Form
          layout="vertical"
          // requiredMark={"optional"}
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
          onValuesChange={() => {
            setDataChange(true);
            setShowPrompt(true);
            onFormChange(true);
          }}
        >
          <StyledFormSelect wh="49" smwh="100">
            <Form.Item
              name="unitType"
              label="Select Property Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Property Type"
                allowClear
                suffixIcon={<IoIosArrowDown />}
                // disabled={selectedApartment}
              >
                <Option value="Single Family Home">Single-Family Home</Option>
                <Option value="Condo">Condo</Option>
                <Option value="Townhouse">Townhouse</Option>
                <Option value="Apartment">Apartment</Option>
                <Option value="Farm/Ranch">Farm/Ranch</Option>
                <Option value="Multi-Family">Multi-Family</Option>
                <Option value="Commercial">Commercial</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="unit_name"
              label="Property Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Property Name" />
            </Form.Item>
          </StyledFormSelect>
          {/* <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="unit_name"
              label="Property Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Property Name" />
            </Form.Item>
          </StyledFormItem> */}
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="description"
              label="Description"
              // rules={[{ required: true }]}
            >
              <Input placeholder="Description" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem smwh="100">
            <Form.Item
              name="address"
              label="Address"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item
              name="phonenumber"
              label="Contact Number"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input type="number" placeholder="Contact Number" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
            <Form.Item
              name="city"
              label="City"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select
                showSearch
                placeholder="City"
                // onChange={handleChange}
                onSearch={handleChange}
                suffixIcon={<IoIosArrowDown />}
              >
                {citesValue
                  ? citesValue.sort().map((cites, id) => (
                      <Option key={id} value={cites}>
                        {cites}
                      </Option>
                    ))
                  : ""}
              </Select>
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select
                showSearch
                placeholder="State"
                onChange={onChangeState}
                onSearch={handleChangeState}
                suffixIcon={<IoIosArrowDown />}
              >
                {statesValue.length &&
                  statesValue.sort().map((state, id) => (
                    <Option key={id} value={state}>
                      {state}
                    </Option>
                  ))}
                {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
              </Select>
            </Form.Item>
            <Form.Item
              name="zipcode"
              label="Zip code"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input type="number" placeholder="Zip code" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="images"
              label="Property Images"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadBox ref={drop} onClick={(e) => upload.current.click()}>
                <UploadInput>
                  <p className="ant-upload-text" style={{ fontSize: "16px" }}>
                    Upload Image of Property
                  </p>
                  <p className="ant-upload-drag-icon">
                    <AiOutlineUpload className="upload__icon" />
                  </p>
                  <p className="upload__icon">Upload</p>
                </UploadInput>
              </UploadBox>
              <input
                accept="image/*"
                id="myInput"
                multiple={"multiple"}
                type="file"
                ref={upload}
                style={{ display: "none" }}
                onChange={fileChangeHandler}
              />
              <div style={{ display: "flex" }}>
                {state.map((img, idx) =>
                  img.isCover ? (
                    <StyledUploadPreviewWrapperCover isPhoto={state.length}>
                      <CoverImage
                        src={img.path}
                        height={95}
                        width={95}
                        onDelete={() => {
                          const st = [...state];
                          // st.splice(0, 1);
                          st.splice(idx, 1);
                          setState(st);
                          // upload.current.value = null;
                        }}
                      />
                      <StyledCover>
                        <p>Cover</p>
                      </StyledCover>
                    </StyledUploadPreviewWrapperCover>
                  ) : (
                    <StyledUploadPreviewWrapper isPhoto={state.length}>
                      <ImagePreview
                        key={idx}
                        src={img.path ? img.path : imagePath(img.path)}
                        height={95}
                        width={95}
                        onDelete={() => {
                          const st = [...state];
                          st.splice(idx, 1);
                          setState(st);

                          // upload.current.value = null;
                        }}
                        onCover={() => {
                          const st = [...state];
                          setState(st);
                          setSelectImgIdx(idx);
                          onCoverChange(idx);
                        }}
                      />
                    </StyledUploadPreviewWrapper>
                  )
                )}
                {/* <StyledUploadPreviewWrapperCover isPhoto={state.length}>
                  <CoverImage
                    src={
                      state[selectImgIdx]?.path
                        ? state[selectImgIdx]?.path
                        : imagePath(state[selectImgIdx]?.path)
                    }
                    height={95}
                    width={95}
                    onDelete={() => {
                      const st = [...state];
                      st.splice(0, 1);
                      setState(st);
                      // upload.current.value = null;
                    }}
                  />
                  <StyledCover>
                    <p>Cover</p>
                  </StyledCover>
                </StyledUploadPreviewWrapperCover>
                <StyledUploadPreviewWrapper isPhoto={state.length}>
                  {state.map((img, idx) => (
                    <>
                      {selectImgIdx === idx ? (
                        " "
                      ) : (
                        <ImagePreview
                          key={idx}
                          src={img.path ? img.path : imagePath(img.path)}
                          height={95}
                          width={95}
                          onDelete={() => {
                            const st = [...state];
                            st.splice(idx, 1);
                            setState(st);

                            // upload.current.value = null;
                          }}
                          onCover={() => {
                            const st = [...state];
                            setState(st);
                            setSelectImgIdx(idx);
                            onCoverChange(idx);
                          }}
                        />
                      )}
                    </>
                  ))}
                </StyledUploadPreviewWrapper> */}

                {/* <StyledUploadPreviewWrapper isPhoto={updateUnit[0]?.images}>
                  {updateUnit[0]?.images?.map((img, idx) => (
                    <ImagePreview
                      key={idx}
                      src={img.url ? img.url : imagePath(img.path)}
                      height={95}
                      width={95}
                      onDelete={() => {
                        const st = [...state];
                        st.splice(idx, 1);
                        setState(st);

                        // upload.current.value = null;
                      }}
                      onCover={() => {
                        const st = [...state];
                        setState(st);
                        setSelectImgIdx(idx);
                      }}
                    />
                  ))}
                </StyledUploadPreviewWrapper> */}
              </div>
            </Form.Item>
          </StyledFormItem>

          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="ammenities"
              label="Amenities"
              // rules={[{ required: true }]}
            >
              <PropertyBtn
                br={viewAmmenities ? "5px 5px 0 0" : ""}
                onClick={() => setViewAmmenities(!viewAmmenities)}
              >
                <p>Property Amenities</p>
                {!viewAmmenities ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </PropertyBtn>
              {viewAmmenities && (
                <DropDownView>
                  <Checkbox.Group
                    defaultValue={selectAmmenities}
                    style={{ width: "100%" }}
                    onChange={onChange}
                  >
                    <Row justify="space-between">
                      {amenities?.slice(0, amenitiesLength).map((value, id) => (
                        <Col key={id} xs={10} sm={6}>
                          <Checkbox value={value}>{value}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>

                  {amenitiesLength === amenities.length ? (
                    ""
                  ) : (
                    <Row>
                      <Col style={{ paddingTop: "15px", cursor: "pointer" }}>
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            setAmenitiesLength(amenities.length);
                          }}
                        >
                          VIEW MORE
                        </span>
                      </Col>
                    </Row>
                  )}
                </DropDownView>
              )}
              {!viewAmmenities && (
                <Row gutter={[10, 10]}>
                  {selectAmmenities.map((val, id) => (
                    <Col key={id}>
                      <ListWrap>
                        <p>{val}</p>
                        <MdClear onClick={() => removeAmmenities(val)} />
                      </ListWrap>
                    </Col>
                  ))}
                </Row>
              )}
            </Form.Item>
          </StyledFormItem>
          {/* <StyledFormItem smwh="100">
            <Form.Item
              name="multi_property"
              label="Multi Unit Property"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Checkbox onChange={onChangeMultiProperty}>
                Multi Unit Property
              </Checkbox>
            </Form.Item>
          </StyledFormItem> */}
          <StyledFormItem smwh="100" wh="32">
            <Form.Item
              name="numberOfUnit"
              label="How Many Units"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                placeholder="How Many Units"
                onChange={onChangeMultiPropertyUpDown}
                type="number"
                min={1}
                // max={10}
              />
              {/* <Input
                // disabled={!multiProperty}
                type="number"
                placeholder="How Many Units"
                onChange={onChangeMultiProperty}
              /> */}
            </Form.Item>
            <Form.Item
              name="default_rent"
              label="Rent"
              className="input__affix"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                prefix={<BsCurrencyDollar className="site-form-item-icon" />}
                disabled={multiProperty}
                type="number"
                placeholder="Rent"
                step="any"
              />
            </Form.Item>
            <Form.Item
              name="default_security"
              label="Security"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                disabled={multiProperty}
                type="number"
                placeholder="Security"
                step="any"
              />
            </Form.Item>
          </StyledFormItem>
          {!multiProperty ? (
            <>
              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="sqft"
                  label="Sq ft"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input type="number" placeholder="Sq ft" />
                </Form.Item>
                <Form.Item
                  name="bed"
                  label="Bed"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input type="number" placeholder="Bed" />
                </Form.Item>
                <Form.Item
                  name="bath"
                  label="Bath"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input type="number" placeholder="Bath" />
                </Form.Item>
              </StyledFormItem>

              {/* <StyledFormItem fd={"row"}>
                <Form.Item
                  name="price"
                  label="Price"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input type="number" placeholder="Price" />
                </Form.Item>
                <Form.Item
                  name="diposit"
                  label="Deposit Amount"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input type="number" placeholder="Deposit Amount" />
                </Form.Item>
              </StyledFormItem> */}
            </>
          ) : (
            ""
          )}
          <Form.Item>
            <StyledButton
              disabled={disableBtn}
              loading={loadings}
              htmlType="submit"
            >
              {isEdit ? "Update" : "List "} This Property
            </StyledButton>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
}

export default ListApartment;
