import React, { useState, useEffect } from "react";
import { Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledFormSelect,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  createTenants,
  getAllApartment,
  getAllOrg,
  getUnitsByUserId,
  inviteTenants,
  updateTenant,
} from "../../store/reducers/user.reducer";
import { IoIosArrowDown } from "react-icons/io";
import { AccountCreateLogo, SendInvitationSvg } from "../../assets/svgs";
import moment from "moment";
import styled from "styled-components";
import { BsCurrencyDollar } from "react-icons/bs";

function AddTenantsForm({
  isModal = false,
  isEdit = false,
  onCancel,
  tenantDetails,
  newAddedVal = console.log,
  updatedTenat = console.log,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { user } = useSelector((state) => state.user);
  const [loadings, setloadings] = useState(false);
  const [isSave, setIsSave] = useState(true);
  const [getAllUnits, setGetAllUnits] = useState({});
  const [getAllApartmentObj, setGetAllApartment] = useState({});
  const [getAllOrgObj, setGetAllOrgObj] = useState({});
  const [getAllUnitsArr, setGetAllUnitsArr] = useState([]);
  const [allApartmentArr, setGetAllApartmentArr] = useState([]);
  const [getAllOrgArr, setGetAllOrgArr] = useState([]);
  const [getSelectApartment, setGetSelectApartment] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [unitType, setUnitType] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [apaName, setApaName] = useState("");
  const [unit_name, setUnitName] = useState("");
  const [apaphonenumber, setApaphonenumber] = useState("");
  const [apaAddress, setApaAddress] = useState("");
  const [orgApartment, setOrgApartment] = useState([]);
  const [invitationValue, setInvitationValue] = useState({});
  const [unitDetails, setUnitDetails] = useState({});

  useEffect(() => {
    if (isEdit && tenantDetails) {
      form.setFieldsValue({
        firstName: tenantDetails?.firstName,
        lastName: tenantDetails?.lastName,
        email: tenantDetails?.email,
        phoneNumber: tenantDetails?.phoneNumber.slice(2),
        apartment_id: tenantDetails?.apartment_id,
        unit_id: tenantDetails?.unit_id,
        leaseStart: moment(tenantDetails?.leaseStart),
        leaseTerm: tenantDetails?.leaseTerm,
        leaseEnd: moment(tenantDetails?.leaseEnd),
        rate: tenantDetails?.rate,
      });
    }
  }, [tenantDetails, isEdit]);

  useEffect(() => {
    if (getAllUnits && Object.keys(getAllUnits).length) {
      const arr = Object.values(getAllUnits);
      setGetAllUnitsArr(arr);
    } else {
      setGetAllUnitsArr([]);
    }
  }, [getAllUnits]);

  useEffect(() => {
    if (getAllApartmentObj && Object.keys(getAllApartmentObj).length) {
      const arr = Object.values(getAllApartmentObj);
      setGetAllApartmentArr(arr);
    } else {
      setGetAllApartmentArr([]);
    }
  }, [getAllApartmentObj]);

  useEffect(() => {
    if (allApartmentArr?.length && !localStorage.getItem("viewAll")) {
      const availableTenants = allApartmentArr.filter((item) => {
        return item["org_id"] == localStorage.getItem("org_id");
      });
      setOrgApartment(availableTenants);
    } else if (localStorage.getItem("viewAll")) {
      setOrgApartment(allApartmentArr);
    }
  }, [allApartmentArr]);

  useEffect(() => {
    if (getAllOrgObj && Object.keys(getAllOrgObj).length) {
      const arr = Object.values(getAllOrgObj);
      setGetAllOrgArr(arr);
    } else {
      setGetAllOrgArr([]);
    }
  }, [getAllOrgObj]);

  useEffect(() => {
    dispatch(getUnitsByUserId()).then(({ payload }) => {
      setGetAllUnits(payload);
    });
  }, []);

  useEffect(() => {
    dispatch(getAllApartment()).then(({ payload }) => {
      setGetAllApartment(payload);
    });
  }, []);

  useEffect(() => {
    dispatch(getAllOrg()).then(({ payload }) => {
      setGetAllOrgObj(payload);
    });
  }, []);

  useEffect(() => {
    if (allApartmentArr?.length) {
      const launchOptimistic = allApartmentArr?.map((elem, idx) => ({
        ...elem,
        key: idx + 1,
        children: getAllUnitsArr.filter(function (item) {
          return item["apartment_id"] === elem.id;
        }),
      }));
    }
  }, [allApartmentArr, getAllUnitsArr]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onChange = (date, dateString) => {
    setSelectedDate(date);
    form.setFieldsValue({
      leaseEnd: null,
    });
  };

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day}-${month}-${year}`;
  }

  const [newDayTerm, setNewDayTerm] = useState("");

  const dateConverter = (dateValue, month) => {
    if (selectedDate) {
      var date = new Date(selectedDate);
      var newDate = new Date(
        date.setMonth(date.getMonth() + Number(dateValue?.substring(0, 2)))
      );
      var newDay = new Date(newDate.setDate(newDate.getDate() - 1));

      setNewDayTerm(newDay);

      form.setFieldsValue({
        leaseEnd: moment(newDay),
      });

      return formatDate(newDay);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    if (selectedDate) {
      return current && current < moment(newDayTerm)?.endOf("day");
    }
  };

  const changeHandler = (value) => {
    var orgValue = getAllUnitsArr?.filter(function (item) {
      return item["id"] === value;
    });
    if (orgValue?.length) {
      setUnitType(orgValue[0]?.unitType);
      setUnitNumber(orgValue[0]?.unitNumber);
      setUnitName(orgValue[0]?.unit_name);
      setUnitDetails(orgValue[0]);

      form.setFieldsValue({
        unitType: orgValue[0]?.unitType,
        unitNumber: orgValue[0]?.unitNumber,
      });
    }
  };

  const changeHandlerApart = (value) => {
    var orgValue = getAllUnitsArr?.filter(function (item) {
      return item["apartment_id"] === value;
    });
    setGetSelectApartment(orgValue);
    // if (unitNumber) {
    form.setFieldsValue({
      unit_id: null,
    });
    // }

    var orgValueApa = allApartmentArr?.filter(function (item) {
      return item["id"] === value;
    });
    var orgValueOrg = getAllOrgArr?.filter(function (item) {
      return item["id"] === orgValueApa[0]?.org_id;
    });
    setOrgName(orgValueOrg[0]?.org_name);
    setOrgId(orgValueOrg[0]?.id);
    setApaName(orgValueApa[0]?.unit_name);
    setApaAddress(orgValueApa[0]?.address ? orgValueApa[0]?.address : "");
    setApaphonenumber(
      orgValueApa[0]?.phonenumber ? orgValueApa[0]?.phonenumber : ""
    );
  };

  const onFinish = (value) => {
    if (isEdit) {
      value.phoneNumber = `+1${value.phoneNumber}`;
      value.id = tenantDetails?.id;
      value.user_id = tenantDetails?.user_id;
      value.unitType = tenantDetails?.unitType;
      value.unitNumber = tenantDetails?.unitNumber;
      value.org_name = tenantDetails?.org_name;
      value.org_id = tenantDetails?.org_id;
      value.apartment_name = tenantDetails?.apartment_name;
      value.unit_name = tenantDetails?.unit_name;
      value.address = tenantDetails?.address;
      value.apaphonenumber = tenantDetails?.apaphonenumber;
      value.leaseStart = value.leaseStart?.format("DD-MMM-YYYY");
      value.leaseEnd = value.leaseEnd?.format("DD-MMM-YYYY");
      value.isSeen = tenantDetails?.isSeen;
      value.unitObj = tenantDetails?.unitObj;
      value.paidAmount = tenantDetails?.paidAmount;
      value.invitaionRequest = tenantDetails?.invitaionRequest;
      setloadings(true);

      dispatch(updateTenant(value)).then(({ payload }) => {
        updatedTenat(payload);
        setloadings(false);
        onCancel();
      });
    } else {
      value.user_id = user.id;
      value.unitType = unitType;
      value.phoneNumber = `+1${value.phoneNumber}`;
      value.unitNumber = unitNumber;
      value.org_name = orgName;
      value.org_id = orgId;
      value.apartment_name = apaName;
      value.unit_name = unit_name;
      value.address = apaAddress;
      value.apaphonenumber = apaphonenumber;
      value.leaseStart = value.leaseStart?.format("DD-MMM-YYYY");
      value.leaseEnd = value.leaseEnd?.format("DD-MMM-YYYY");
      value.isSeen = false;
      value.unitObj = unitDetails;
      value.paidAmount = "0";
      value.invitaionRequest = "sent";

      // value.dueDate = value.dueDate?.format("DD-MMM-YYYY");
      setloadings(true);

      dispatch(createTenants(value)).then(({ payload }) => {
        newAddedVal(payload);
        setloadings(false);
        setIsSave(false);
        setInvitationValue(payload);
        // onCancel();
      });
    }
  };

  const inviteChanger = () => {
    setloadings(true);
    // const forTenantsId = {...invitationValue}
    // forTenantsId
    const objCopy = { ...invitationValue };
    objCopy.tenant_id = invitationValue.id;

    dispatch(inviteTenants(objCopy)).then(({ payload }) => {
      onCancel();
      message.success(payload.message);
      setloadings(false);
    });
  };

  const prefixSelector = (
    <SelectArrow>
      <Form.Item name="prefix" noStyle>
        {/* <span>+1</span> */}
        <Select
          style={{
            width: 70,
          }}
          defaultValue="+1"
        >
          <Option value="+1">+1</Option>
        </Select>
      </Form.Item>
    </SelectArrow>
  );

  return (
    <>
      {isSave ? (
        <StyledForm
          wh={isModal ? "100" : "100"}
          smwh="100"
          xswh="100"
          xxswh="100"
        >
          <StyledForms>
            {isModal && (
              <StyledTitle>{isEdit ? "Update" : "Add New"} Tenant</StyledTitle>
            )}

            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem smwh="100">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem smwh="100">
                <Form.Item
                  name="email"
                  label="Email"
                  // rules={[
                  //   {
                  //     required: true,
                  //     type: "email",
                  //   },
                  // ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  label="Contact Number"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    disabled={isEdit}
                    addonBefore={prefixSelector}
                    type="number"
                    placeholder="Contact Number"
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormSelect smwh="100" wh="49">
                <Form.Item
                  name="apartment_id"
                  label="Select Property"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Property"
                    // allowClear
                    suffixIcon={<IoIosArrowDown />}
                    disabled={isEdit}
                    onChange={changeHandlerApart}
                  >
                    {orgApartment?.map((item, idx) => (
                      <Option key={idx} value={item.id}>
                        {item.unit_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="unit_id"
                  label="Select Unit"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Unit"
                    // allowClear
                    suffixIcon={<IoIosArrowDown />}
                    disabled={isEdit}
                    onChange={changeHandler}
                  >
                    {(getSelectApartment?.length
                      ? getSelectApartment
                      : getAllUnitsArr
                    )?.map((item, idx) =>
                      !item.tenant ? (
                        <Option key={idx} value={item.id}>
                          {item.unit_name}
                        </Option>
                      ) : (
                        ""
                      )
                    )}
                  </Select>
                </Form.Item>
              </StyledFormSelect>
              <StyledFormItem smwh="100">
                {/* <Form.Item name="dueDate" label="Due Date">
              <DatePicker
                disabledDate={disabledDate}
                allowClear={false}
                onChange={onChange}
              />
            </Form.Item> */}
                <Form.Item name="leaseStart" label="Lease Start">
                  <DatePicker allowClear={false} onChange={onChange} />
                </Form.Item>
                <Form.Item
                  name="leaseTerm"
                  label="Lease Term"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Select
                    placeholder="Lease Term"
                    // allowClear
                    suffixIcon={<IoIosArrowDown />}
                    // disabled={isEdit}
                    onChange={dateConverter}
                  >
                    <Option value="03 Months">03 Months</Option>
                    <Option value="06 Months">06 Months</Option>
                    <Option value="09 Months">09 Months</Option>
                    <Option value="12 Months">12 Months</Option>
                    <Option value="18 Months">18 Months</Option>
                    <Option value="24 Months">24 Months</Option>
                    <Option value="30 Months">30 Months</Option>
                    <Option value="36 Months">36 Months</Option>
                  </Select>
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem smwh="100" wh="49">
                {/* <Form.Item
              name="balance"
              label="Balance"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input type="number" placeholder="Balance" />
            </Form.Item> */}
                <Form.Item name="leaseEnd" label="Lease End">
                  <DatePicker
                    disabledDate={disabledDate}
                    allowClear={false}
                    // onChange={onChange}
                  />
                </Form.Item>

                <Form.Item
                  name="rate"
                  label="Rent"
                  className="input__affix"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input
                    prefix={
                      <BsCurrencyDollar className="site-form-item-icon" />
                    }
                    step="any"
                    type="number"
                    placeholder="Rent"
                  />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <StyledButton loading={loadings} htmlType="submit">
                  {isEdit ? "Update" : "Save"}
                </StyledButton>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      ) : (
        <ThanksWrap
          style={{ padding: "0" }}
          boxWidth="100"
          smwh="100"
          xswh="100"
          xxswh="100"
        >
          {/* <img className="vector" src="/Vector.svg" /> */}
          <SendInvitationSvg />
          <h2 className="avenew__signup">Send Invite to Tenant</h2>
          <h1 className="personal__profile">
            Tenant Information has been saved successfully in Avenew system
          </h1>
          {/* <StyledButton loading={loadings} htmlType="submit"> */}
          <Form.Item>
            <Row justify="center">
              <Col xs={18} sm={18} md={18} lg={16} xl={18} xxl={18}>
                <StyledButton
                  br="10px"
                  loading={loadings}
                  onClick={inviteChanger}
                >
                  Invite
                </StyledButton>
              </Col>
            </Row>
          </Form.Item>
        </ThanksWrap>
      )}
    </>
  );
}

export default AddTenantsForm;

export const SelectArrow = styled.div`
  .ant-select-arrow {
    display: none;
  }
`;

const ThanksWrap = styled.div`
  text-align: center;
  background-color: #ffffff;
  width: ${({ boxWidth }) => boxWidth || "40"}%;
  padding: 50px;
  margin: ${({ margin }) => margin || "auto"};
  position: unset;
  top: 0;
  left: 0;
  transform: none;

  .avenew__signup {
    padding-top: 45px;
    color: ${({ theme }) => theme.colors.forth};
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
  }

  .personal__profile {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #777777;
    margin-bottom: 30px;
  }

  @media (max-width: 1550px) {
    padding: 40px;

    .avenew__signup {
      padding-top: 35px;
      font-size: 32px;
      line-height: 40px;
    }

    .personal__profile {
      font-size: 18px;
      line-height: 25px;
    }
  }

  @media (max-width: 425px) {
    width: 100%;
    transform: none;
    top: 0;
    left: 0;
    /* this is for background image */
    background: transparent;
  }
  @media (max-width: 625px) {
    .avenew__signup {
      font-size: 26px;
    }
    .personal__profile {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    .avenew__signup {
      font-size: 26px;
    }
    .personal__profile {
      font-size: 16px;
    }
  }
  @media (max-width: 411px) {
    .avenew__signup {
      font-size: 20px;
    }
    .personal__profile {
      font-size: 25px;
    }
  }
  @media (max-width: 341px) {
    .avenew__signup {
      font-size: 17px;
    }
    .personal__profile {
      font-size: 22px;
    }
  }
  @media (max-width: 1199px) {
    width: ${({ smwh }) => smwh || "50"}%;
  }
  @media (max-width: 768px) {
    width: ${({ xswh }) => xswh || "50"}%;
    padding: 25px;
  }
  @media (max-width: 576px) {
    width: ${({ xxswh }) => xxswh || "50"}%;
  }
`;
