import React, { useEffect, useState } from "react";
import { Container } from "../../ui/Layout/Container";
import {
  Row,
  Col,
  Skeleton,
  Spin,
  Form,
  Switch,
  Table,
  Popover,
  Space,
} from "antd";
import { Heading } from "../../ui/Heading/Heading";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { StyledButton } from "../createPersonalProfile/RegistrationStyle";
import AddApartmentModal from "../addApartmentModal/AddApartmentModal";
import { Card } from "../../ui/Card";
import {
  AddNewApr,
  StyledApparCard,
  StyledApparCardWrapper,
  StyledApparContentWrapper,
} from "./Listingstyle";
import ApartmentSection from "./ApartmentSection";
import { apartmentDummyData } from "./dummy.data";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  ApartmentTable,
  FloatButton,
  SecondaryButton,
} from "../apartments-view/ApartmentsViewStyle";
import { HiPlus } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllApartment,
  getAllOrg,
  getApartment,
  getOrg,
  getUnitsByUserId,
} from "../../store/reducers/user.reducer";
import NoListing from "../no-listing page/NoListing";
import { EditIconWrap } from "../apartments-view/Actions";
import { EditSvg } from "../../assets/svgs";
import UpdateUnitModal from "../addApartmentModal/UpdateUnitModal";
import UpdateApartmentModal from "../addApartmentModal/UpdateApartmentModal";
import InviteTenantsModal from "../addTenantsModal/InviteTenantsModal";
import { FcInfo } from "react-icons/fc";
import {
  MyAccountWrapRight,
  NameWrap,
  RightSide,
  TenantsDetails,
  RightSideWrap,
} from "../tenants/Tenants";
import { UserAvatar } from "../../ui/Avatar";

function Listings({ name, displayShow = true }) {
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [modalStateInvite, setModalStateInvite] = useState(false);
  const [loadingIn, setLoadingIn] = useState(true);
  const [loadings, setLoadings] = useState(false);
  const [allApartments, setAllApartments] = useState({});
  const [apartments, setApartments] = useState([]);
  const [valueOfOrg, setValueOfOrg] = useState([]);
  const [waitForApart, setWaitForApart] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [allPropUnit, setAllPropUnit] = useState([]);
  const [sortAllPropUnit, setSortAllPropUnit] = useState([]);
  const [getAllUnit, setGetAllUnit] = useState({});
  const [inviteTenant, setInviteTenant] = useState({});
  const [getAllUnitArr, setGetAllUnitArr] = useState([]);
  const [maxBed, setMaxBed] = useState(0);
  const [minBed, setMinBed] = useState(0);
  const [modalStateUpdate, setModalStateUpdate] = useState(false);
  const [valueChanger, setValueChanger] = useState("false");
  const [allApartmentForModal, setAllApartmentForModal] = useState([]);
  const [selectedUnitFromTable, setSelectedUnitFromTable] = useState([]);
  const [heading, setHeading] = useState("All Property");

  const valueOfSelect = allApartmentForModal[0];
  const { allOrganization } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.user);
  const [getAllApartmentState, setGetAllApartment] = useState({});
  const [getAllApartmentStateArr, setGetAllApartmentStateArr] = useState({});

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUnitsByUserId()).then(({ payload }) => {
      setGetAllUnit(payload);
    });
  }, []);

  useEffect(() => {
    if (params.id) {
      var arr = [];

      for (var item in allOrganization) {
        if (allOrganization.hasOwnProperty(item)) {
          arr.push(allOrganization[item]);
        }
      }

      var orgValue = arr.filter(function (item) {
        return item["id"] == localStorage.getItem("toSave");
      });
      setValueOfOrg(orgValue);
    } else {
      if (localStorage.getItem("toSave")) {
        var arr = [];

        for (var item in allOrganization) {
          if (allOrganization.hasOwnProperty(item)) {
            arr.push(allOrganization[item]);
          }
        }

        var orgValue = arr.filter(function (item) {
          return item["id"] == localStorage.getItem("toSave");
        });
        setValueOfOrg(orgValue);
      }
    }
  }, [params.id, heading, allOrganization, localStorage.getItem("toSave")]);

  useEffect(() => {
    if (valueOfOrg && valueOfOrg.length) {
      setHeading(valueOfOrg[0]?.org_name);
    }
  }, [valueOfOrg]);

  useEffect(() => {
    if (params.id && localStorage.getItem("toSave")) {
      // localStorage.setItem("org_id", params.id);
      localStorage.removeItem("viewAll");
    }
  }, [params.id, localStorage.getItem("toSave")]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (params.id || localStorage.getItem("toSave")) {
      setIsLoading(true);
      dispatch(getApartment()).then(({ payload }) => {
        setAllApartments(payload);
        setWaitForApart(false);
        setIsLoading(false);

        if (payload.Error) {
          if (payload.Error.includes("not found")) {
            navigate("/");
          }
        }
      });
    }
  }, [params.id, localStorage.getItem("toSave")]);

  useEffect(() => {
    if (allApartments && Object.keys(allApartments).length) {
      const arr = Object.values(allApartments);
      setApartments(arr);
      setWaitForApart(false);
    } else {
      setWaitForApart(true);
      setApartments([]);
    }
  }, [allApartments]);

  useEffect(() => {
    if (getAllUnit && Object.keys(getAllUnit).length) {
      const arr = Object.values(getAllUnit);
      const arrOfSort = [];

      let sortForeach = arr.map((obj) => {
        return obj.unit_name;
      });
      sortForeach.sort();

      sortForeach?.map((val) => {
        arr.filter((obj) => {
          if (obj["unit_name"] === val) {
            arrOfSort.push(obj);
          }
        });
      });

      // console.log(arr, "arrOfSort");

      setGetAllUnitArr(arr);
      setWaitForApart(false);
    } else {
      setWaitForApart(true);
      setGetAllUnitArr([]);
    }
  }, [getAllUnit]);

  useEffect(() => {
    if (allPropUnit?.length) {
      const arrOfSort = [];
      allPropUnit?.map((value) => {
        let sortForeach = value.children.map((val) => {
          return val.unit_name;
        });
        sortForeach.sort();

        sortForeach?.map((val) => {
          value.children.filter((obj) => {
            if (obj["unit_name"] === val) {
              arrOfSort.push(obj);
            }
          });
        });
      });

      const launchOptimistic = apartments?.map((elem, idx) => ({
        ...elem,
        key: idx + 1,
        orgName: getAllOrgArr?.filter(function (item) {
          return item["id"] === elem.org_id;
        }),
        children: arrOfSort.filter(function (item) {
          return item["apartment_id"] === elem.id;
        }),
      }));
      setSortAllPropUnit(launchOptimistic);
    }
  }, [allPropUnit]);

  useEffect(() => {
    setTimeout(() => setLoadingIn(false), 400);
  }, []);

  const { width } = useWindowSize();
  const location = useLocation();
  const [padding, setPadding] = useState("50px 50px 50px 330px");

  useEffect(() => {
    if (width < 1550 && width > 991) {
      setPadding("40px 40px 40px 265px");
    } else {
      setPadding("");
    }
  }, [width, location.pathname]);

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  const handleHeaderChange = (enable) => {
    setShowHeader(enable);
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  const columns = [
    {
      title: "Unit Type",
      dataIndex: "unitType",
      key: "unitType",
      width: "12%",
    },

    {
      title: "Units Name",
      dataIndex: "unit_name",
      key: "unit_name",
      width: "15%",
    },
    {
      title: "Sub Unit",
      dataIndex: "numberOfUnit",
      key: "numberOfUnit",
    },
    {
      title: "Sq.ft",
      dataIndex: "sqft",
      key: "sqft",
    },
    {
      title: "Bed",
      dataIndex: "bed",
      key: "bed",
    },
    {
      title: "Bath",
      dataIndex: "bath",
      key: "bath",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Status",
      dataIndex: "unitStatus",
      key: "unitStatus",
      render: (_, record) => {
        let color = "red";
        if (record.unitStatus === "Sold") {
          color = "#E04141";
        } else if (record.unitStatus === "Contract Sent") {
          color = "#4160AC";
        } else if (record.unitStatus === "Available") {
          color = "#58D255";
        } else if (record.unitStatus === "Pending") {
          color = "#F67C36";
        } else if (record.unitStatus === "Configure") {
          color = "#F67C36";
        }
        return (
          <>
            {record.unitStatus === "Configure" ? (
              <span
                style={{ color: color }}
                key={record.unitStatus}
                onClick={(event) => {
                  editValue(record);
                  event.stopPropagation();
                }}
              >
                {record.unitStatus}
              </span>
            ) : (
              <span style={{ color: color }} key={record.unitStatus}>
                {record.unitStatus}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Tenants",
      dataIndex: "tenants",
      key: "tenants",
      render: (_, record) => {
        return (
          <>
            {record.tenant?.id ? (
              <>
                <Popover
                  placement="left"
                  // trigger="click"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  content={
                    <RightSideWrap
                      style={{ width: "400px" }}
                      padding="15px"
                      minH="auto"
                    >
                      <RightSide>
                        <NameWrap>
                          <UserAvatar
                            className="userName__icon"
                            fullName={"Mark Jecno"}
                            // fullName={`${firstName || ""} ${lastName || ""}`}
                            // src={
                            //   item?.user?.avatar?.path
                            //     ? imagePath(item?.user?.avatar?.path)
                            //     : ""
                            // }
                            src={"/images/tenants.png"}
                          />
                        </NameWrap>
                        <MyAccountWrapRight>
                          <Row justify="space-between">
                            <Col>
                              <h2>
                                {upperCase(record.tenant?.firstName)}{" "}
                                {upperCase(record.tenant?.lastName)}
                              </h2>
                              <p>
                                {formatPhoneNumber(record.tenant?.phoneNumber)}
                              </p>
                            </Col>
                          </Row>
                        </MyAccountWrapRight>
                      </RightSide>
                      <TenantsDetails>
                        {record.tenant?.org_name ? (
                          <Row>
                            <Col span={12}>
                              <span>Organization Name</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.org_name}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {record.tenant?.apartment_name ? (
                          <Row>
                            <Col span={12}>
                              <span>Property Name</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.apartment_name}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {record.tenant?.unit_name ? (
                          <Row>
                            <Col span={12}>
                              <span>Unit Name</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.unit_name}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {record.tenant?.unitNumber ? (
                          <Row>
                            <Col span={12}>
                              <span>Unit Number</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.unitNumber}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant?.unitType ? (
                          <Row>
                            <Col span={12}>
                              <span>Unit Type</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.unitType}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant?.leaseTerm ? (
                          <Row>
                            <Col span={12}>
                              <span>Lease Term</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.leaseTerm}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant?.leaseStart ? (
                          <Row>
                            <Col span={12}>
                              <span>Lease Start</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.leaseStart}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant?.leaseEnd ? (
                          <Row>
                            <Col span={12}>
                              <span>Lease End</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.leaseEnd}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant?.dueDate ? (
                          <Row>
                            <Col span={12}>
                              <span>Due Date</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.dueDate}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant?.rate ? (
                          <Row>
                            <Col span={12}>
                              <span>Rent</span>
                            </Col>
                            <Col span={12}>
                              <p>
                                {toUSACurrency(Number(record.tenant?.rate))}
                              </p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant?.balance ? (
                          <Row>
                            <Col span={12}>
                              <span>Balance</span>
                            </Col>
                            <Col span={12}>
                              <p>
                                {toUSACurrency(Number(record.tenant?.balance))}
                              </p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant?.email ? (
                          <Row>
                            <Col span={12}>
                              <span>Tenant Email</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant?.email}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </TenantsDetails>
                    </RightSideWrap>
                  }
                >
                  <span key={record.tenant?.id}>
                    {upperCase(record.tenant?.firstName)}{" "}
                    {upperCase(record.tenant?.lastName)}
                  </span>
                </Popover>
              </>
            ) : !record.children ? (
              <span
                onClick={(event) => {
                  setModalStateInvite(true);
                  event.stopPropagation();
                  setInviteTenant(record);
                }}
                style={{ color: "#F67C36" }}
              >
                Invite &gt;
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      align: "right",
      width: "8%",
      render: (_, record) => {
        return (
          <EditIconWrap
            onClick={(event) => {
              editValue(record);
              event.stopPropagation();
            }}
          >
            <EditSvg />
          </EditIconWrap>
        );
      },
    },
  ];
  const editValue = (value) => {
    setAllApartmentForModal([value]);
    setModalStateUpdate(true);
  };

  const getUpdatedApartment = (value) => {
    if (value && Object.keys(value).length) {
      if (apartments.length) {
        const objIndex = apartments.findIndex((obj) => obj.id === value.id);
        apartments[objIndex] = value;
        setValueChanger(value);
      }
    }
  };

  const getUpdatedUnit = (value) => {
    if (value && Object.keys(value).length) {
      if (apartments.length) {
        const objIndex = getAllUnitArr.findIndex((obj) => obj.id === value.id);
        getAllUnitArr[objIndex] = value;
        setValueChanger(value);
        setGetAllApartmentStateArr((val) => [...val, value]);
      }
    }
  };
  const [getAllOrgObj, setGetAllOrgObj] = useState({});
  const [getAllOrgArr, setGetAllOrgArr] = useState([]);

  useEffect(() => {
    dispatch(getAllOrg()).then(({ payload }) => {
      setGetAllOrgObj(payload);
    });
  }, []);

  useEffect(() => {
    if (getAllOrgObj && Object.keys(getAllOrgObj).length) {
      const arr = Object.values(getAllOrgObj);
      setGetAllOrgArr(arr);
      setValueChanger(arr);
    } else {
      setGetAllOrgArr([]);
      setValueChanger([]);
    }
  }, [getAllOrgObj]);

  useEffect(() => {
    if (getAllUnitArr) {
      var arr = [];
      for (var item in getAllUnit) {
        if (getAllUnit.hasOwnProperty(item)) {
          arr.push(getAllUnit[item]);
        }
      }

      if (apartments.length) {
        const newChild = getAllUnitArr?.map((ele, idx) => ({
          ...ele,
          unitType: ele.unitType,
        }));

        const launchOptimistic = apartments?.map((elem, idx) => ({
          ...elem,
          key: idx + 1,
          orgName: getAllOrgArr?.filter(function (item) {
            return item["id"] === elem.org_id;
          }),
          children: newChild.filter(function (item) {
            return item["apartment_id"] === elem.id;
          }),
        }));
        setAllPropUnit(launchOptimistic);
      }
    }
  }, [apartments, getAllUnitArr, valueChanger, showHeader]);

  const [tableLoading, setTableLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    size: "small",
  });

  const fetchData = (params = {}) => {
    setTableLoading(true);
    setPagination({
      ...params.pagination,
      total: apartments.length, // 200 is mock data, you should read it from server
      // total: data.totalCount,
    });
    setTimeout(() => {
      setTableLoading(false);
    }, 500);

    //   });
  };

  const newListedApartment = (value) => {
    setApartments((val) => [...val, value]);
    setShowHeader(true);
    setGetAllApartmentStateArr((val) => [...val, value]);
  };

  const newListedSubUnit = (value) => {
    setGetAllUnitArr((val) => [...val, value]);
  };
  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };
  const [yScroll, setyScroll] = useState(550);

  useEffect(() => {
    if (params.id) {
      dispatch(getAllApartment()).then(({ payload }) => {
        setGetAllApartment(payload);
      });
    }
  }, []);

  useEffect(() => {
    if (getAllApartmentState && Object.keys(getAllApartmentState).length) {
      const arr = Object.values(getAllApartmentState);
      setGetAllApartmentStateArr(arr);
    } else {
      setGetAllApartmentStateArr([]);
    }
  }, [getAllApartmentState]);

  useEffect(() => {
    if (selectedUnitFromTable && Object.values(selectedUnitFromTable).length) {
      if (selectedUnitFromTable?.children) {
        navigate(`/subunit/${selectedUnitFromTable?.id}`);
      } else {
        navigate({
          pathname: `/subunit/${selectedUnitFromTable?.apartment_id}`,
          search: createSearchParams({
            unitId: selectedUnitFromTable?.id,
          }).toString(),
        });
      }
    }
  }, [selectedUnitFromTable]);

  const newAddedVal = (value) => {
    dispatch(getUnitsByUserId()).then(({ payload }) => {
      setGetAllUnit(payload);
    });
    // console.log(value, "value of fun");
    // const val = allPropUnit?.filter((item) =>{
    //   return item["id"] === value.apartment_id
    // })
    // console.log(val, "val");
  };

  return (
    <>
      {displayShow && (
        <>
          {loading ? (
            <Container padding="0 50px 0px 330px">
              <div
                style={{
                  position: "relative",
                  minHeight: "calc(100vh - 80px)",
                }}
              >
                <Spin className="ant__spin" size="large" />
              </div>
            </Container>
          ) : apartments && apartments?.length ? (
            <Container padding={padding} paddingSm={padding}>
              <>
                <Row justify="space-between">
                  <Col>
                    <Heading>{upperCase(heading)}</Heading>
                  </Col>
                  <Col style={{ display: "flex" }}>
                    <Form.Item
                      label="Table View"
                      style={{ marginRight: "20px" }}
                    >
                      <Switch
                        checked={showHeader}
                        onChange={handleHeaderChange}
                      />
                    </Form.Item>
                    <AddNewApr
                      bgColor={"#777777"}
                      fw={"400"}
                      displayView="none"
                      onClick={() => setModalState(true)}
                    >
                      Add New Property
                    </AddNewApr>
                  </Col>
                </Row>
                {!showHeader ? (
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <StyledApparCardWrapper wd={"100"} ml={"0"}>
                        <Row gutter={[30, 30]}>
                          {loadingIn &&
                            [...Array(6).keys()].map((v) => (
                              <Col
                                key={v}
                                xs={24}
                                sm={12}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                              >
                                <StyledApparCard>
                                  <Skeleton.Image
                                    active={loadingIn}
                                    style={{ width: "100%" }}
                                  ></Skeleton.Image>
                                  {/* <StyledVideoProgressWrapper></StyledVideoProgressWrapper> */}
                                  <StyledApparContentWrapper mh={"0"}>
                                    <Skeleton.Input
                                      active={loadingIn}
                                      style={{
                                        width: "100%",
                                        height: "20px",
                                        marginBottom: 20,
                                      }}
                                    ></Skeleton.Input>
                                    <Skeleton.Input
                                      active={loadingIn}
                                      style={{
                                        width: "100%",
                                        height: 20,
                                        marginBottom: 20,
                                      }}
                                    ></Skeleton.Input>
                                    <Skeleton.Input
                                      active={loadingIn}
                                      style={{
                                        width: "100%",
                                        height: 20,
                                        marginBottom: 20,
                                      }}
                                    ></Skeleton.Input>
                                  </StyledApparContentWrapper>
                                </StyledApparCard>
                              </Col>
                            ))}
                        </Row>
                      </StyledApparCardWrapper>
                      <ApartmentSection
                        apartmentDummyData={apartmentDummyData}
                        apartments={apartments}
                        apartmentsWithUnits={allPropUnit}
                      />
                    </Col>
                  </Row>
                ) : (
                  <ApartmentTable background={"#9DA8B3"}>
                    <Table
                      defaultExpandAllRows={true}
                      columns={columns}
                      dataSource={[...sortAllPropUnit]}
                      onChange={handleTableChange}
                      pagination={pagination}
                      loading={tableLoading}
                      scroll={{ x: 1300, y: yScroll }}
                      onRow={(r) => ({
                        onClick: (event) => {
                          // navigate({
                          //   pathname: `/subunit/${r.id}`,
                          //   search: createSearchParams({
                          //     unitId: "hello",
                          //   }).toString(),
                          // });
                          event.isDefaultPrevented();
                          setSelectedUnitFromTable(r);
                        },
                      })}
                    />
                  </ApartmentTable>
                )}
              </>
              <FloatButton>
                <SecondaryButton
                  shape="circle"
                  icon={<HiPlus className="plus_icon" />}
                  onClick={() => setModalState(true)}
                />
              </FloatButton>
            </Container>
          ) : !isLoading ? (
            <NoListing
              newListedSubUnit={newListedSubUnit}
              newListedApartment={newListedApartment}
            />
          ) : (
            <Container padding="0 50px 0px 330px">
              <div
                style={{
                  position: "relative",
                  minHeight: "calc(100vh - 80px)",
                }}
              >
                <Spin className="ant__spin" size="large" />
              </div>
            </Container>
          )}
        </>
      )}
      {!allApartmentForModal[0]?.children ? (
        <UpdateUnitModal
          onCancel={(value) => setModalStateUpdate(value)}
          modalState={modalStateUpdate}
          isEdit={true}
          updateUnit={valueOfSelect}
          // getUpdatedUnit={getUpdatedUnit}
          getAllApartmentState={getAllApartmentStateArr}
          getUpdatedUnit={getUpdatedUnit}
        />
      ) : (
        <UpdateApartmentModal
          onCancel={(value) => setModalStateUpdate(value)}
          modalState={modalStateUpdate}
          isEdit={true}
          updateUnit={allApartmentForModal}
          getUpdatedApartment={getUpdatedApartment}
        />
      )}
      <AddApartmentModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        newListedApartment={newListedApartment}
        newListedSubUnit={newListedSubUnit}
      />
      <InviteTenantsModal
        onCancel={(value) => setModalStateInvite(value)}
        modalState={modalStateInvite}
        newAddedVal={newAddedVal}
        inviteTenant={inviteTenant}
      />
    </>
  );
}

export default Listings;
