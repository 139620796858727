import { Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { CgFileDocument } from "react-icons/cg";
import { FaTrash } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components";
import { updateApplication } from "../../store/reducers/user.reducer";
import { imagePath } from "../../utils/fn";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  StyledUploadPreviewWrapper,
  UploadBox,
  UploadInput,
} from "../apartment-listing/ListApartmentStyle";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../createPersonalProfile/RegistrationStyle";
import { CoverImage, ImagePreview } from "../ImagePreview";
import { AtchBox } from "../messages/messagesStyle";
import { ButtonWrap, CurrentStagWrap, StatusButton } from "./ApplicationsStyle";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextEditor from "../textEditor/TextEditor";

function SendLease({ selectedApplication, updatedApplication }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [state, setState] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);
  const upload = React.useRef(null);
  const attachmentType = ["image/svg+xml", "application/pdf"];
  const location = useLocation();
  const [value, setValue] = useState("");
  const { width } = useWindowSize();

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const MAX_LENGTH = 4;

  const fileChangeHandler = async (event) => {
    // const imgType =
    if (Array.from(event.target.files).length > MAX_LENGTH) {
      event.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      return;
    }
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  const fileChangeHandlerDrag = async (files) => {
    if (Array.from(files).length > MAX_LENGTH) {
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      files.preventDefault();
      return;
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    await Promise.all(
      Array(...files).map(async (img) => {
        const convertImg = await convertToBase64(img);
        setState((s) => [
          {
            file: convertImg,
            path: URL.createObjectURL(img),
            name: img.name,
            ext: img.type,
          },
        ]);
      })
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const drop = React.useRef(null);

  React.useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      leaseAgreementDoc: state,
    });
  }, [form, state]);

  useEffect(() => {
    // if (selectedApplication?.leaseAgreementDoc) {
    //   setState([selectedApplication?.leaseAgreementDoc]);
    // }

    if (selectedApplication?.leaseAgreementText) {
      form.setFieldsValue({
        leaseAgreementText: selectedApplication?.leaseAgreementText,
      });
    }
  }, [form, selectedApplication]);

  const onFinish = (value) => {
    if (selectedApplication?.isApprove) {
      value.isApprove = selectedApplication.isApprove;
      value.adminUserId = selectedApplication.adminUserId;
      value.backgroundCheckVerified =
        selectedApplication.backgroundCheckVerified;
      value.creditScoreVerified = selectedApplication.creditScoreVerified;
      value.id = selectedApplication.id;
      value.incomeVerified = selectedApplication.incomeVerified;
      value.leaseLength = selectedApplication.leaseLength;
      value.previousRental = selectedApplication.previousRental;
      value.prospectUserId = selectedApplication.prospectUserId;
      value.selectedDate = selectedApplication.selectedDate;
      value.unitId = selectedApplication.unitId;
      value.leaseAgreementDoc = state.length
        ? state[0]
        : selectedApplication?.leaseAgreementDoc
        ? selectedApplication.leaseAgreementDoc
        : "";

      setSendLoading(true);
      dispatch(updateApplication(value)).then(({ payload }) => {
        console.log(payload, "payload >>>");
        updatedApplication(payload);
        setSendLoading(false);
      });
    } else {
      message.info("Please approve application first");
    }
  };

  const download = (e, name) => {
    const link = document.createElement("a");
    // link.setAttribute("download", "images.jfif");
    link.href = e;

    document.body.appendChild(link);
    link.click();
  };
  const [stringSort, setStringSort] = useState(8);

  useEffect(() => {
    if (width < 768) {
      setStringSort(8);
    } else {
      setStringSort(30);
    }
  }, [width, location.pathname]);

  const slpitFunc = (value) => {
    if (value?.length > 28) {
      return value.slice(0, stringSort) + "..." + value.slice(-8);
    } else {
      return value;
    }
  };

  // const textAreaChang = (text) => {
  //   // console.log(text.replace(/(.{30})/g,"$1asas"))
  //   function chunk(str, n) {
  //     var ret = [];
  //     var i;
  //     var len;

  //     for (i = 0, len = str.length; i < len; i += n) {
  //       ret.push(str.substr(i, n));
  //     }

  //     return ret;
  //   }

  //   form.setFieldsValue({
  //     leaseAgreementText: chunk(text, 58).join("/n"),
  //   });

  //   // console.log(chunk(text, 58).join("/n"));
  // };

  const OnChangeHandler = (e) => {
    console.log(e, "e");
  };

  return (
    <>
      <CurrentStagWrap>
        <h2>Step : 3 Send Lease & Deposit</h2>
      </CurrentStagWrap>
      <SendLeaseWrap>
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="leaseAgreementDoc"
                  label="Upload Lease Agreement"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <UploadBox ref={drop} onClick={(e) => upload.current.click()}>
                    <UploadInput>
                      <p className="ant-upload-drag-icon">
                        <AiOutlineUpload className="upload__icon" />
                      </p>
                      <p className="upload__icon">Upload</p>
                    </UploadInput>
                  </UploadBox>
                  <input
                    // accept="application/pdf , application/vnd.ms-excel, image/*"
                    accept="application/pdf"
                    id="myInput"
                    // multiple={"multiple"}
                    type="file"
                    ref={upload}
                    style={{ display: "none" }}
                    onChange={fileChangeHandler}
                  />
                  <div style={{ display: "flex" }}>
                    {state.map((img, idx) => (
                      <StyledUploadPreviewWrapper isPhoto={state.length}>
                        {attachmentType.includes(img.ext) ? (
                          <ObjWrap>
                            <object
                              data={img.path}
                              type="application/pdf"
                              width="100%"
                              // height="100%"
                              height="400"
                            >
                              <p>
                                Alternative text - include a link{" "}
                                <a href={img.path}>to the PDF!</a>
                              </p>
                            </object>
                            <h3>
                              <IoIosClose
                                onClick={() => setState([])}
                                className="modal_close_icon"
                              />
                            </h3>
                          </ObjWrap>
                        ) : (
                          <ImagePreview
                            key={idx}
                            src={img.path ? img.path : imagePath(img.path)}
                            height={95}
                            width={95}
                            onDelete={() => {
                              const st = [...state];
                              st.splice(idx, 1);
                              setState(st);
                            }}
                            isCoverDisProp={false}
                          />
                        )}
                      </StyledUploadPreviewWrapper>
                    ))}
                  </div>
                  {selectedApplication?.leaseAgreementDoc ? (
                    <AtchBox
                      style={{ marginTop: "30px" }}
                      onClick={() =>
                        download(
                          selectedApplication?.leaseAgreementDoc.path,
                          selectedApplication?.leaseAgreementDoc.name
                        )
                      }
                    >
                      <CgFileDocument />
                      <h5>
                        {slpitFunc(selectedApplication?.leaseAgreementDoc.name)}
                      </h5>
                    </AtchBox>
                  ) : (
                    ""
                  )}
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="leaseAgreementText"
                  label="Make a New Agreement"
                  // rules={[{ required: true }]}
                >
                  <TextEditor onChange={setValue} />
                  {/* <Input.TextArea
                    className="description__box"
                    placeholder="Type your..."
                    // onChange={(e) => textAreaChang(e.target.value)}
                  /> */}
                </Form.Item>
              </StyledFormItem>

              <ButtonWrap padding="0">
                <Row gutter={[10, 10]}>
                  <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                    <StatusButton
                      className="status__button"
                      bgColor={"#4160AC"}
                    >
                      <span>Message Applicant</span>
                    </StatusButton>
                  </Col>
                  <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                    <StatusButton
                      className="status__button"
                      bgColor={"#F67C36"}
                      htmlType="submit"
                      loading={sendLoading}
                    >
                      <span>Send Lease</span>
                    </StatusButton>
                  </Col>
                </Row>
              </ButtonWrap>
            </Form>
          </StyledForms>
        </StyledForm>
      </SendLeaseWrap>
    </>
  );
}

export default SendLease;

const ObjWrap = styled.div`
  display: flex;
  svg {
    cursor: pointer;
  }
`;

const SendLeaseWrap = styled.div`
  padding: 15px;

  .description__box {
    height: 170px;
  }

  .upload__icon {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #777777;
  }

  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
  }

  @media (max-width: 1550px) {
    padding: 10px;
  }
`;
