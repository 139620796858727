import React, { useState, useEffect } from "react";
import { Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledFormSelect,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  createTenants,
  getAllApartment,
  getAllOrg,
  getUnitsByUserId,
  inviteTenants,
} from "../../store/reducers/user.reducer";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import { ThanksWrap } from "../thanksPage/ThanksPageStyle";
import { AccountCreateLogo, SendInvitationSvg } from "../../assets/svgs";
import { SelectArrow } from "./AddTenantsForm";

function InviteTenantsForm({
  isModal = false,
  isEdit = false,
  onCancel,
  newAddedVal = console.log,
  inviteTenant,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { user } = useSelector((state) => state.user);
  const [loadings, setloadings] = useState(false);
  const [getAllUnits, setGetAllUnits] = useState({});
  const [getAllUnitsArr, setGetAllUnitsArr] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [unitType, setUnitType] = useState("");
  const [isSave, setIsSave] = useState(true);
  const [getAllOrgObj, setGetAllOrgObj] = useState({});
  const [getAllOrgArr, setGetAllOrgArr] = useState([]);
  const [getAllApartmentObj, setGetAllApartment] = useState({});
  const [allApartmentArr, setGetAllApartmentArr] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [apaName, setApaName] = useState("");
  const [apaAddress, setApaAddress] = useState("");
  const [apaphonenumber, setApaphonenumber] = useState("");
  const [invitationValue, setInvitationValue] = useState({});

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day}-${month}-${year}`;
  }

  const [newDayTerm, setNewDayTerm] = useState("");

  const dateConverter = (dateValue, month) => {
    if (selectedDate) {
      var date = new Date(selectedDate);
      var newDate = new Date(
        date.setMonth(date.getMonth() + Number(dateValue?.substring(0, 2)))
      );
      var newDay = new Date(newDate.setDate(newDate.getDate() - 1));

      setNewDayTerm(newDay);

      form.setFieldsValue({
        leaseEnd: moment(newDay),
      });

      return formatDate(newDay);
    }
  };

  useEffect(() => {
    if (getAllUnits && Object.keys(getAllUnits).length) {
      const arr = Object.values(getAllUnits);
      setGetAllUnitsArr(arr);
    } else {
      setGetAllUnitsArr([]);
    }
  }, [getAllUnits]);

  useEffect(() => {
    if (getAllOrgObj && Object.keys(getAllOrgObj).length) {
      const arr = Object.values(getAllOrgObj);
      setGetAllOrgArr(arr);
    } else {
      setGetAllOrgArr([]);
    }
  }, [getAllOrgObj]);

  useEffect(() => {
    if (getAllApartmentObj && Object.keys(getAllApartmentObj).length) {
      const arr = Object.values(getAllApartmentObj);
      setGetAllApartmentArr(arr);
    } else {
      setGetAllApartmentArr([]);
    }
  }, [getAllApartmentObj]);

  useEffect(() => {
    dispatch(getUnitsByUserId()).then(({ payload }) => {
      setGetAllUnits(payload);
    });
  }, []);

  useEffect(() => {
    dispatch(getAllOrg()).then(({ payload }) => {
      setGetAllOrgObj(payload);
    });
  }, []);
  useEffect(() => {
    dispatch(getAllApartment()).then(({ payload }) => {
      setGetAllApartment(payload);
    });
  }, []);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onChange = (date, dateString) => {
    setSelectedDate(date);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    if (selectedDate) {
      return current && current < selectedDate?.endOf("day");
    }
  };
  const changeHandler = (value) => {
    var orgValue = getAllUnitsArr.filter(function (item) {
      return item["id"] === value;
    });
    if (orgValue?.length) {
      setUnitType(orgValue[0]?.unitType);
      form.setFieldsValue({
        unitType: orgValue[0]?.unitType,
      });
    }
  };

  useEffect(() => {
    if (inviteTenant && inviteTenant?.id) {
      var orgValueApa = allApartmentArr?.filter(function (item) {
        return item["id"] === inviteTenant.apartment_id;
      });
      var orgValueOrg = getAllOrgArr?.filter(function (item) {
        return item["id"] === orgValueApa[0]?.org_id;
      });

      setOrgName(orgValueOrg[0]?.org_name);
      setOrgId(orgValueOrg[0]?.id);
      setApaName(orgValueApa[0]?.unit_name);
      setApaAddress(orgValueApa[0]?.address ? orgValueApa[0]?.address : "");
      setApaphonenumber(
        orgValueApa[0]?.phonenumber ? orgValueApa[0]?.phonenumber : ""
      );
    }
  }, [inviteTenant, allApartmentArr, getAllOrgArr]);

  const onFinish = (value) => {
    value.user_id = user.id;
    value.unitType = inviteTenant?.unitType;
    value.phoneNumber = `+1${value.phoneNumber}`;
    value.unitNumber = inviteTenant?.unitNumber;
    value.org_name = orgName;
    value.org_id = orgId;
    value.apartment_name = apaName;
    value.unit_name = inviteTenant?.unit_name;
    value.address = apaAddress;
    value.apaphonenumber = apaphonenumber;
    value.leaseStart = value.leaseStart?.format("DD-MMM-YYYY");
    value.leaseEnd = value.leaseEnd?.format("DD-MMM-YYYY");
    value.apartment_id = inviteTenant?.apartment_id;
    value.unit_id = inviteTenant?.id;
    value.isSeen = false;
    value.paidAmount = "0";
    value.invitaionRequest = "sent";

    setloadings(true);

    dispatch(createTenants(value)).then(({ payload }) => {
      newAddedVal(value);
      setloadings(false);
      // onCancel();
      setIsSave(false);
      setInvitationValue(payload);
    });
  };

  const inviteChanger = () => {
    setloadings(true);
    // const forTenantsId = {...invitationValue}
    // forTenantsId
    const objCopy = { ...invitationValue };
    objCopy.tenant_id = invitationValue.id;

    dispatch(inviteTenants(objCopy)).then(({ payload }) => {
      message.success(payload.message);
      setloadings(false);
      onCancel();
    });
  };

  const prefixSelector = (
    <SelectArrow>
      <Form.Item name="prefix" noStyle>
        {/* <span>+1</span> */}
        <Select
          style={{
            width: 70,
          }}
          defaultValue="+1"
        >
          <Option value="+1">+1</Option>
        </Select>
      </Form.Item>
    </SelectArrow>
  );

  return (
    <>
      {isSave ? (
        <StyledForm
          wh={isModal ? "100" : "100"}
          smwh="100"
          xswh="100"
          xxswh="100"
        >
          <StyledForms>
            {isModal && (
              <StyledTitle>{isEdit ? "Update" : "Add "} New Tenant</StyledTitle>
            )}

            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem smwh="100">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem smwh="100">
                <Form.Item name="email" label="Email">
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  label="Contact Number"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    type="number"
                    placeholder="Contact Number"
                  />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem smwh="100">
                <Form.Item name="leaseStart" label="Lease Start">
                  <DatePicker allowClear={false} onChange={onChange} />
                </Form.Item>
                <Form.Item name="leaseTerm" label="Lease Term">
                  <Select
                    placeholder="Lease Term"
                    suffixIcon={<IoIosArrowDown />}
                    disabled={isEdit}
                    onChange={dateConverter}
                  >
                    <Option value="03 Months">03 Months</Option>
                    <Option value="06 Months">06 Months</Option>
                    <Option value="09 Months">09 Months</Option>
                    <Option value="12 Months">12 Months</Option>
                    <Option value="18 Months">18 Months</Option>
                    <Option value="24 Months">24 Months</Option>
                    <Option value="30 Months">30 Months</Option>
                    <Option value="36 Months">36 Months</Option>
                  </Select>
                </Form.Item>
              </StyledFormItem>
              <StyledFormSelect smwh="100" wh="49">
                <Form.Item name="leaseEnd" label="Lease End">
                  <DatePicker disabledDate={disabledDate} allowClear={false} />
                </Form.Item>

                <Form.Item name="rate" label="Rent">
                  <Input type="number" placeholder="Rent" />
                </Form.Item>
              </StyledFormSelect>

              <Form.Item>
                <StyledButton loading={loadings} htmlType="submit">
                  save
                </StyledButton>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      ) : (
        <ThanksWrap
          style={{ padding: "0" }}
          boxWidth="100"
          smwh="100"
          xswh="100"
          xxswh="100"
        >
          {/* <img className="vector" src="/Vector.svg" /> */}
          <SendInvitationSvg />
          <h2 className="avenew__signup">Send Invite to Tenant</h2>
          <h1 className="personal__profile">
            Tenant Information has been saved successfully in Avenew system
          </h1>
          {/* <StyledButton loading={loadings} htmlType="submit"> */}
          <Form.Item>
            <Row justify="center">
              <Col xs={18} sm={18} md={18} lg={16} xl={18} xxl={18}>
                <StyledButton
                  br="10px"
                  loading={loadings}
                  onClick={inviteChanger}
                >
                  Invite
                </StyledButton>
              </Col>
            </Row>
          </Form.Item>
        </ThanksWrap>
      )}
    </>
  );
}

export default InviteTenantsForm;
