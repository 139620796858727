import React, { useState } from "react";
import { Modal } from "antd";
import { IoIosClose } from "react-icons/io";
import { useBeforeunload } from "react-beforeunload";
import UserSettings from "./UserSettings";

function UserSettingsModal({ modalState, onCancel }) {
  const [isFormChange, setIsFormChange] = useState(false);

  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });
  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          if (!isFormChange) {
            onCancel(false);
          } else {
            openPrompt(
              "You will lose all changes if you navigate away from this page without saving"
            );
          }
        }}
        width={"700px"}
        footer={null}
        style={{ borderRadius: "5px"}}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <UserSettings
          isModal={true}
          // onFormChange={fromChanges}
        />
      </Modal>
    </>
  );
}

export default UserSettingsModal;
