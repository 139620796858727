import styled from "styled-components";
import { Button } from "antd";

export const JoinCompanyMain = styled.div`
  display: flex;
`;

export const JoinCompanyLeft = styled.div`
  padding-top: 140px;
  padding-bottom: 140px;
//   min-height: 100vh;
  width: 50%;
  background-color: #eeeeee;

  .avenew__signup {
    color: #777777;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }

  .personal__profile {
    color: #323548;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
  }

  .company__details {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #777777;
    margin-bottom: 33px;
  }

  .or__option {
    align-items: center;
    margin-top: 18px;
    margin-bottom: 18px;
    p {
      margin: 0 15px 0 15px;
      font-weight: 400;
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
 
`;

export const JoinCompanyRight = styled.div`
//   min-height: 100vh;
  width: 50%;
  background-color: #aaaaaa;
  @media (max-width: 768px) {
    display: none;
  }
`;
