import React from "react";

const listings = ({
  size = "22",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3571 2.85714C21.5543 2.85714 21.7143 2.69714 21.7143 2.5V1.78571C21.7143 0.800714 20.9135 0 19.9285 0H19.2143C19.0171 0 18.8571 0.16 18.8571 0.357143C18.8571 0.554286 19.0171 0.714286 19.2143 0.714286H19.9285C20.5193 0.714286 21 1.195 21 1.78571V2.5C21 2.69714 21.16 2.85714 21.3571 2.85714Z"
        fill="#777777"
      />
      <path
        d="M0.285706 18.2143V17.5C0.285706 17.3028 0.445706 17.1428 0.642848 17.1428H15.6428C15.84 17.1428 16 17.3028 16 17.5V18.2143C16 18.805 16.4807 19.2857 17.0714 19.2857C17.2686 19.2857 17.4286 19.4457 17.4286 19.6428C17.4286 19.84 17.2686 20 17.0714 20H2.07142C1.08642 20 0.285706 19.1993 0.285706 18.2143ZM15.2857 18.2143V17.8571H0.999991V18.2143C0.999991 18.805 1.48071 19.2857 2.07142 19.2857H15.6436C15.4193 18.9871 15.2857 18.6164 15.2857 18.2143Z"
        fill="#777777"
      />
      <path
        d="M15.2936 11.4357C15.1543 11.5757 15.1543 11.8014 15.2936 11.9414L16.3036 12.9514C16.4428 13.0907 16.6693 13.0907 16.8086 12.9514C16.9478 12.8121 16.9478 12.5857 16.8086 12.4464L15.7986 11.4357C15.6586 11.2957 15.4328 11.2957 15.2936 11.4357Z"
        fill="#777777"
      />
      <path
        d="M11.7143 13.5714C8.56357 13.5714 6 11.0078 6 7.85711C6 4.70639 8.56357 2.14282 11.7143 2.14282C12.9 2.14282 14.0371 2.50282 15.0036 3.18425C15.165 3.29711 15.2036 3.52068 15.09 3.68139C14.9764 3.84282 14.7529 3.88211 14.5921 3.76711C13.7471 3.17139 12.7521 2.85711 11.7143 2.85711C8.95714 2.85711 6.71429 5.10068 6.71429 7.85711C6.71429 10.6135 8.95714 12.8571 11.7143 12.8571C14.4714 12.8571 16.7143 10.6135 16.7143 7.85711C16.7143 7.34354 16.6371 6.83782 16.4843 6.35354C16.425 6.16497 16.5293 5.96425 16.7179 5.90568C16.9064 5.84782 17.1064 5.95068 17.1657 6.13854C17.34 6.69282 17.4286 7.27139 17.4286 7.85711C17.4286 11.0078 14.865 13.5714 11.7143 13.5714Z"
        fill="#777777"
      />
      <path
        d="M17.4286 12.8571C17.6114 12.8571 17.7943 12.9271 17.9336 13.0664L20.7907 15.9235C21.07 16.2028 21.07 16.6543 20.7907 16.9335C20.6514 17.0728 20.4686 17.1428 20.2857 17.1428C20.1029 17.1428 19.92 17.0728 19.7807 16.9335L16.9236 14.0764C16.6443 13.7971 16.6443 13.3457 16.9236 13.0664C17.0629 12.9271 17.2457 12.8571 17.4286 12.8571ZM17.4286 12.1428C17.0471 12.1428 16.6886 12.2914 16.4186 12.5614C16.1486 12.8314 16 13.19 16 13.5714C16 13.9528 16.1486 14.3114 16.4186 14.5814L19.2757 17.4385C19.5457 17.7085 19.9043 17.8571 20.2857 17.8571C20.6671 17.8571 21.0257 17.7085 21.2957 17.4385C21.5657 17.1685 21.7143 16.81 21.7143 16.4285C21.7143 16.0471 21.5657 15.6885 21.2957 15.4185L18.4386 12.5614C18.1686 12.2914 17.81 12.1428 17.4286 12.1428Z"
        fill="#777777"
      />
      <path
        d="M11.3571 9.99998C11.3543 9.99998 11.3514 9.99998 11.3486 9.99998C11.245 9.99712 11.1486 9.95069 11.0821 9.87141L9.29641 7.72855C9.17069 7.5764 9.19141 7.3514 9.34212 7.22498C9.49427 7.09926 9.71855 7.11998 9.84569 7.27069L11.3693 9.09998L16.4507 3.68712C16.585 3.54141 16.8107 3.53283 16.955 3.66498C17.1 3.79855 17.11 4.02426 16.9764 4.16998L11.62 9.88426C11.5521 9.95783 11.4578 9.99998 11.3571 9.99998Z"
        fill="#777777"
      />
      <path
        d="M19.9285 0.714286C20.1257 0.714286 20.2857 0.554286 20.2857 0.357143C20.2857 0.16 20.1257 0 19.9285 0H5.64283C4.65783 0 3.85712 0.800714 3.85712 1.78571V16.0714C3.85712 16.2686 4.01712 16.4286 4.21426 16.4286C4.4114 16.4286 4.5714 16.2686 4.5714 16.0714V1.78571C4.5714 1.195 5.05212 0.714286 5.64283 0.714286H18.5007C18.2764 1.01286 18.1428 1.38429 18.1428 1.78571V11.0714C18.1428 11.2686 18.3028 11.4286 18.5 11.4286C18.6971 11.4286 18.8571 11.2686 18.8571 11.0714V1.78571C18.8571 1.195 19.3378 0.714286 19.9285 0.714286Z"
        fill="#777777"
      />
      <path
        d="M17.0714 19.2857H15.8214V20H17.0714C18.0564 20 18.8571 19.1993 18.8571 18.2143C18.8571 18.0172 18.6971 17.8572 18.5 17.8572C18.3028 17.8572 18.1428 18.0172 18.1428 18.2143C18.1428 18.805 17.6621 19.2857 17.0714 19.2857Z"
        fill="#777777"
      />
    </svg>
  );
};

export default listings;
