import React from "react";

const lessthanIcon = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15.2788L8.31771 17L-1.16426e-06 8.5L8.31771 -1.47071e-07L10 1.72125L3.36979 8.5L10 15.2788Z"
        fill="#2B353F"
      />
    </svg>
  );
};

export default lessthanIcon;
