import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Space,
  Popover,
  Input,
  message,
} from "antd";
import { FcInfo } from "react-icons/fc";
import { Heading } from "../../ui/Heading/Heading";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HeaderSearch from "../layout/header/HeaderSearch";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import CheckboxMenu from "./CheckboxMenu";
import { MessagesSvg } from "../../assets/svgs";
import { BiDetail, BiSearch } from "react-icons/bi";
import { createChat, getAllTenants } from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import { TableOneWrap } from "./TableOne";
import styled from "styled-components";
import { AddNewApr } from "../listings/Listingstyle";
import AddTenantsModal from "../addTenantsModal/AddTenantsModal";
import {
  FloatButton,
  SecondaryButton,
} from "../apartments-view/ApartmentsViewStyle";
import { HiPlus } from "react-icons/hi";
import { ContainerWrap } from "../../ui/Layout/ContainerWrap";
import { UserAvatar } from "../../ui/Avatar";
import LoadingTopPage from "../feture-soon/LoadingTopPage";

function Tenants() {
  const [modalState, setModalState] = useState(false);
  const [isClickMessage, setIsClickMessage] = useState(false);

  const [dayLeft, setDayLeft] = useState(0);
  const dispatch = useDispatch();

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day}-${month}-${year}`;
  }

  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);
    var newDate = new Date(date.setMonth(date.getMonth() + month));
    var newDay = new Date(newDate.setDate(newDate.getDate() - 1));

    return formatDate(newDay);
  };

  const dayLeftCounter = (dateValue, month) => {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    var date = new Date(dateValue);
    var newDate = new Date(date.setMonth(date.getMonth() + month));
    var newDay = new Date(newDate.setDate(newDate.getDate()));

    let date_ini = new Date();
    let date_end = new Date(formatDate(newDay));
    let diff = date_end.getTime() - date_ini.getTime();
    const val = Math.floor(diff / day);

    if (Math.sign(val) === -1) {
      return "Lease Expired";
    } else {
      return `${val} Left`;
    }
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = React.useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // icon={<BiSearch />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <BiSearch
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <BiSearch
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // const balanceColor = (value) => {
  //   if (Number(value.rate) - Number(value.balance) < 0) {
  //     return (
  //       <td style={{ color: "green" }}>
  //         {toUSACurrency(Math.abs(Number(value.rate) - Number(value.balance)))}
  //       </td>
  //     );
  //   } else if (Number(value.rate) - Number(value.balance) === 0) {
  //     return (
  //       <td style={{ color: "rgb(33, 150, 243)" }}>
  //         {toUSACurrency(Number(value.rate) - Number(value.balance))}
  //       </td>
  //     );
  //   } else {
  //     return (
  //       <td style={{ color: "#e73030" }}>
  //         {toUSACurrency(Number(value.rate) - Number(value.balance))}
  //       </td>
  //     );
  //   }
  // };

  const dueDateColor = (value) => {
    if (Number(value.rate) - Number(value.balance) < 0) {
      return (
        <p style={{ color: "green" }}>
          Balance :{" "}
          {/* {toUSACurrency(
            Math.abs(Number(value.rate) - Number(value.balance))
          )} */}
        </p>
      );
    } else if (Number(value.rate) - Number(value.balance) === 0) {
      return (
        <p style={{ color: "rgb(33, 150, 243)" }}>
          Balance :{" "}
          {/* {toUSACurrency(Number(value.rate) - Number(value.balance))} */}
        </p>
      );
    } else {
      return (
        <p style={{ color: "#F67C36 " }}>
          Balance :{" "}
          {/* {toUSACurrency(Number(value.rate) - Number(value.balance))} */}
        </p>
      );
    }
  };

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const invitaionstatus = (key) => {
    switch (key) {
      case "sent":
        return "black";
      case "approved":
        return "#3650f6";
      case "denied":
        return "red";
      case "cancel":
        return "orange";
    }
  };

  const chatCreate = (value) => {
    if (value.id) {
      setIsClickMessage(true);
      const data = {};
      data.tenant_id = value.id;
      data.unit_id = value.unit_id;
      data.unit_name = value.unit_name;
      dispatch(createChat(data)).then(({ payload }) => {
        setIsClickMessage(false);

        if (payload.chat) {
          navigate({
            pathname: `/messages`,
            search: createSearchParams({
              chatId: payload.chat.id,
            }).toString(),
          });
        } else if(payload.Error) {
          message.info("Tenant hasn't responded to your invitation yet.")
        }
      });
    }
  };

  const columns = [
    {
      title: "TENANT NAME",
      dataIndex: "tenantName",
      width: 170,
      // ...getColumnSearchProps("tenantName"),
      render: (_, record) => (
        <div>
          <td>
            {upperCase(record.firstName)} {upperCase(record.lastName)}
          </td>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 170,
      // getColumnSearchProps("tenantName"),
      // render: (_, record) => (
      //   <div>
      //     <td>
      //       {upperCase(record.firstName)} {upperCase(record.lastName)}
      //     </td>
      //   </div>
      // ),
    },
    {
      title: "UNIT",
      dataIndex: "unitNumber",
      width: 250,
      render: (_, record) => (
        <span>
          {record.unit_name}, {record.apartment_name}
        </span>
      ),
    },
    {
      title: "UNIT TYPE",
      dataIndex: "unitType",
      width: 120,
    },
    {
      title: "LEASE TERM",
      dataIndex: "leaseTerm",
    },
    {
      title: "LEASE START",
      dataIndex: "leaseStart",
    },
    {
      title: "LEASE END",
      dataIndex: "leaseEnd",
    },
    {
      title: "DUE DATE",
      dataIndex: "dueDate",
      render: (_, record) => (
        <div>
          <Space size="middle">
            <td>
              {record.leaseStart
                ? dateConverter(
                    record?.leaseStart,
                    Number(record?.leaseTerm?.substring(0, 2))
                  )
                : ""}
            </td>
            <Popover
              content={
                <div>
                  <p style={{ color: "#f65936 " }}>
                    Due Day :{" "}
                    {record.leaseStart
                      ? dayLeftCounter(
                          record?.leaseStart,
                          Number(record?.leaseTerm?.substring(0, 2))
                        )
                      : ""}
                  </p>
                  {dueDateColor(record)}
                </div>
              }
              title="DUE DAY"
            >
              <FcInfo />
            </Popover>
          </Space>
        </div>
      ),
    },
    {
      title: "Rent",
      dataIndex: "rate",
      sorter: (a, b) => Number(a.rate) - Number(b.rate),
      render: (_, record) => (
        <td>{toUSACurrency(Number(record?.rate ? record?.rate : 0))}</td>
      ),
    },
    {
      title: "BALANCE",
      dataIndex: "balance",
      render: (_, record) => (
        <Space
          size="middle"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={(event) => {
            navigate(`/tenants-ledger/${record.id}`);
            event.stopPropagation();
          }}
        >
          {toUSACurrency(Number(record.rate))}

          <Popover
            content={
              <div>
                <p>Rate : {toUSACurrency(Number(record.rate))}</p>
                <p style={{ color: "#5aa535 " }}>
                  Paid Amount : {toUSACurrency(Number(record.paidAmount))}
                </p>
                <p style={{ color: "#F67C36 " }}>
                  Balance :{" "}
                  {toUSACurrency(
                    Number(record.rate) - Number(record.paidAmount)
                  )}
                </p>
              </div>
            }
            title="BALANCE"
          >
            <FcInfo />
          </Popover>
        </Space>
      ),
    },
    {
      title: "Invitation",
      dataIndex: "invitaionRequest",
      render: (_, record) => (
        <span style={{ color: invitaionstatus(record.invitaionRequest) }}>
          {upperCase(
            record.invitaionRequest === "cancel"
              ? "viewed"
              : record.invitaionRequest
          )}
        </span>
      ),
    },

    {
      title: "MESSAGE",
      dataIndex: "sendmessage",
      width: 100,

      render: (_, record) => (
        <Space
          size="middle"
          onClick={(event) => {
            event.stopPropagation();
            chatCreate(record);
          }}
        >
          <Link to="/tenants">
            <MessagesSvg />
          </Link>
          {/* <Link to="/tenants">Send Message</Link> */}
          {/* <Link to="/tenants">
            <MdOutlineArrowForwardIos />
          </Link> */}
        </Space>
      ),
    },
  ];

  const [columans, setColumans] = useState(columns);
  const [allTenantsObj, setAllTenantsObj] = useState({});
  const [allTenantsArr, setAllTenantsArr] = useState([]);
  const [orgTenants, setOrgTenants] = useState([]);
  const [allTenantsArrOne, setAllTenantsArrOne] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const tenantDetailsPage = (value) => {
    if (width < 1600) {
      navigate(`/tenant-details/${value.id}`);
    }
  };

  const { width } = useWindowSize();
  const location = useLocation();
  const [yScroll, setyScroll] = useState(250);

  useEffect(() => {
    if (width < 1550) {
      setyScroll(250);
    } else {
      setyScroll(350);
    }
  }, [width, location.pathname]);

  useEffect(() => {
    if (allTenantsArrOne?.id) {
      localStorage.setItem("tenant_id", allTenantsArrOne.id);
    } else {
      localStorage.removeItem("tenant_id");
    }
  }, [allTenantsArrOne]);

  useEffect(() => {
    if (allTenantsArr?.length && !localStorage.getItem("viewAll")) {
      const availableTenants = allTenantsArr.filter((item) => {
        return item["org_id"] == localStorage.getItem("toSave");
      });
      setOrgTenants(availableTenants);
    } else if (localStorage.getItem("viewAll")) {
      setOrgTenants(allTenantsArr);
    }
  }, [
    allTenantsArr,
    localStorage,
    localStorage.getItem("viewAll"),
    localStorage.getItem("toSave"),
  ]);

  useEffect(() => {
    if (orgTenants?.length) {
      setAllTenantsArrOne(orgTenants[0]);
    } else {
      setAllTenantsArrOne({});
    }
  }, [
    orgTenants,
    localStorage.getItem("viewAll"),
    localStorage.getItem("toSave"),
  ]);
  useEffect(() => {
    dispatch(getAllTenants()).then(({ payload }) => {
      setAllTenantsObj(payload);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (allTenantsObj && Object.keys(allTenantsObj).length) {
      const arr = Object.values(allTenantsObj);
      setAllTenantsArr(arr);
    }
  }, [allTenantsObj]);

  const newAddedVal = (value) => {
    setAllTenantsArr((val) => [...val, value]);
  };

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  function getMenuLink(key) {
    switch (key) {
      case "tenantName":
        return {
          title: "TENANT NAME",
          dataIndex: "tenantName",
          width: 170,
          // ...getColumnSearchProps("tenantName"),
          render: (_, record) => (
            <div>
              <td>
                {upperCase(record.firstName)} {upperCase(record.lastName)}
              </td>
            </div>
          ),
        };
      case "unitNumber":
        return {
          title: "UNIT",
          dataIndex: "unitNumber",
          width: 120,
        };
      case "unitType":
        return {
          title: "UNIT TYPE",
          dataIndex: "unitType",
          width: 120,
        };
      case "leaseTerm":
        return {
          title: "LEASE TERM",
          dataIndex: "leaseTerm",
        };
      case "leaseStart":
        return {
          title: "LEASE START",
          dataIndex: "leaseStart",
        };
      case "leaseEnd":
        return {
          title: "LEASE END",
          dataIndex: "leaseEnd",
        };
      case "dueDate":
        return {
          title: "DUE DATE",
          dataIndex: "dueDate",
          render: (_, record) => (
            <div>
              <Space size="middle">
                <td>
                  {dateConverter(
                    record.leaseStart,
                    Number(record.leaseTerm?.substring(0, 2))
                  )}
                </td>
                <Popover
                  content={
                    <div>
                      <p style={{ color: "#f65936 " }}>
                        Due Day :{" "}
                        {dayLeftCounter(
                          record.leaseStart,
                          Number(record.leaseTerm?.substring(0, 2))
                        )}
                      </p>
                      {dueDateColor(record)}
                    </div>
                  }
                  title="DUE DAY"
                >
                  <FcInfo />
                </Popover>
              </Space>
            </div>
          ),
        };
      case "rate":
        return {
          title: "Rent",
          dataIndex: "rate",
          sorter: (a, b) => Number(a.rate) - Number(b.rate),
          render: (_, record) => <td>{toUSACurrency(Number(record.rate))}</td>,
        };
      case "balance":
        return {
          title: "BALANCE",
          dataIndex: "balance",
          render: (_, record) => (
            <Space
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              size="middle"
            >
              {toUSACurrency(Number(record.rate))}
              <Popover
                content={
                  <div>
                    <p>Rate : {toUSACurrency(Number(record.rate))}</p>
                    <p style={{ color: "#F67C36 " }}>
                      Balance : {toUSACurrency(Number(record.rate))}
                    </p>
                  </div>
                }
                title="BALANCE"
              >
                <FcInfo />
              </Popover>
            </Space>
          ),
        };

      case "sendmessage":
        return {
          title: "MESSAGE",
          dataIndex: "sendmessage",
          width: 100,

          render: (_, record) => (
            <Space size="middle">
              <Link to="/tenants">
                <MessagesSvg />
              </Link>
              {/* <Link to="/tenants">Send Message</Link>
              <Link to="/tenants">
                <MdOutlineArrowForwardIos />
              </Link> */}
            </Space>
          ),
        };
      default:
        return null;
    }
  }
  function getMenu(key) {
    switch (key) {
      case "TENANT NAME":
        return "tenantName";
      case "UNIT":
        return "unitNumber";
      case "UNIT TYPE":
        return "unitType";
      case "LEASE TERM":
        return "leaseTerm";
      case "LEASE START":
        return "leaseStart";
      case "LEASE END":
        return "leaseEnd";
      case "RENT":
        return "rate";
      case "DUE DATE":
        return "dueDate";
      case "BALANCE":
        return "balance";
      case "SEND MESSAGE":
        return "sendmessage";
    }
  }

  const onCheckboxChange = (selection) => {
    var arr = [];
    if (selection.length) {
      selection.map((value) => {
        // arr.push(getMenuLink(value));
        arr.push(getMenu(value));
      });
    }
    if (arr?.length) {
      var arr2 = [];
      const abc = columns?.map((val) =>
        arr.filter(function (item) {
          return item === val["dataIndex"];
        })
      );
      arr2.push(...abc);
      if (arr2?.length) {
        var arr3 = [];
        arr2.map((val) => {
          arr3.push(getMenuLink(...val));
        });
        var arr4 = [];
        if (arr3?.length) {
          arr3.map((value) => {
            if (value === null) {
            } else {
              arr4.push(value);
            }
          });
        }
        setColumans(arr4);
      }
    }
  };
  const [tableLoading, setTableLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    size: "small",
  });

  const fetchData = (params = {}) => {
    setTableLoading(true);
    setPagination({
      ...params.pagination,
      total: orgTenants.length, // 200 is mock data, you should read it from server
      // total: data.totalCount,
    });
    setTimeout(() => {
      setTableLoading(false);
    }, 500);

    //   });
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const slpitFunc = (value) => {
    if (value.length > 15) {
      return value.slice(0, 15) + "...";
    } else {
      return value;
    }
  };

  return (
    <>
      <TenantsWrap>
        {isClickMessage ? <LoadingTopPage /> : ""}

        <Row justify="space-between">
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 5 }}
            xs={{ span: 12 }}
          >
            <Heading marginBottom="30px">
              <Link to="/tenants">Tenants</Link>
            </Heading>
          </Col>
          <Col style={{ display: "flex" }}>
            <AddNewApr
              bgColor={"#777777"}
              fw={"400"}
              displayView="none"
              onClick={() => setModalState(true)}
            >
              Add New Tenants
            </AddNewApr>
          </Col>
        </Row>
        <AddTenantsModal
          onCancel={(value) => setModalState(value)}
          modalState={modalState}
          newAddedVal={newAddedVal}
          // from={adminRoutes.TEAM}
        />

        {/* <TenantsCard>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <TenantsCardWrap>
                <Row justify="space-between">
                  <Col>
                    <p>Lease Up in 90 Days</p>
                  </Col>
                  <Col>
                    <h5>
                      <TiFilter className="tifilter__icon" />
                      Tenants
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h2>08</h2>
                  </Col>
                </Row>
              </TenantsCardWrap>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <TenantsCardWrap>
                <Row justify="space-between">
                  <Col>
                    <p>Below Est. Market Rate</p>
                  </Col>
                  <Col>
                    <h5>
                      <TiFilter className="tifilter__icon" />
                      Tenants
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h2>12</h2>
                  </Col>
                </Row>
              </TenantsCardWrap>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <TenantsCardWrap>
                <Row justify="space-between">
                  <Col>
                    <p>Above Est. Market Rate</p>
                  </Col>
                  <Col>
                    <h5>
                      <TiFilter className="tifilter__icon" />
                      Tenants
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h2>04</h2>
                  </Col>
                </Row>
              </TenantsCardWrap>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <TenantsCardWrap>
                <Row justify="space-between">
                  <Col>
                    <p>Occupancy Rate</p>
                  </Col>
                  <Col>
                    <h5>
                      <TiFilter className="tifilter__icon" />
                      Tenants
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h2>87%</h2>
                  </Col>
                </Row>
              </TenantsCardWrap>
            </Col>
          </Row>
        </TenantsCard> */}
        <TableOneWrap>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
              <ContainerWrap
                paddings="10px"
                padding="30px 30px 0 30px"
                minH="auto"
                brRadius="5px"
                bgColor="#ffffff"
              >
                <Row justify="space-between">
                  <Col
                    xl={{ span: 9 }}
                    md={{ span: 8 }}
                    sm={{ span: 10 }}
                    xs={{ span: 24 }}
                  >
                    <Heading fontSize="20px">
                      <Link to="/tenants">Current Tenants</Link>
                    </Heading>
                  </Col>
                  <Col
                    xl={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 10 }}
                    xs={{ span: 24 }}
                    style={{ display: "flex" }}
                    className="search__filter"
                  >
                    <HeaderSearch height="40px" bgcolor="#eeeeee" />
                    <CheckboxMenu
                      options={[
                        "TENANT NAME",
                        "UNIT",
                        "UNIT TYPE",
                        "LEASE TERM",
                        "RENT",
                        "DUE DATE",
                        "BALANCE",
                        "SEND MESSAGE",
                        "LEASE START",
                        "LEASE END",
                      ]}
                      value={[
                        "TENANT NAME",
                        "UNIT",
                        "UNIT TYPE",
                        "LEASE TERM",
                        "RENT",
                        "DUE DATE",
                        "BALANCE",
                        "SEND MESSAGE",
                        "LEASE START",
                        "LEASE END",
                      ]}
                      onChange={onCheckboxChange}
                    />

                    {/* <FilterButton icon={<TiFilter className="" />}>Filter</FilterButton> */}
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Table
                      columns={columans}
                      dataSource={[...orgTenants]}
                      onChange={handleTableChange}
                      size="middle"
                      scroll={{ x: 1900, y: yScroll }}
                      loading={loading}
                      pagination={pagination}
                      onRow={(r) => ({
                        onClick: () => {
                          setAllTenantsArrOne(r);
                          tenantDetailsPage(r);
                        },
                      })}
                    />
                  </Col>
                </Row>
              </ContainerWrap>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={6}>
              <RightSideWrap>
                <RightSide>
                  <NameWrap>
                    <UserAvatar
                      className="userName__icon"
                      fullName={"Mark Jecno"}
                      // fullName={`${firstName || ""} ${lastName || ""}`}
                      // src={
                      //   item?.user?.avatar?.path
                      //     ? imagePath(item?.user?.avatar?.path)
                      //     : ""
                      // }
                      src={"/images/tenants.png"}
                    />
                  </NameWrap>
                  <MyAccountWrapRight>
                    <Row justify="space-between">
                      <Col>
                        <h2>
                          {upperCase(allTenantsArrOne?.firstName)}{" "}
                          {upperCase(allTenantsArrOne?.lastName)}
                        </h2>
                        <p>
                          {formatPhoneNumber(allTenantsArrOne?.phoneNumber)}
                        </p>
                      </Col>
                    </Row>
                  </MyAccountWrapRight>
                </RightSide>
                <TenantsDetails>
                  {allTenantsArrOne?.org_name ? (
                    <Row>
                      <Col span={12}>
                        <span>Organization Name</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.org_name}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {allTenantsArrOne?.apartment_name ? (
                    <Row>
                      <Col span={12}>
                        <span>Property Name</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.apartment_name}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {allTenantsArrOne?.unit_name ? (
                    <Row>
                      <Col span={12}>
                        <span>Unit Name</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.unit_name}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {allTenantsArrOne?.unitNumber ? (
                    <Row>
                      <Col span={12}>
                        <span>Unit Number</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.unitNumber}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.unitType ? (
                    <Row>
                      <Col span={12}>
                        <span>Unit Type</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.unitType}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.leaseTerm ? (
                    <Row>
                      <Col span={12}>
                        <span>Lease Term</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.leaseTerm}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.leaseStart ? (
                    <Row>
                      <Col span={12}>
                        <span>Lease Start</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.leaseStart}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.leaseEnd ? (
                    <Row>
                      <Col span={12}>
                        <span>Lease End</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.leaseEnd}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.dueDate ? (
                    <Row>
                      <Col span={12}>
                        <span>Due Date</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.dueDate}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.rate ? (
                    <Row>
                      <Col span={12}>
                        <span>Rent</span>
                      </Col>
                      <Col span={12}>
                        <p>{toUSACurrency(Number(allTenantsArrOne?.rate))}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.balance ? (
                    <Row>
                      <Col span={12}>
                        <span>Balance</span>
                      </Col>
                      <Col span={12}>
                        <p>
                          {toUSACurrency(Number(allTenantsArrOne?.balance))}
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.email ? (
                    <Row>
                      <Col span={12}>
                        <span>Tenant Email</span>
                      </Col>
                      <Col span={12}>
                        <p>{slpitFunc(allTenantsArrOne?.email)}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </TenantsDetails>
              </RightSideWrap>
            </Col>
          </Row>
        </TableOneWrap>
        <FloatButton>
          <SecondaryButton
            shape="circle"
            icon={<HiPlus className="plus_icon" />}
            onClick={() => setModalState(true)}
          />
        </FloatButton>
      </TenantsWrap>
    </>
  );
}

export default Tenants;

export const TenantsDetails = styled.div`
  padding-top: 50px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #94999e;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
    color: #374049;
    margin-bottom: 0;
  }

  @media (max-width: 1550px) {
    padding-top: 0;
  }
`;

export const MyAccountWrapRight = styled.div`
  padding: 20px 0 20px 30px;
  width: 100%;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.forth};
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.third};
  }

  @media (max-width: 1550px) {
    padding: 24px 0px;

    h2 {
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: 420px) {
    padding: 10px;
  }
`;

export const NameWrap = styled.div`
  .ant-avatar {
    width: 100px;
    height: 100px;
  }
`;
export const RightSideWrap = styled.div`
  ${"" /* padding: 30px; */}
  padding: ${({ padding }) => padding || "30px"};

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  ${"" /* min-height: calc(100vh - 220px); */}
  min-height: ${({ minH }) => minH || "calc(100vh - 220px)"};
`;
export const RightSide = styled.div`
  display: flex;
  @media (max-width: 1550px) {
    display: flex;
    flex-direction: column;
    flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  }
`;

export const TenantsWrap = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "50px 50px 0 330px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "40px 40px 0 265px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }

  .ant-table-tbody > tr.ant-table-placeholder {
    min-height: calc(100vh - 445px) !important;
  }

  .ant-table-body {
    min-height: calc(100vh - 445px) !important;
  }

  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.secondary};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
`;
