import styled from "styled-components";
import { Menu, Badge, List } from "antd";

export const MenuItem = styled(Menu.Item)`
  &:hover {
    svg {
      fill: #777777;
    }
  }
`;

// HEADER ALL WRAPPER
export const StyledHeaderTop = styled.div`
  height: 80px;
  background-color: #aaaaaa;
  background-color: ${({ theme }) => theme.colors.primary};
  background-size: cover;
  background-position: 100% 100%;
  display: flex;
  padding: 0 30px;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 1550px) {
    height: 65px;
  }

  @media (max-width: 991.98px) {
    padding: 0 15px;
    width: 100%;
  }
`;

// SIDEBAR MENU
export const StyledLeftSideMenu = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  color: #ffffff;

  .header_hamburger_menu {
    color: ${({ theme }) => theme.colors.forth};
    cursor: pointer;
    font-size: 40px;
    margin-right: 10px;
    margin-top: 7px;
    display: none;
  }
  .avenew__title {
    font-weight: 700;
    font-size: 40px;
    // line-height: 49px;
    color: #ffffff;

    @media (max-width: 1550px) {
      width: 160px;
      height: 39px;
    }
  }
  .avenew__title__sidebar {
    display: none;
    font-weight: 700;
    font-size: 40px;
    color: #777777;
    padding-left: 30px;
    padding-bottom: 15px;
  }
  @media (max-width: 1550px) {
    .avenew__title__sidebar {
      padding-left: 15px;
      padding-bottom: 15px;
    }
  }
  @media (max-width: 426px) {
    width: 15%;
  }
  @media (max-width: 991.98px) {
    .header_hamburger_menu {
      display: revert;
    }
    .avenew__title {
      display: none;
    }
    .avenew__title__sidebar {
      display: revert;
    }
  }
`;

// RIGHT SIDE HEADER
export const StyledRightSideMenu = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: flex-end;

  .hand_icon {
    display: inline-block;
    margin-right: 13px;
    width: 29px;
    height: 29px;
  }

  .user_profile_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .header_user_profile_image {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 20px;
    border: 1px solid white;
  }

  .ant-dropdown-trigger {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .header_dropdown_menu_icon {
    color: white;
    font-size: 21px;
  }

  .header_user_mobile_image {
    display: none;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 20px;
  }
  .user__icon {
    font-size: 30px;
    color: ${({ theme }) => theme.colors.forth};
    margin-left: 15px;
    background: #ededed;
    width: 40px;
    height: 40px;
  }
  .user_profile_wrapper {
    .ant-dropdown-trigger {
      span {
        margin-right: 20px;
        color: white;
        font-size: 14px;
        line-height: 14px;
        white-space: nowrap;
      }
    }
    .ant-avatar {
      margin-right: 20px;
    }
  }
  @media (max-width: 1550px) {
    .user__icon {
      font-size: 24px;
      ${
        "" /* width: 32px;
      height: 32px; */
      }
    }
  }

  @media (max-width: 700px) {
    .user__icon {
      width: 45px !important;
      height: 45px !important;
    }
  }
  @media (max-width: 632px) {
    .user__icon {
      width: 55px !important;
      height: 45px !important;
    }
  }
  @media (max-width: 525px) {
    .user__icon {
      width: 70px !important;
      height: 45px !important;
    }
  }
  @media (max-width: 426px) {
    width: 85%;
    .user__icon {
      font-size: 40px !important;
      ${"" /* margin-left: 0 !important; */}
      width: 60px !important;
      height: 42px !important;
    }
  }
  @media (max-width: 350px) {
    .user__icon {
      margin-left: 0 !important;
      width: 60px !important;
      height: 42px !important;
    }
  }

  @media (max-width: 575.98px) {
    .hand_icon {
      display: none;
    }
  }

  @media (max-width: 991.98px) {
    .hand_icon {
      margin-right: 20px;
    }
    .user_profile_wrapper {
      display: none;
    }
    .header_dropdown_menu_icon {
      display: none;
    }
    .header_user_mobile_image {
      display: flex;
    }
  }
`;

// HEADER TREE SELECT WRAPPER
export const StyledHeaderSearch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "433px"};
  height: ${({ height }) => height || "45px"};
  background: #cbcbcb;
  background: ${({ bgcolor }) => bgcolor || "#cbcbcb"};
  border-radius: 5px;
  padding-left: 18px;
  ${
    "" /* .ant-select-dropdown {
    position: fixed !important;
  } */
  }

  .ant-select-selector {
    height: auto;
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
  }
  .ant-select {
    ${"" /* width: 80% !important; */}
  }

  .ant-select-clear {
    color: #777777 !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 14px;
    color: #aaaaaa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  .ant-select-clear {
    opacity: 1;
    background: none;
    font-size: 16px;
    color: white;
  }

  .header_search_icon {
    font-size: 20px;
    cursor: pointer;
    color: #777777;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
    line-height: 14px;
    color: #aaaaaa;
  }

  input::placeholder {
    color: white;
    font-size: 14px;
    line-height: 14px;
  }

  @media (max-width: 1550px) {
    max-width: ${({ maxWidth }) => maxWidth || "354px"};
    height: 35px;
    .header_search_icon {
      font-size: 16px;
    }
    .ant-select-selection-placeholder {
      font-size: 14px;
      line-height: 14px;
      color: #aaaaaa;
    }
  }
`;

// HEADER NOTIFICATION WRAPPER
export const StyledNotificationWrapper = styled.div`
  display: flex;
  margin-left: 15px;

  @media (max-width: 350px) {
    margin-right: 10px;
  }
  // margin-right: 20px;
  .bell_icon {
    font-size: 21px;
    color: white;
    cursor: pointer;
  }
  @media (max-width: 1550px) {
    .bell_icon {
      width: 20px;
      height: 20px;
    }
  }
`;

// HEADER DROPDOWN MENU
export const StyledHeaderDropdownContentWrappeer = styled(Menu)`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #d3d6dd;
  width: 200px;
  top: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0;

  .ant-dropdown-menu-item {
    padding: 10px 0px 10px 17.16px;
  }
  .logout__wrap {
    background: #ffeade;
    a {
      color: #f67c36 !important;
    }
  }

  @media (max-width: 1550px) {
    width: 160px;

    .ant-dropdown-menu-item {
      padding: 8px 0px 8px 14px;
    }
  }
  @media (max-width: 991.98px) {
    top: 0px;
  }
`;

// HEADER DROPDOWN ITEM
export const StyledHeaderDropdownMenuItem = styled(MenuItem)`
  .ant-dropdown-menu-item-icon {
    color: #949494;
    font-size: 16px;
    margin-right: 12px;
  }
  .ant-dropdown-menu-title-content > a {
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #2b353f;
  }
  .ant-dropdown-menu-title-content > p {
    color: #2b353f;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }

  @media (max-width: 1550px) {
    .ant-dropdown-menu-item-icon {
      font-size: 14px;
      margin-right: 10px;
    }
    .ant-dropdown-menu-title-content > a {
      font-size: 14px;
      line-height: 10px;
    }
    .ant-dropdown-menu-title-content > p {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }
  }
`;

export const StyledListItem = styled(List.Item)`
  background: ${(props) => (props.notificationRead ? "#F2F2F2" : "#FFFFFF")};
  border: 0px solid #e5e5e5;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 12px 5px 12px 13px;
  align-items: flex-start;
  align-items: center;
  cursor: pointer;
  border-bottom: inset !important;

  .ant-list-item-meta {
    align-items: center;
  }

  .ant-list-item-action {
    margin-left: 0px;
  }

  .ant-list-item-meta-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #232333;
  }

  .ant-list-item-meta-description {
    font-size: 14px;
    line-height: 18px;
    color: #747487;
    display: flex;
    justify-content: space-between;
  }

  .header_notification_time {
    font-style: italic;
    font-size: 12px;
    line-height: 18px;
    color: #747487;
  }

  .header_notification_option_button {
    font-size: 24px;
    line-height: 24px;
    color: #747487;
    cursor: pointer;
  }

  @media (max-width: 991.98px) {
    .header_notification_time {
      font-size: 12px;
    }

    .ant-list-item-meta-title {
      font-weight: bold;
    }

    .ant-list-item-meta-description {
      font-size: 12px;
    }
  }
`;

export const StyledListWrapper = styled(List)`
  .header_view_more_wrap {
    text-align: center;
    margin-top: 12px;
  }

  .header_view_more_wrap Button {
    border: none;
    padding: 0;
    height: 0;
  }

  .header_view_more_wrap Button span {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #747487;
  }
`;

export const StyledNotificationInline = styled.div`
  display: inline-block;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #f67c36;
  }

  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
  }
`;
