import { Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { businessDetails } from "../../features/businessDetailsSlice";
import { onEdit } from "../../features/useSlice";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../createPersonalProfile/RegistrationStyle";

function EditBusinessDetails() {
  const dispatch = useDispatch();

  // const {
  //   OrganizationName,
  //   OrganizationNumber,
  //   OrganizationEmail,
  //   TotalListedApartment,
  //   TotalGeneratedRevenue,
  // } = useSelector((state) => state.businessDetails.userbusinessDetails);

  const onFinish = (value) => {
    dispatch(businessDetails(value));
    dispatch(onEdit(""));
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  const [fields, setFields] = useState([
    {
      name: ["OrganizationName"],
      value: "Prompt",
    },
    {
      name: ["OrganizationNumber"],
      value: Number("+12569874"),
    },
    {
      name: ["OrganizationEmail"],
      value: "help@prompt.com",
    },
    {
      name: ["TotalListedApartment"],
      value: "008",
    },
    {
      name: ["TotalGeneratedRevenue"],
      value: "$987",
    },
  ]);
  return (
    <EditBusinessDetailsWrap>
      <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
        <StyledForms>
          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={"optional"}
            validateMessages={validateMessages}
            fields={fields}
          >
            <StyledFormItem wh="100" smwh="100">
              <Form.Item
                name="OrganizationName"
                label="Organization name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Organization Name" />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem
              smwh="100"
              fd="column"
              flexDirection="column"
              flexDirectionSmwh="100"
            >
              <Form.Item
                name="OrganizationNumber"
                label="Organization Contact"
                rules={[{ required: true }]}
              >
                <Input type="number" placeholder="Organization Contact" />
              </Form.Item>
              <Form.Item
                name="OrganizationEmail"
                label="Organization Email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input placeholder="Organization Email" />
              </Form.Item>
            </StyledFormItem>

            <StyledFormItem
              smwh="100"
              fd="column"
              flexDirection="column"
              flexDirectionSmwh="100"
            >
              <Form.Item
                name="TotalListedApartment"
                label="Total Listed Apartments"
                rules={[{ required: true }]}
              >
                <Input placeholder="Total Listed Apartments" />
              </Form.Item>
              <Form.Item
                name="TotalGeneratedRevenue"
                label="Total Generated Revenue"
                rules={[{ required: true }]}
              >
                <Input placeholder="Total Generated Revenue" />
              </Form.Item>
            </StyledFormItem>

            <Form.Item>
              <Row justify="end">
                <Col>
                  <StyledButton htmlType="submit">SAVE</StyledButton>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </StyledForms>
      </StyledForm>
    </EditBusinessDetailsWrap>
  );
}

export default EditBusinessDetails;

export const EditBusinessDetailsWrap = styled.div`
  ${"" /* padding: 5px 30px 0 60px; */}
  ${
    "" /* @media screen and (max-width: 1600px) {
    .ant-form-item {
      width: 100%;
    }
  } */
  }
  @media (max-width: 1441px) {
    padding: 0;
  }
  .ant-form-item {
    input {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      color: #000000;
    }
  }
  @media (max-width: 1550px) {
    padding: 0;

    .ant-form-item {
      input {
        font-size: 16px !important;
        line-height: 19px !important;
      }
    }
    .ant-form-item input {
      height: 40px !important;
    }
  }

  @media (max-width: 991.98px) {
    padding: 0 20px;
  }
  @media (max-width: 420px) {
    padding: 0 10px;
  }
`;
