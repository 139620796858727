import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { Container } from "../../ui/Layout/Container";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import WriteQuestionModal from "../write question/WriteQuestionModal";

function Support() {
  const { Meta } = Card;
  const navigate = useNavigate();
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);

  return (
    <Container>
      <SupportWrap>
        <Row justify="center" gutter={[30, 30]}>
          <Col>
            <Card
              hoverable
              cover={<img alt="example" src="images/Illuction.svg" />}
              onClick={() => {
                navigate("/faqs");
              }}
            >
              <Meta title="FAQs" description="View all >" />
            </Card>
          </Col>
          <Col>
            <Card
              onClick={() => {
                setModalState(true);
              }}
              hoverable
              cover={<img alt="example" src="images/anyquestion.svg" />}
            >
              <Meta
                title="Do you have any questions?"
                description="View all >"
              />
            </Card>
          </Col>
          <Col>
            <Card
              hoverable
              onClick={() => {
                navigate("/support/callormail");
              }}
              cover={<img alt="example" src="images/needcallemail.svg" />}
            >
              <Meta title="Contact us directly" description="View all >" />
            </Card>
          </Col>
        </Row>
      </SupportWrap>
      <WriteQuestionModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </Container>
  );
}

export default Support;

export const SupportWrap = styled.div`
  .ant-card {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 380px;
  }
  .ant-card-cover img {
    padding: 35px 95px 50px 95px;
  }
  .ant-card-body {
    text-align: center;
  }
  .ant-card-meta-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .ant-card-meta-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #f67c36;
  }
  .ant-card-bordered .ant-card-cover {
    min-height: 258px;
  }

  @media (max-width: 1600px) {
    .ant-card {
      width: 290px;
    }
    .ant-card-cover img {
      padding: 25px 70px 36px 70px;
    }
    .ant-card-meta-title {
      font-size: 20px;
      line-height: 28px;
    }
    .ant-card-meta-description {
      font-size: 14px;
      line-height: 16px;
    }
    .ant-card-bordered .ant-card-cover {
      min-height: 205px;
    }
  }

  @media (max-width: 1250px) {
    .ant-card {
      width: 380px;
      margin: auto;
    }
    .ant-row {
      flex-direction: column;
    }
  }

  @media (max-width: 425px) {
    .ant-card-cover img {
      padding: 15px 40px 20px 40px;
    }
    .ant-card {
      width: 300px;
      margin: auto;
    }
    .ant-card-bordered .ant-card-cover {
      min-height: 215px;
    }
  }
`;
