import React from "react";

const RightSideArrow = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="12" height="18" viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-1.74802e-06 16.1775L2.01875 18L12 9L2.01875 1.03476e-06L-4.93067e-07 1.8225L7.95625 9L-1.74802e-06 16.1775Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default RightSideArrow;
