import React from "react";

const discountSvg = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14.4925L13.0003 2.49219H20.5005V9.9924L8.50021 21.9927L1 14.4925Z"
        stroke="#777777"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7579 7.7579C17.5863 7.7579 18.2579 7.0863 18.2579 6.25785C18.2579 5.4294 17.5863 4.75781 16.7579 4.75781C15.9294 4.75781 15.2578 5.4294 15.2578 6.25785C15.2578 7.0863 15.9294 7.7579 16.7579 7.7579Z"
        stroke="#777777"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 5.50013L22.0001 1"
        stroke="#777777"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2502 7.74219L6.25 13.7424"
        stroke="#777777"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2501 10.75L12.25 13.7501"
        stroke="#777777"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default discountSvg;
